import GlobalState from "../interfaces/states/GlobalState";
import {FilterType} from "../../constants/enums";
import {SimpleFilter} from "../../constants/globalTypes";

export function getGeoFilter(
  state: GlobalState
) {
  const {
    data: {
      filters
    },
  } = state;
  return filters.filter(
    filterDefinition => filterDefinition.type === FilterType.Geo).pop() as SimpleFilter;
}

export function getFocusId(
  state: GlobalState
) {
  const {
    data: {
      focusId
    }
  } = state;
  return focusId;
}
