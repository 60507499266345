import React, {PropsWithChildren} from "react";
import GlobalState from "../../store/interfaces/states/GlobalState";
import { connect, ConnectedProps } from "react-redux";

function PermissionWrapper(
    props: PropsWithChildren<PropsFromRedux>
) {
  // TODO: Add code for more detailed checking of roles osv
  const { authenticated, children } = props;
  if (authenticated) {
    return (
      <>
        {children}
      </>
    );
  } else {
    return <></>;
  }
}

function mapStateToProps(state: GlobalState) {
    const { authenticated } = state.auth;
    return {
      authenticated
    };
}

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(PermissionWrapper);

