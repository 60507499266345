import React from "react";
import {Card, theme} from "antd";
import {useDimension} from "../../utils/hooks";
import { DimensionDefinition } from "../../interfaces/Config";
import * as d3 from "d3";


export interface GradientControlProperties {
  min: number;
  max: number;
  show?: boolean;
}

export default function AntDGradientControl(
  props: GradientControlProperties
) {
  const {
    min,
    max,
    show = true,
  } = props;
  const colorString = [0, 0.25, 0.5, 0.75, 1.0].map(d3.interpolateWarm).toString();
  const { token } = theme.useToken();
  return (
    <Card
      bodyStyle={{
        padding: 10,
        height: 45
      }}
      style={{
        display: show ? 'flex' : 'none',
        borderColor: token.colorBorder,
        boxShadow: token.boxShadow,
        width: 140
      }}>
      <div style={{
        width: 120,
        height: 12,
        backgroundImage: `linear-gradient(to right, ${colorString})`
      }} />
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        width: 120,
        borderTopColor: token.colorText,
        borderTopWidth: 1,
        borderTopStyle: 'solid'
      }}>
        <div style={{
          display: 'flex',
          flex: 1,
          borderLeftColor: token.colorText,
          borderLeftWidth: 1,
          borderLeftStyle: 'solid',
          lineHeight: 1,
          height: 3,
          paddingTop: 4,
        }}>
          <div style={{ marginLeft: -2 }}>{min}</div>
        </div>
        <div style={{
          display: 'flex',
          flex: 1,
          borderRightColor: token.colorText,
          borderRightWidth: 1,
          borderRightStyle: 'solid',
          justifyContent: 'flex-end',
          lineHeight: 1,
          height: 3,
          paddingTop: 4,
        }}>
          <div style={{ marginRight: -2 }}>{max}</div>
        </div>
      </div>
    </Card>
  );
}
