import React, { useState } from "react";
import { Button, Form, Input, Checkbox, Menu, Card } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLock } from "@fortawesome/free-solid-svg-icons";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { bindActionCreators, Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import * as authActions from "../../store/actions/auth";
import GlobalState from "../../store/interfaces/states/GlobalState";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {useInjection} from "inversify-react";
import Messenger from "../../interfaces/Messenger";
import Injectable from "../../injection/injectable";

// @ts-ignore
import className from "../../assets/scss/components/toolbar.scss";

function AntDLogin(props: PropsFromRedux) {
    const [form] = Form.useForm();
    const messenger = useInjection<Messenger>(Injectable.Messenger);
    const onFinish = () => {
        const username = form.getFieldValue('username');
        const password = form.getFieldValue('password');
    };
    const onFinishFailed = (errorInfo: any) => {
        messenger.error(errorInfo);
    };
    const spinnerIcon = <LoadingOutlined
        style={{ fontSize: 16, color: 'white', marginRight: 20 }}
        spin />;
    return (
        <Card style={{ padding: 0 }}>
            <Form
                form={form}
                name="basic"
                className={className.loginForm}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 18 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off">
                <Form.Item
                    name="username"
                    className={className.test}
                    rules={[{ required: true, message: 'Brukernavn må fylles inn' }]}
                >
                    <Input
                        className={className.loginFormInput}
                        prefix={<FontAwesomeIcon icon={faUser as IconProp}
                            style={{ fontSize: 13 }} />}
                        placeholder="Brukernavn" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Passord må fylles inn' }]}
                >
                    <Input
                        className={className.loginFormInput}
                        prefix={<FontAwesomeIcon icon={faLock as IconProp}
                            style={{ fontSize: 13 }} />}
                        type="password"
                        placeholder="Passord" />
                </Form.Item>
                <Form.Item>
                    <div className={className.loginFormRow}>
                        <Checkbox>Husk meg</Checkbox>
                        <a href="">Glemt passord</a>
                    </div>
                </Form.Item>
                <Form.Item>
                    <Button
                        className={className.loginFormButton}
                        type="primary"
                        htmlType="submit">
                        Logg inn
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
}

function mapStateToProps(
    state: GlobalState
) {
    const { authenticated } = state.auth;
    return {
        authenticated,
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
    const {
    } = authActions;
    return bindActionCreators({
    }, dispatch)
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(AntDLogin);
