import React, {ReactElement, useMemo, useRef} from "react";
import {useResizeObserver} from "../../utils/hooks";

// @ts-ignore
import className from "../../assets/scss/statistics.scss";

export interface PreviewAreaProperties {
  width: number;
  height: number;
  children: (size: { width: number, height: number }) => ReactElement;
}

export function PreviewArea(props: PreviewAreaProperties) {
  const { width, height, children } = props;
  const ref = useRef<HTMLDivElement>(null);
  const previewSize = useResizeObserver(ref);
  const size = useMemo(() => ({
    width: previewSize.width - 20,
    height: previewSize.height - 20
  }), [previewSize]);
  return (
    <div ref={ref} className={className.preview} style={{ width, height }}>
      {
        children(size)
      }
    </div>
  )
}
