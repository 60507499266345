import {theme} from "antd";
import React, {CSSProperties, PropsWithChildren, useCallback} from "react";
import {joinClassNames} from "../../utils/miscUtilities";

import className from "../../assets/scss/components/scrollarea.scss";
import {getNightMode} from "../../store/selectors/user";
import {useSelector} from "react-redux";

interface RendererProperties {
  style: Partial<CSSProperties>;
  props: any;
}

export interface ScrollAreaProperties {
  className?: string;
  autoHide?: boolean;
  style?: Partial<CSSProperties>;
  onScroll?: React.UIEventHandler<any> | undefined;
}

export default function ThemedScrollArea(
  props: PropsWithChildren<ScrollAreaProperties>
) {
  const {
    children,
    onScroll,
    autoHide = false,
    style = {}
  } = props;
  const { token } = theme.useToken();
  const renderThumb = useCallback(({style, props}: RendererProperties) => {
    const thumbStyle = {
      ...style,
      backgroundColor: token.colorPrimary,
      borderRadius: token.borderRadius,
    };
    return <div style={thumbStyle} {...props} />;
  }, [token]);

  const renderView = useCallback(({style, props}: RendererProperties) => {
    const viewStyle = {
      ...style,
      padding: 4,
      backgroundColor: token.colorBgContainer,
      color: token.colorText
    };
    return <div style={{ ...style, ...viewStyle }} {...props} />;
  }, [token]);

  return <div />
  /*return (
    <div
      onScroll={onScroll}
      renderThumbVertical={renderThumb}
      renderThumbHorizontal={renderThumb}
      renderView={renderView}
      style={style}
      className={joinClassNames(props.className, className.scrollArea)}
      autoHide={autoHide}>
      { children }
    </div>
  );*/
}
