import {Filter, ComparatorValue, FilterFunction} from "../types";
import _ from "lodash";
import {OPERATORS} from "../constants";
import {FilterType, Operator} from "../enums";
import {Key} from "react";

export function includeOperatorSelect(
    filter: Filter
) {
    return !!filter;
}

export function operatorsForType(
    type: FilterType
): [Operator, string][] {
    switch (type) {
        case FilterType.Categorical:
            return [Operator.Equal, Operator.NotEqual].map(v => [v, OPERATORS[v]]);
        case FilterType.Text:
            return [Operator.In].map(v => [v, OPERATORS[v]]);
        case FilterType.Numerical:
        case FilterType.Date:
        default: {
            const nonTextOperators = _.omit(OPERATORS, [Operator.In]);
            return _.entries(nonTextOperators) as any as [Operator, any][];
        }
    }
}

export function groupFilters(
    filters: Filter[]
): Filter[][] {
    const ungroupedFilters = filters.filter(({ group }) => _.isUndefined(group)).map(filter => [filter]);
    const filterByGroup: Record<Key, Filter[]> = {};
    filters.forEach(filter => {
        if (!_.isUndefined(filter.group)) {
            filterByGroup[filter.group] = (filterByGroup[filter.group] || []).concat(filter);
        }
    });
    return _.values(filterByGroup).concat(ungroupedFilters);
}

export function isMultipleOperator(operator?: Operator) {
    return operator == Operator.NotEqual || operator == Operator.Equal;
}

import dayjs from "dayjs";
import {alwaysTrue} from "../../../utils/miscUtilities";
import {Dimension} from "crossfilter2";



function createDateFilterFunction(
    filter: Filter
) {
    const { operator } = filter;
    const timestamp = filter.comparatorValue as number;
    const comparatorDate = timestamp && dayjs.unix(timestamp);
    switch (operator) {
        case Operator.LesserThan:
            return (v: ComparatorValue) => dayjs(v as string).isBefore(comparatorDate);
        case Operator.LesserThanOrEqual:
            return (v: ComparatorValue) => dayjs(v as string).isSameOrBefore(comparatorDate);
        case Operator.Equal:
            return (v: ComparatorValue) => dayjs(v as string).isSame(comparatorDate);
        case Operator.NotEqual:
            return (v: ComparatorValue) => !dayjs(v as string).isSame(comparatorDate);
        case Operator.GreaterThan:
            return (v: ComparatorValue) => dayjs(v as string).isAfter(comparatorDate);
        case Operator.GreaterThanOrEqual:
            return (v: ComparatorValue) => dayjs(v as string).isSameOrAfter(comparatorDate);
    }
    return alwaysTrue;
}

function createFilterFunctionFromValuesAndOperator<T>(
    filter: Filter,
    comparatorValues: ComparatorValue[],
    transform: (ComparatorValue: any) => ComparatorValue = (value: ComparatorValue) => value
) {
    const { operator } = filter;
    comparatorValues = comparatorValues.map(transform);
    switch (operator) {
        case Operator.LesserThan:
            return (value: ComparatorValue|ComparatorValue[]) => transform(value) < comparatorValues[0];
        case Operator.LesserThanOrEqual:
            return (value: ComparatorValue|ComparatorValue[]) => transform(value) <= comparatorValues[0];
        case Operator.GreaterThan:
            return (value: ComparatorValue|ComparatorValue[]) => transform(value) > comparatorValues[0];
        case Operator.GreaterThanOrEqual:
            return (value: ComparatorValue|ComparatorValue[]) => {
                return transform(value) >= comparatorValues[0];
            };
        case Operator.Equal:
            return (value: ComparatorValue|ComparatorValue[]) => {
                return _.isArray(value)
                    ? value.every((v: any) => comparatorValues.includes(v))
                    : comparatorValues.includes(value);
            };
        case Operator.NotEqual:
            return (value: ComparatorValue|ComparatorValue[]) => {
                return _.isArray(value)
                    ? !value.some((v: any) => comparatorValues.includes(v))
                    : !comparatorValues.includes(value);
            };
    }
    return alwaysTrue;
}

function createTextFilterFunction(
    filter: Filter
) {
    const { comparatorValue } = filter;
    if (_.isString(comparatorValue)) {
        return (text: ComparatorValue) => (text as string).toLowerCase().includes(comparatorValue.toLowerCase());
    }
    return alwaysTrue;
}

export function filterDimension(
    dimension: Dimension<any, any>,
    filter: Filter|Filter[]
) {
    const filterFunction = createFilterFunctionFromFilters(filter);
    if (_.isFunction(dimension.filterFunction)) {
        dimension.filterFunction(filterFunction);
    }
}

export function createFilterFunctionFromFilter(
    filter: Filter
): FilterFunction {
    let { type, comparatorValue } = filter;
    const comparatorValues = _.isArray(comparatorValue)
        ? comparatorValue
        : _.isUndefined(comparatorValue) ? [] : [comparatorValue];
    switch (type) {
        case FilterType.Text:
            return createTextFilterFunction(filter);
        case FilterType.Numerical:
            return createFilterFunctionFromValuesAndOperator(filter, comparatorValues);
        case FilterType.Categorical:
            return createFilterFunctionFromValuesAndOperator(filter, comparatorValues);
        case FilterType.Date:
            return createDateFilterFunction(filter);
    }
    return alwaysTrue;
}

function combineFilterFunctions(filterFunctions: FilterFunction[]) {
    return (value: ComparatorValue) => {
        for (const filterFunction of filterFunctions) {
            if (!filterFunction(value)) {
                return false;
            }
        }
        return true;
    }
}

export function createFilterFunctionFromFilters(
    filters: Filter|Filter[]
): (dataPoint: any) => boolean {
    const filterFunctions: FilterFunction[] = _.castArray(filters).map(createFilterFunctionFromFilter);
    return filterFunctions.length > 0
        ? combineFilterFunctions(filterFunctions)
        : alwaysTrue;
}
