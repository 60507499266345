import {
  ComponentDefinition,
  ComponentEditor,
  ComponentRenderer,
  Factory,
  FunctionalComponent
} from "../constants/globalTypes";
import Build from "../interfaces/Build";
import {Container} from "inversify";
import _ from "lodash";
import {useInjection} from "inversify-react";
import Injectable from "../injection/injectable";
import {ComponentType} from "../constants/enums";

function editorPostfix(injectable: string) {
  return `${injectable}-editor`;
}

function rendererPostfix(injectable: string) {
  return `${injectable}-renderer`;
}

export function injectReactComponent<Props>(injectable: Injectable): FunctionalComponent<Props> {
  return useInjection<FunctionalComponent<Props>>(injectable);
}

export function injectComponentRenderer<Props>(componentType: ComponentType): ComponentRenderer<Props> {
  return useInjection<ComponentRenderer<Props>>(componentType
    ? rendererPostfix(componentType) : Injectable.Empty);
}

export function injectComponentEditor<Props = any>(componentType: ComponentType): ComponentEditor<Props> {
  return useInjection<ComponentEditor<Props>>(editorPostfix(componentType));
}

export function addBaseBuildToContainer(
  build: Build,
  container: Container
): void {
  const { singletons, constructors, factories, components, constants, statisticComponents } = build;

  // Singletons
  Object.keys(singletons)
    .forEach(key => {
      const constructor = (singletons as any)[key];
      container.bind<typeof constructor>(key)
        .to(constructor)
        .inSingletonScope()
    });

  // Classes
  Object.keys(constructors)
    .forEach(key => {
      const constructor = (constructors as any)[key];
      container.bind<typeof constructor>(key)
        .to(constructor);
    });

  // Components
  _.entries(components)
    .forEach(([key, func]) => {
      container.bind<typeof func>(key).toConstantValue(func);
    });

  // Factories
  Object.keys(factories)
    .forEach(key => {
      const constructor = (factories as any)[key];
      container.bind<Factory<typeof constructor>>(key).toFactory<typeof constructor>(constructor)
    });

  // Constants
  _.entries(constants)
    .forEach(([key, constant]) => {
      container.bind<typeof constant>(key).toConstantValue(constant);
    });

  // Statistic Components
  _.entries(statisticComponents)
    .forEach(([key, statisticComponent]) => {
      const {
        renderer, editor
      } = statisticComponent;
      container.bind<ComponentRenderer<any>>(rendererPostfix(key)).toConstantValue(renderer);
      container.bind<ComponentEditor<any>>(editorPostfix(key)).toConstantValue(editor);
    });

  const statisticComponentTypes = _.values(statisticComponents).map(({ label, type }) => ({ type, label }));
  container.bind<ComponentDefinition[]>(Injectable.ComponentDefinitions).toConstantValue(statisticComponentTypes);
}
