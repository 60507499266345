import * as React from "react";
import TabPaneProperties from "../../interfaces/properties/TabPaneProperties";
import {useInjection} from "inversify-react";
import Injectable from "../../injection/injectable";
import { Pane } from "react-split-pane";
import {isDefined} from "../../utils/validationUtilities";
import TabBarProperties from "../../interfaces/properties/TabBarProperties";
import {useRef} from "react";

import className from "../../assets/scss/components/tabpane.scss";

export default function TabPane(
  props: TabPaneProperties
) {
  const ref = useRef<Pane>(null);
  const TabBar = useInjection<React.FunctionComponent<TabBarProperties>>(Injectable.TabBar);
  const {
    children = [],
    header,
    forceShowTabs,
    ...rest
  } = props;
  return (
    // @ts-ignore
    <Pane
      ref={ref}
      className={className.paneContainer} { ... rest }>
      { isDefined(header) && header }
      <div
        className={className.paneContent}>
        <TabBar
          forceShowTabs={forceShowTabs}>
          {
            children
              .filter(child => isDefined(child.props) && isDefined(child.props.tab))
          }
        </TabBar>
      </div>
    </Pane>
  );
}
