import {NodeOptions, RiskInputOption} from "../types";
import _ from "lodash";
import { v4 as uuid } from "uuid";
import {NodeSerialized} from "../interfaces/NodeSerialized";
import {Key} from "react";

export default class Node {
    private readonly _probability: number;
    private readonly _possibleValues: RiskInputOption[];
    private readonly _label: string;
    private readonly _id: string;
    private readonly _defaultValue: number;
    private readonly _statisticsCategory?: Key;
    private _value?: number;

    constructor(
        options: NodeOptions
    ) {
        const {
            id,
            label,
            currentValue,
            possibleValues,
            weight,
            defaultValue,
            statisticsCategory
        } = options;

        this._value = currentValue || defaultValue;
        this._probability = weight;
        this._possibleValues = possibleValues;
        this._label = label;
        this._defaultValue = defaultValue;
        this._statisticsCategory = statisticsCategory;
        this._id = id || uuid();

        Node.validateValueAgainstPossibleValues(this._value, possibleValues);
    }

    public get probability(): number {
        return this._probability;
    }

    public get value(): number {
        return this._value!;
    }

    public get id(): string {
        return this._id;
    }

    public set value(value: number) {
        Node.validateValueAgainstPossibleValues(value, this._possibleValues);
        this._value = value;
    }

    public serialize(): NodeSerialized {
        return {
            id: this._id,
            label: this._label,
            currentValue: this._value,
            weight: this._probability,
            possibleValues: this._possibleValues,
            defaultValue: this._defaultValue,
            statisticsCategory: this._statisticsCategory
        };
    }

    static validateValueAgainstPossibleValues(
        value: number|undefined,
        inputOptions: RiskInputOption[]
    ): void {
        const valueIsPossible = !_.isUndefined(value) && inputOptions.map(({ value })=> value).includes(value);
        if (!valueIsPossible) {
            // TODO: error text
            throw new Error("");
        }
    }

}