import DataState from "../interfaces/states/DataState";
import {Action} from "redux";
import {DataAction} from "../actions/data";
import {
    AddLayersAction,
    FilterByGeometryAction,
    FilterDefinitionAction,
    MoreInfoModalAction, SetFilterDefinitionsAction,
    SetFocusAction,
    SetSelectionTypeAction,
    AddRedactionInfoAction
} from "../interfaces/actions/data";
import {isDefined, isFilter, isSimpleFilter} from "../../utils/validationUtilities";
import {updateState} from "../../utils/miscUtilities";
import {analytics, AnalyticsEvent} from "../../components/analytics";
import _ from "lodash";
import {
    ensureFiltersIncludePredefinedFilters,
    initializeFiltersFromUrlParameters,
    serializeFilters
} from "../../utils/filterUtilities";
import {Filter, SimpleFilter} from "../../constants/globalTypes";

const visibleLayers: Record<string, boolean> = {};

const initialState: DataState = {
    focusId: null,
    moreInfoModalData: null,
    moreInfoModalVisible: false,
    addNewAccidentModalVisible: false,
    filterExpanded: true,
    filters: initializeFiltersFromUrlParameters(),
    selectionType: null,
    visibleLayers: visibleLayers
}

function updateLocation(
  filters: (Filter|SimpleFilter)[]
) {
    const { protocol, host, pathname } = window.location;
    const url = new URL(protocol + '//' + host + pathname);
    const filtersString = serializeFilters(filters);
    url.searchParams.set('f', filtersString);
    window.history.replaceState('', '', url.toString());
}

// Use the initialState as a default value
export default function data(
    state = initialState,
    action: Action
) {
    switch (action.type) {
        case DataAction.SetFilterDefinitions: {
            const { savedFilter } = action as SetFilterDefinitionsAction;
            const filters = ensureFiltersIncludePredefinedFilters(savedFilter.filters)
            updateLocation(filters);
            return {
                ...state,
                filters
            }
        }
        case DataAction.FocusOnDataPoint: {
            const { id: focusId, zoom: focusZoom } = action as SetFocusAction;
            const focusTimestamp = new Date().getTime();
            return updateState(state, { focusId, focusZoom, focusTimestamp });
        }
        case DataAction.FilterByGeometry: {
            const { geometrySelection } = action as FilterByGeometryAction;
            return updateState(state, { geometrySelection, selectionType: null });
        }
        case DataAction.SetSelectionType: {
            const { selectionType } = action as SetSelectionTypeAction;
            return updateState(state, { selectionType });
        }
        case DataAction.AddRedactionString: {
            const { redactionString, redactionInt } = action as AddRedactionInfoAction;
            return updateState(state, {
                redactionString,
                redactionInt
            });
        }
        case DataAction.AddLayers: {
            const { layers } = action as AddLayersAction;
            const visibleLayers = { ...state.visibleLayers };
            layers.forEach(layerDefinition => {
                visibleLayers[layerDefinition.key] = layerDefinition.initialVisibility
            });
            return updateState(state, { layers, visibleLayers });
        }
        case DataAction.InsertFilterDefinition: {
            const {
                filter
            } = action as FilterDefinitionAction;
            const { dimensionId } = filter as Filter;
            const insertIndex = _.findLastIndex(state.filters, { dimensionId });
            if (insertIndex >= 0 && insertIndex + 1 !== state.filters.length) {
                const filters = state.filters.slice();
                filters.splice(insertIndex + 1, 0, filter);
                updateLocation(filters);
                return { ...state, filters };
            } else {
                const filters = state.filters.concat(filter);
                updateLocation(filters);
                return { ...state, filters };
            }
        }
        case DataAction.UpdateFilterDefinition: {
            const {
                filter
            } = action as FilterDefinitionAction;
            const { filterId } = filter;
            const updateIndex = _.findLastIndex(state.filters, { filterId });
            const filters = state.filters.slice();
            filters[updateIndex] = filter;
            updateLocation(filters);
            return {
                ...state,
                filters
            };
        }
        case DataAction.DeleteFilterDefinition: {
            const {
                filter: {
                    filterId
                }
            } = action as FilterDefinitionAction;
            const filters = state.filters.slice();
            const deleteIndex = _.findLastIndex(state.filters, { filterId });
            filters.splice(deleteIndex, 1);
            updateLocation(filters);
            return {
                ...state,
                filters
            };
        }
        case DataAction.ClearAllFilters: {
            const filters = state.filters.slice().map(filter => {
                if (isFilter(filter)) {
                    return {
                        ...filter,
                        comparatorValue: undefined
                    }
                } else if (isSimpleFilter(filter)) {
                    return {
                        ...filter,
                        settings: undefined
                    }
                }
            });
            return {
                ...state,
                filters
            };
        }
        case DataAction.MoreInfoAboutDataPoint: {
            const { data: moreInfoModalData, focus } = action as MoreInfoModalAction;
            const moreInfoModalVisible = isDefined(moreInfoModalData);
            if (moreInfoModalVisible) {
                analytics.track(AnalyticsEvent.OpenMoreInfoModal).catch(console.error);
            }
            const stateUpdate: Partial<DataState> = { moreInfoModalData, moreInfoModalVisible };
            if (!!focus) {
                stateUpdate.focusId = moreInfoModalData.id
            }
            return updateState(state, stateUpdate);
        }
        case DataAction.ShowAddNewAccidentModal: {
            analytics.track(AnalyticsEvent.OpenNewAccidentModal).catch(console.error);
            return updateState(state, { addNewAccidentModalVisible: true });
        }
        case DataAction.HideAddNewAccidentModal: {
            return updateState(state, { addNewAccidentModalVisible: false });
        }
        default:
            return state
    }
}
