import {RiskInputOption, RiskInputOptionWithColor} from "../types";
import colorLib from '@kurkle/color';

export function findClosestColor(
    value: number|undefined,
    possibleValues: RiskInputOption[]
) {
    let index = 0;
    let diff = Infinity;
    possibleValues.forEach(({ value: possibleValue }, possibleIndex) => {
        const possibleDiff = Math.abs(possibleValue - (value || 0))
        if (possibleDiff < diff) {
            diff = possibleDiff;
            index = possibleIndex;
        }
    });
    return possibleValues[index].color;
}

export function colorFromValue(
    value: number|undefined,
    possibleValues: RiskInputOption[]
) {
    const {
        color = 'black'
    } = possibleValues
        .filter(({ value: possibleValue }) => possibleValue === value).pop() as RiskInputOptionWithColor;
    return color;
}

export function addOpacityToColor(value: string, opacity: number) {
    const alpha = opacity === undefined ? 0.5 : 1 - opacity;
    return colorLib(value).alpha(alpha).rgbString();
}