import { Action, Dispatch } from "redux";
import {AuthCheckFailureAction, LogoutAction} from "../interfaces/actions/auth";
import { SetUserMenuVisibleAction } from "../interfaces/actions/auth";
import LoginResponse from "../interfaces/UserData";

export const enum AuthAction {
    Logout = 'LOGOUT',
    LogoutRequest = 'LOGOUT_REQUEST',
    LogoutSuccess = 'LOGOUT_SUCCESS',
    LogoutFailure = 'LOGOUT_FAILURE',
    SetUserMenuVisible = 'SET_USER_MENU_VISIBLE',
    AuthCheck = "AUTH_CHECK",
    AuthCheckRequest = "AUTH_CHECK_REQUESTED",
    AuthCheckSuccess = "AUTH_CHECK_SUCCESS",
    AuthCheckFailure = "AUTH_CHECK_FAILURE",
}

export function setUserMenuVisible(
    visible: boolean = false
) {
    return (dispatch: Dispatch) => {
        const type = AuthAction.SetUserMenuVisible;
        const action: SetUserMenuVisibleAction = { type, visible };
        dispatch(action);
    };
}

export function authCheck(): Action {
    return { type: AuthAction.AuthCheck };
}
  
export function authCheckRequest(): Action {
    return { type: AuthAction.AuthCheckRequest };
}
  
export function authCheckSuccess(
    data: LoginResponse
): Action & LoginResponse {
    return { type: AuthAction.AuthCheckSuccess, ...data };
}
  
export function authCheckFailure(
  error: any
): AuthCheckFailureAction {
    const {
        response
    } = error;
    return {
        type: AuthAction.AuthCheckFailure,
        errorStatus: response ? response.statusCode : -1
    };
}

export function logout(): LogoutAction {
    return { type: AuthAction.Logout };
}

export function logoutRequest(): Action {
    return { type: AuthAction.LogoutRequest };
}
  
export function logoutSuccess(): Action {
    return { type: AuthAction.LogoutSuccess };
}

export function logoutFailure(): Action {
    return { type: AuthAction.LogoutFailure };
}
