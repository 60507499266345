import {Action} from "redux";
import {LayoutAction} from "../actions/layout";
import LayoutState from "../interfaces/states/LayoutState";
import {SetLayoutAlternativeAction} from "../interfaces/actions/layout";
import {updateState} from "../../utils/miscUtilities";

const initialState: LayoutState = {
    dragging: true,
}

// Use the initialState as a default value
export default function layout(
    state = initialState,
    action: Action
) {
    switch (action.type) {
        case LayoutAction.SetDragging: {
            const { dragging } = action as SetLayoutAlternativeAction;
            return updateState(state, { dragging });
        }
        default:
            return state
    }
}
