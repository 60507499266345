import {Feature} from "ol";
import {Geometry, Point} from "ol/geom";
import {isDefined} from "./validationUtilities";

export function getFirstFeatureFromCluster<Type extends Geometry>(
    cluster: Feature<Point>
): Feature<Type>|null {
    const features = cluster.get('features');
    if (isDefined<Feature<Type>[]>(features) && features.length > 0) {
        return features[0];
    }
    return null;
}

export function haversine(lat1: number, lon1: number, lat2: number, lon2: number, unit: "K"|"N") {
    if ((lat1 == lat2) && (lon1 == lon2)) {
        return 0;
    }
    else {
        const radLat1 = Math.PI * lat1/180;
        const radLat2 = Math.PI * lat2/180;
        const theta = lon1-lon2;
        const radTheta = Math.PI * theta/180;
        let dist = Math.sin(radLat1) * Math.sin(radLat2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.cos(radTheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180/Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit=="K") { dist = dist * 1.609344 }
        if (unit=="N") { dist = dist * 0.8684 }
        return dist;
    }
}
