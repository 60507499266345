import React, {Key, PropsWithChildren, useMemo} from "react";
import {joinClassNames} from "../../../utils/miscUtilities";
import {useRiskModelContext} from "./RiskModelViewer";
import {Indicator} from "./Indicator";

// @ts-ignore
import className from "../../../assets/scss/components/riskmodel.scss";

interface BoxProperties extends PropsWithChildren<{}> {
    label: string;
    className?: string;
    value: number;
    comparisonValue?: number;
}
export default function Box(props: BoxProperties) {
    const {
        children,
        label,
        className: inputClassName,
        value,
        comparisonValue
    } = props;

    const {
        network
    } = useRiskModelContext();

    const trend = useMemo(() => {
        if (comparisonValue) {
            const diff = value - comparisonValue;
            return diff === 0 ? 0 : diff/Math.abs(diff);
        }
        return undefined;
    }, [comparisonValue, value]);

    return (
        <div className={joinClassNames(className.labelBox, inputClassName)}>
            <div className={className.label}>
                { label }
            </div>
            <Indicator
                trend={trend}
                noLabel={true}
                value={value}
                possibleValues={network.possibleValues} />
            { children }
        </div>
    );
}