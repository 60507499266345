import * as React from "react";
import {useInjection} from "inversify-react";
import Injectable from "../../injection/injectable";
import {ToolbarComponent} from "../../constants/globalTypes";
import {PropsWithChildren} from "react";

import className from '../../assets/scss/main.scss';

export default function SharedLayout(props: PropsWithChildren<{}>) {

  const { children } = props;
  const Toolbar = useInjection<ToolbarComponent>(Injectable.Toolbar);
  return (
    <div className={className.mainLayout}>
      <Toolbar />
      <div className={className.content}>
        { children }
      </div>
    </div>
  );
}
