import {Operator} from "./enums";

export const OPERATORS = {
    [Operator.LesserThanOrEqual]: "≤",
    [Operator.LesserThan]: "<",
    [Operator.Equal]: "=",
    [Operator.NotEqual]: "≠",
    [Operator.GreaterThan]: ">",
    [Operator.GreaterThanOrEqual]: "≥",
    [Operator.In]: "IN",
}
