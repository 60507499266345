import React, {useCallback, useMemo, useState} from "react";
import {Button, Modal} from "antd";
import {
  Component,
  ComponentData,
  OnDataChangeCallback, PartialComponent,
} from "../../constants/globalTypes";
import {ComponentType} from "../../constants/enums";
import {injectComponentEditor, injectComponentRenderer} from "../../utils/injectionUtilities";
import {callSetterOnValueChange} from "../../utils/hooks";

function getWidth(
  initialComponent?: PartialComponent
) {
  switch (initialComponent?.type) {
    case ComponentType.Trend:
      return 1000;
    case ComponentType.Statistic:
      return 700;
    case ComponentType.Categorical:
    default:
      return 1000;
  }
}

function getTitle(
  initialComponent?: PartialComponent,
  isEditingExistingComponent: boolean = false
) {
  const action = isEditingExistingComponent ? "Rediger" : "Legg til";
  switch (initialComponent?.type) {
    case ComponentType.Trend:
      return `${action} Trend Diagram`;
    case ComponentType.Statistic:
      return `${action} Statistikk`;
    case ComponentType.Categorical:
      return `${action} Kategorisk diagram`;
    default:
      return initialComponent?.type
        ? `Somethings wrong. Maybe you forgot to implement a case for ${ComponentType[initialComponent.type]}`
        : '';
  }
}

interface ComponentEditorProperties {
  type: ComponentType;
  component: PartialComponent;
  onDataChange: OnDataChangeCallback;
  isEditingExistingComponent: boolean;
}

function ComponentEditor(
  props: ComponentEditorProperties
) {
  const {
    type,
    component,
    onDataChange,
    isEditingExistingComponent
  } = props;
  const ComponentEditorInstance = injectComponentEditor(type);
  const ComponentRendererInstance = injectComponentRenderer(type);
  return (
    <ComponentEditorInstance
      isEditingExistingComponent={isEditingExistingComponent}
      renderer={ComponentRendererInstance}
      data={component.data}
      onDataChange={onDataChange} />
  )
}

export interface AddComponentModalProperties {
  isEditingExistingComponent: boolean;
  open: boolean;
  onFinish: (component: Component) => void;
  onHide: () => void;
  initialComponent?: PartialComponent;
}

function AddOrEditComponentModal(props: AddComponentModalProperties) {
  const {
    open,
    onFinish,
    onHide,
    initialComponent,
    isEditingExistingComponent
  } = props;

  const width = useMemo(() => getWidth(initialComponent), [initialComponent]);
  const title = useMemo(
    () => getTitle(initialComponent, isEditingExistingComponent), [initialComponent, isEditingExistingComponent]);
  const [valid, setValid] = useState(false);
  const [component, setComponent] = useState<PartialComponent|undefined>(initialComponent);
  callSetterOnValueChange(setComponent, initialComponent);

  const onDataChange = useCallback((type: ComponentType, data: ComponentData, valid: boolean) => {
    setValid(valid);
    setComponent({ ...component, type, data });
  }, [component, setValid, setComponent]);

  return (
    <Modal
      title={title}
      open={open}
      destroyOnClose={true}
      onCancel={onHide}
      width={width}
      footer={[
        <Button
          key="close"
          disabled={!valid}
          type="primary" onClick={() => {
          if (component && valid) {
            onFinish(component as Component);
            onHide();
          }
        }} >
          {
            isEditingExistingComponent ? "Oppdater" : "Legg til"
          }
        </Button>
      ]}>
      <div style={{ overflow: 'hidden' }}>
        {
          component && component.type
            ? (
              <ComponentEditor
                isEditingExistingComponent={isEditingExistingComponent}
                type={component.type}
                component={component}
                onDataChange={onDataChange} />
            ) : <></>
        }
      </div>
    </Modal>
  );
}

export default AddOrEditComponentModal;
