import Node from "./Node";
import { v4 as uuid } from "uuid";
import {Key} from "react";
export default class NodeGroup {

    private readonly _nodeIds: string[];
    private readonly _label: string;

    private readonly _id: string;

    constructor(
        nodes: Node[],
        label: string,
        id?: string,
    ) {
        this._nodeIds = nodes.map(node => node.id);
        this._label = label;
        this._id = id || uuid();
    }

    public serialize() {
        return {
            nodes: this._nodeIds,
            label: this._label,
            id: this._id
        };
    }

    public get nodeIds() {
        return this._nodeIds;
    }

    public get label() {
        return this._label;
    }

    public get id(): string {
        return this._id;
    }

}