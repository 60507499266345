import React, {useCallback, useEffect, useMemo, useState} from "react";
import * as ol from "ol";
import { Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useInjection } from "inversify-react";
import {faLayerGroup} from "@fortawesome/free-solid-svg-icons";
import MapControlPopoverItem from "../../interfaces/MapControlPopoverItem";
import { MapControlComponent } from "../../constants/globalTypes";
import Injectable from "../../injection/injectable";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import BaseLayer from "ol/layer/Base";
import _ from "lodash";
import {useMap} from "../dataviews/map/OlMainMap";

function getLayersFromMap(
  map?: ol.Map
): BaseLayer[] {
  return map?.getLayers().getArray().filter(layer => layer.getProperties().includeInLayerSwitcher != false) || [];
}

export default function AntDLayerSwitcherControl() {

  const map = useMap();
  const [layers, setLayers] = useState<BaseLayer[]>([]);

  useEffect(() => {
    if (map) {
      map?.on('change', () => {
        const nextLayers = getLayersFromMap(map);
        if (!_.isEqual(layers, nextLayers)) {
          setLayers(nextLayers);
        }
      });
    }
  }, [map]);

  const layerLookup = useMemo(() => {
    const layerLookup: Record<string, BaseLayer> = {};
    layers?.forEach(layer => {
      const { key } = layer.getProperties();
      layerLookup[key] = layer;
    });
    return layerLookup;
  }, [layers]);

  const toggleLayerVisibility = useCallback((layerKey: string) => {
    const layer = layerLookup[layerKey];
    layer.setVisible(!layer.getVisible());
  }, [layerLookup]);

  const MapControl = useInjection<MapControlComponent>(Injectable.PopoverWrapper);
  const element = <Button
    type="default"
    icon={<FontAwesomeIcon icon={faLayerGroup as IconProp} />}
    size="large" />;

  const popoverItems = useMemo(() => {
    return layers?.map(layer => {
      const { key, label } = layer.getProperties();
      return {
        type: "check",
        key: key,
        label: label,
        onClick: () => toggleLayerVisibility(key),
        category: "andre",
        value: layer.getVisible()
      } as MapControlPopoverItem;
    })
  }, [layers, toggleLayerVisibility]);

  return (
    <MapControl
      element={element}
      tooltipTitle="Kartlag"
      popoverItems={popoverItems}/>
  );
}
