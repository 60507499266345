import Map from "ol/Map";
import React, {CSSProperties, useEffect, useRef} from "react";
import {whenDefined} from "../../utils/validationUtilities";
import {Tile} from "ol/layer";
import {OSM, XYZ} from "ol/source";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import {Circle, Fill, Style} from "ol/style";
import {FOCUS_COLOR} from "../../constants/colors";
import {Point} from "ol/geom";
import {Feature} from "ol";
import {fromLonLat} from "ol/proj";
import {boundingExtent} from "ol/extent";
import {getNightMode} from "../../store/selectors/user";
import {useSelector} from "react-redux";
import {LayerKey} from "../../constants/enums";

export interface CoordinatesMapProperties {
    coordinates: [number, number][];
    className?: string;
    style?: CSSProperties;
}


function createFeatureLayer() {
    const featureSource = new VectorSource({
        wrapX: false
    });
    return new VectorLayer({
        source: featureSource,
        style: new Style({
            image: new Circle({
                radius: 8,
                fill: new Fill({
                    color: FOCUS_COLOR,
                }),
            }),
            zIndex: 100
        }),
        properties: {
            label: 'Ulykker'
        }
    });
}

function addMarkersToVectorLayer(
    map: Map,
    featureLayer: VectorLayer<any>,
    coordinates: [number, number][]
) {
    coordinates = coordinates.map(coordinate => fromLonLat(coordinate) as [number, number]);
    const features = coordinates.map(coordinate => {
        const geometry = new Point(coordinate);
        return new Feature({ geometry });
    });
    const source = featureLayer.getSource() as VectorSource<Point>;
    source.clear(true);
    if (features.length > 0) {
        source.addFeatures(features);
        const featuresBoundingExtent = boundingExtent(coordinates);
        map.getView().fit(featuresBoundingExtent, {
            duration: 0
        });
        map.getView().setZoom(6);
    }
}

export function OlCoordinatesMap(props: CoordinatesMapProperties) {
    const {
        coordinates = [],
        className = "",
        style = {}
    } = props;
    const baseLayerLight = useRef<Tile<XYZ>>();
    const baseLayerDark = useRef<Tile<XYZ>>();
    const nightMode = useSelector(getNightMode);
    const featureLayer = useRef<VectorLayer<any>>();
    const map = useRef<Map>();
    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        whenDefined(() => ref.current)
            .then(() => {
                baseLayerDark.current = new Tile({
                    source: new XYZ({
                        url: 'https://{a-c}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png'
                    }),
                    visible: true
                });
                baseLayerLight.current = new Tile({
                    source: new OSM(),
                    visible: true
                });
                featureLayer.current = createFeatureLayer();
                map.current = new Map({
                    controls: [],
                    layers: [
                        nightMode ? baseLayerDark.current : baseLayerLight.current,
                        featureLayer.current
                    ],
                    target: ref.current!,
                    interactions: []
                });
                addMarkersToVectorLayer(map.current, featureLayer.current, coordinates);
            });
    }, []);
    useEffect(() => {
        if (map.current && featureLayer.current) {
            addMarkersToVectorLayer(map.current, featureLayer.current, coordinates);
        }
    }, [map.current, coordinates])
    useEffect(() => {
        if (baseLayerDark.current && baseLayerLight.current) {
            if (nightMode) {
                map.current?.addLayer(baseLayerDark.current);
                map.current?.removeLayer(baseLayerLight.current);
            } else {
                map.current?.addLayer(baseLayerLight.current);
                map.current?.removeLayer(baseLayerDark.current);
            }
        }
    }, [nightMode]);
    return <div ref={ref} className={className} style={style}  />;
}
