import React, {memo, useMemo} from "react";
import {Handle, NodeProps, Position} from "reactflow";
import {GroupNodeData} from "../types";
import {Dropdown} from "antd";
import _ from "lodash";
import {useRiskModelContext} from "./RiskModelViewer";
import Box from "./Box";
import {optionalClassNames} from "../../../utils/miscUtilities";
import {Indicator} from "./Indicator";

// @ts-ignore
import className from "../../../assets/scss/components/riskmodel.scss";


const GroupNode = memo((
    props: NodeProps<GroupNodeData>
) => {

    const {
        isConnectable,
        id,
        data: {
            label,
            nodes,
            hasIncomingEdges,
            hasOutingEdges,
        },
    } = props;

    const {
        updateValueForNode,
        setStatisticsCategory,
        statisticsCategory,
        comparisonValues
    } = useRiskModelContext();


    const value = useMemo(() => {
        const sum = nodes
            .map(({ currentValue, defaultValue }) => currentValue || defaultValue)
            .reduce((a, b) => a + b, 0);
        return (sum / nodes.length) || 0;
    }, [nodes]);

    const comparisonValue = useMemo(
        () => comparisonValues ? comparisonValues[id] : undefined, [comparisonValues, id]);

    return (
        <div className={className.groupNode}>
            <Box
                label={label}
                value={value}
                comparisonValue={comparisonValue}>
                {
                    hasIncomingEdges && (
                        <Handle
                            type="target"
                            position={Position.Left}
                            id="in"
                            style={{ background: 'black', top: 16 }}
                            isConnectable={isConnectable}
                        />
                    )
                }
                {
                    hasOutingEdges && (
                        <Handle
                            type="source"
                            position={Position.Right}
                            id="out"
                            style={{ background: 'black', top: 16 }}
                            isConnectable={isConnectable} />
                    )
                }
            </Box>
            <div className={className.inputBox}>
                {
                    nodes.map(node => {
                        const isActive = _.isString(node.statisticsCategory)
                            && node.statisticsCategory === statisticsCategory;
                        return (
                            <div
                                className={
                                    optionalClassNames({
                                        [className.input]: true,
                                        [className.active]: isActive,
                                        'nodrag': true
                                    })
                                }>
                                <div
                                    className={className.label}
                                    onClick={() => setStatisticsCategory(node.statisticsCategory)}>
                                    {node.label}
                                </div>
                                <Dropdown
                                    trigger={["click"]}
                                    className="nodrag"
                                    menu={{
                                        items: node.possibleValues.map(({value, label}) => ({
                                            key: value,
                                            label: <Indicator
                                                value={value}
                                                label={label}
                                                possibleValues={node.possibleValues}/>,
                                            onClick: () => updateValueForNode(node.id, value)
                                        })),
                                        className: className.indicatorDropdown
                                    }}>
                                    <div className={className.indicatorTrigger}>
                                        <Indicator
                                            value={node.currentValue || node.defaultValue}
                                            possibleValues={node.possibleValues}/>
                                    </div>
                                </Dropdown>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
});

export default GroupNode;