import StatisticDefinition from "../interfaces/StatisticDefinition";
import {useMemo} from "react";
import {v4 as uuid} from "uuid";
import {FilterType} from "../../filterRenderer/enums";
import Network from "../models/Network";

const YEARS = [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023];
const COUNTIES = [
    "Viken",
    "Agder",
    "Nordland",
    "Rogaland",
    "Vestland",
    "Trøndelag",
    "Troms og Finnmark",
    "Vestfold og Telemark",
    "Innlandet",
    "Møre og Romsdal",
    "Oslo"
]

function randomIntFromInterval(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min)
}

export function useRiskStatisticsDummyData(network: Network): StatisticDefinition[] {

    const {
        nodes = []
    } = network.serialize();

    return useMemo(() => nodes
        .filter(node => !!node.statisticsCategory)
        .map(node => node.statisticsCategory)
        .flatMap(category => {
            return [
                {
                    id: uuid(),
                    plugin: {
                        type: 'bar',
                        config: {
                            xColumn: "year",
                            yColumn: "value",
                            stackColumn: "county",
                            label: 'Dummy Data 1'
                        }
                    },
                    category: category!,
                    data: YEARS.flatMap(year => COUNTIES.map(county => ({
                        year, county, value: randomIntFromInterval(10, 80)
                    }))),
                    filters: [
                        { column: "county", type: FilterType.Categorical, label: "Fylke" }
                    ]
                },
                {
                    id: uuid(),
                    plugin: {
                        type: 'bar',
                        config: {
                            xColumn: "year",
                            yColumns: ["value"],
                            label: 'Dummy Data 2'
                        }
                    },
                    category: category!,
                    data: YEARS.flatMap(year => COUNTIES.map(county => ({
                        year, county, value: randomIntFromInterval(10, 80)
                    }))),
                    filters: [
                        { column: "county", type: FilterType.Categorical, label: "Fylke" }
                    ]
                },
            ]
        }), []);
}