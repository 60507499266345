// ------------------------------------------------------------------
// External Libraries Imports
// ------------------------------------------------------------------

import {Container} from 'inversify';
import 'reflect-metadata';

// ------------------------------------------------------------------
// Interface and Types Imports
// ------------------------------------------------------------------

import Injectable from './injectable';

// ------------------------------------------------------------------
// Internal Imports
// ------------------------------------------------------------------

import config from '../config';
import Config from "../interfaces/Config";
import {addBaseBuildToContainer} from "../utils/injectionUtilities";
import {Store} from "redux";

// ------------------------------------------------------------------
// Injection
// ------------------------------------------------------------------


export const container = new Container();

const { build } = config;

// Constants
container.bind<Config>(Injectable.Config).toConstantValue(config);
addBaseBuildToContainer(build, container);

export function addStoreToContainer(
    store: Store
) {
    container.bind<Store>(Injectable.Store).toConstantValue(store);
}
