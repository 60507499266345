import React, {PropsWithChildren, useRef, useState} from "react";
import SplitPane from "react-split-pane";
import {theme, Typography} from "antd";
import _ from "lodash";
import FilterList, {FilterListProperties} from "./FilterList";

// @ts-ignore
import className from "../../../assets/scss/filterView.scss";
import {joinClassNames} from "../../../utils/miscUtilities";

interface FilterBarProperties extends PropsWithChildren<{}>, FilterListProperties {
    defaultSize: number;
    minSize: number;
    onDragStart?: () => void;
    onDragEnd?: (size: number) => void;
    filterExpanded?: boolean;
    className?: string;

}

export default function FilterView(
    props: FilterBarProperties
) {
    const {
        children,
        className: filterViewClassName,
        defaultSize,
        minSize,
        filterExpanded = false,
        onDragStart = _.noop,
        onDragEnd = _.noop,
        ...filterListProps
    } = props;
    const ref = useRef<SplitPane>(null);
    const { token } = theme.useToken();
    const [width, setWidth] = useState(defaultSize);
    return (
        <SplitPane
            ref={ref}
            className={className.container}
            resizerStyle={{
                background: token.colorBorderSecondary
            }}
            split="vertical"
            onDragStarted={onDragStart}
            onDragFinished={onDragEnd}
            allowResize={filterExpanded}
            minSize={minSize}
            size={filterExpanded ? width : 0}
            onChange={setWidth}>
            <div className={className.filter}>
                {
                    filterExpanded
                        ? (
                            <div className={joinClassNames(filterViewClassName)}>
                                <div className={className.header}>
                                    <Typography.Title level={4} className={className.text}>Filtre</Typography.Title>
                                </div>
                                <FilterList {...filterListProps} />
                            </div>
                        )
                        : <></>
                }
            </div>
            <div className={className.content}>
                {children}
            </div>
        </SplitPane>
    );
}