import * as ExcelJS from 'exceljs';
import Config, {DimensionDefinition, DimensionType} from '../interfaces/Config';
import {DataPoint} from '../interfaces/models/DataPoint';
import {Filter, SimpleFilter} from "../constants/globalTypes";
import {FilterType, GeoFilterType} from "../constants/enums";
import _ from "lodash";
import {lookupFromArray} from "./miscUtilities";
import {OPERATORS} from "../constants/filter";
import * as ol from "ol";
import {Tile} from "ol/layer";
import {OSM} from "ol/source";
import {Polygon} from "ol/geom";
import {fromExtent} from "ol/geom/Polygon";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import {Feature} from "ol";
import {saveAs} from "file-saver";

function adjustColumnWidth(worksheet: ExcelJS.Worksheet) {
    worksheet.columns.forEach((column: Partial<ExcelJS.Column>) => {
        const lengths = column.values?.map(v => v?.toString().length) || [];
        const maxLength = Math.max(...lengths.filter(_.isNumber));
        column.width = Math.max(10, maxLength);
    });
}

export function downloadDataAsExcelFile(
    data: readonly DataPoint[],
    config: Config,
    filters: (Filter|SimpleFilter)[],
    ignoreTypes: DimensionType[] = []
): void {
    const dimensionLookup = lookupFromArray(config.dimensions, ({ id }) => id);
    const dimensions = config.dimensions.filter(({ type }) => !ignoreTypes.includes(type))
    const workbook = new ExcelJS.Workbook();

    const dataWorksheet = workbook.addWorksheet('Data');

    const headers = dimensions.map(({ label }) => label);
    dataWorksheet.addRow(headers);

    data.forEach((dataPoint: DataPoint) => {
        const values = dimensions.map(({ type, selector, isArray }) => {
            const value = selector(dataPoint);
            if (type === DimensionType.Date) {
                return (value as Date).toISOString();
            } else if (type === DimensionType.Numerical) {
                return isArray
                    ? (value as number[]).filter(n => n >= 0).toString()
                    : value < 0 ? "" : value.toString()
            }
            return value.toString();
        });
        dataWorksheet.addRow(values);
    });
    adjustColumnWidth(dataWorksheet);
    if (filters.length > 0) {
        const filterWorksheet = workbook.addWorksheet('Filtre');
        filterWorksheet.addRow(['Dimensjon', 'Operasjon', 'Verdi(er)']);
        const promises = filters
            .sort((a, b) => a.type - b.type)
            .filter(
                (filter) => !!(filter as Filter).comparatorValue || !!(filter as SimpleFilter).settings)
            .map(async filter => {
                const { type } = filter;
                switch (type) {
                    case FilterType.Numerical:
                    case FilterType.Categorical:
                    case FilterType.Date:
                        return addEasilyListableFilterAsRow(filter as Filter, filterWorksheet, dimensionLookup);
                    case FilterType.Text:
                        break;
                    case FilterType.Geo:
                        return addGeoFilterAsRowWithImage(filter as SimpleFilter, filterWorksheet);
                }
            });
        Promise.all(promises).then(() => {
            adjustColumnWidth(filterWorksheet);
            workbook.xlsx.writeBuffer()
                .then(buffer => saveAs(new Blob([buffer]), 'filtrert_data.xlsx'));
        });
    } else {
        workbook.xlsx.writeBuffer()
            .then(buffer => saveAs(new Blob([buffer]), 'data.xlsx'));
    }
}

function urlToFile(url: string, filename: string, mimeType: string) {
    return (fetch(url)
            .then(function (res) {
                return res.arrayBuffer();
            })
            .then(function (buf) {
                return new File([buf], filename, {type: mimeType});
            })
    );
}


async function addGeoFilterAsRowWithImage(
    filter: SimpleFilter,
    filterWorksheet: ExcelJS.Worksheet,
) {
    return new Promise((resolve, reject) => {
        return getImageOfMapWithGeoFilterPolygonAsBase64String(filter as SimpleFilter
        ).then(base64 => {
            if (_.isString(base64)) {
                filterWorksheet.addRow(['Koordinat', '∈']);
                const rowNumber = getFirstFreeRow(filterWorksheet);
                const imageId2 = filterWorksheet.workbook.addImage({
                    base64: base64,
                    extension: 'png',
                });
                const row = filterWorksheet.getRow(rowNumber);
                row.height = 562;
                row.getCell(1).alignment = { vertical: 'top', horizontal: 'left' };
                row.getCell(2).alignment = { vertical: 'top', horizontal: 'left' };
                filterWorksheet.addImage(imageId2, {
                    tl: { col: 2, row: rowNumber - 1 },
                    ext: { width: 500, height: 500 }
                });
                resolve();
            } else {
                reject();
            }
        });
    });
}
function getFirstFreeRow(
    filterWorksheet: ExcelJS.Worksheet
) {
    let i = 1;
    while (true) {
        const row = filterWorksheet.getRow(i);
        const cellValue = row.getCell(1).value;
        if (cellValue === null) {
            return i - 1;
        }
        i += 1;
    }

}

function addEasilyListableFilterAsRow(
    filter: Filter,
    filterWorksheet: ExcelJS.Worksheet,
    dimensionLookup: Record<string, DimensionDefinition<DataPoint>>
) {
    const { dimensionId, operator, comparatorValue } = filter as Filter;
    const { label } = dimensionLookup[dimensionId];
    const values = _.isArray(comparatorValue)
        ? comparatorValue.map(value => `"${value}"`).join(" eller ")
        : comparatorValue!.toString();
    filterWorksheet.addRow([label, OPERATORS[operator], values]);
    return Promise.resolve();
}

async function getImageOfMapWithGeoFilterPolygonAsBase64String(
    filter: SimpleFilter
) {
    const div = document.createElement('div');
    div.setAttribute('style', 'width: 500px; height: 500px; position: absolute; background: red; top: 0; visibility: hidden;')
    document.body.appendChild(div);
    const map = createFilterMap(div, filter);
    return saveMap(map).then(base64 => {
        map.setTarget(undefined);
        div.remove()
        return base64;
    });
}

function createFilterMap(
    target: HTMLDivElement,
    filter: SimpleFilter
) {
    const baseLayer = new Tile({
        source: new OSM()
    });
    const features = [new Feature({
        geometry: getFilterPolygon(filter)
    })];
    const source = new VectorSource<Polygon>({ features });
    const vectorLayer = new VectorLayer({ source });
    const map = new ol.Map({
        controls: [],
        target: target,
        layers: [
            baseLayer,
            vectorLayer
        ],
        view: new ol.View({
            center: [ 1543064.7758262376, 12342707.078435263 ],
            zoom: 2
        })
    });
    map.getView().fit(source.getExtent(), {
        size: map.getSize(),
        maxZoom: 3.5
    });
    return map;
}

function getFilterPolygon(
    filter: SimpleFilter
) {
    const {
        type,
        value
    } = filter.settings;
    switch (type) {
        case GeoFilterType.Polygon: {
            return new Polygon([value]);
        }
        case GeoFilterType.Extent: {
            return fromExtent(value);
        }
    }
}

async function saveMap(
    map: ol.Map
) {
    return new Promise(resolve => {
        map.once('rendercomplete', () => {
            const mapCanvas = document.createElement('canvas');
            const size = map.getSize()
            if (size) {
                mapCanvas.width = size[0];
                mapCanvas.height = size[1];
                const mapContext = mapCanvas.getContext('2d');
                if (mapContext) {
                    Array.prototype.forEach.call(
                        map.getViewport().querySelectorAll('.ol-layer canvas, canvas.ol-layer'),
                        function (canvas) {
                            if (canvas.width > 0) {
                                const opacity =
                                    canvas.parentNode.style.opacity || canvas.style.opacity;
                                mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
                                let matrix;
                                const transform = canvas.style.transform;
                                if (transform) {
                                    // Get the transform parameters from the style's transform matrix
                                    matrix = transform
                                        .match(/^matrix\(([^\(]*)\)$/)[1]
                                        .split(',')
                                        .map(Number);
                                } else {
                                    matrix = [
                                        parseFloat(canvas.style.width) / canvas.width,
                                        0,
                                        0,
                                        parseFloat(canvas.style.height) / canvas.height,
                                        0,
                                        0,
                                    ];
                                }
                                // Apply the transform to the export map context
                                CanvasRenderingContext2D.prototype.setTransform.apply(
                                    mapContext,
                                    matrix
                                );
                                const backgroundColor = canvas.parentNode.style.backgroundColor;
                                if (backgroundColor) {
                                    mapContext.fillStyle = backgroundColor;
                                    mapContext.fillRect(0, 0, canvas.width, canvas.height);
                                }
                                mapContext.drawImage(canvas, 0, 0);
                            }
                        }
                    );
                    mapContext.globalAlpha = 1;
                    mapContext.setTransform(1, 0, 0, 1, 0, 0);
                    const mapImage = mapCanvas.toDataURL();
                    resolve(mapImage);
                }
            }
        });
    })
}