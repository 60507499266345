import {all, call, fork, put, select, takeEvery} from "redux-saga/effects";
import GlobalState from "../interfaces/states/GlobalState";
import {
  hydrateUserDataFailure,
  hydrateUserDataRequest,
  hydrateUserDataSuccess,
  persistUserDataFailure,
  persistUserDataRequest,
  persistUserDataSuccess,
  UserAction,
} from "../actions/user";
import {hydrateUserDataAsync, persistUserDataAsync} from "../services/user";

const {
  UpdateHomeStatisticView,
  AddSavedFilter,
  HydrateUserData,
  ToggleNightMode,
  SetActiveDimensionIdsInMoreInfoModal,
  SetActiveDimensionIdsInDataCard,
  UpdateAccidentLayerConfig,
  UpdateDimensionGraphs
} = UserAction;

function* persistState() {
  try {
    const userData = yield select((state: GlobalState) => state.user.data);
    localStorage.setItem("fbp-user-data", JSON.stringify(userData));
    yield put(persistUserDataRequest());
    yield call(persistUserDataAsync, userData);
    yield put(persistUserDataSuccess());
  } catch (error) {
    yield put(persistUserDataFailure());
  }
}

function* onHydrateUserData() {
  try {
    yield put(hydrateUserDataRequest());
    const userData = yield call(hydrateUserDataAsync);
    yield put(hydrateUserDataSuccess(userData));
  } catch (error) {
    yield put(hydrateUserDataFailure());
  }
}

function* watchChangesToUserData() {
  yield takeEvery([
    UpdateHomeStatisticView,
    AddSavedFilter,
    ToggleNightMode,
    SetActiveDimensionIdsInMoreInfoModal,
    SetActiveDimensionIdsInDataCard,
    UpdateAccidentLayerConfig,
    UpdateDimensionGraphs
  ], persistState);
}

function* watchHydrateUserData() {
  yield takeEvery([
    HydrateUserData
  ], onHydrateUserData);
}

export default function* userSaga() {
  yield all([fork(watchChangesToUserData), fork(watchHydrateUserData)]);
}
