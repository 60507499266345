import React, {Key, useState} from "react";
import {Button} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {groupFilters} from "../utilities/helpers";
import { Filter } from "../types";
import FilterRenderer from "./FilterRenderer";

// @ts-ignore
import className from "../../../assets/scss/filterView.scss";

export interface FilterListProperties {
    filters: Filter[];
    onChangeFilter: (filter: Filter) => void;
    onDeleteFilter: (filter: Filter) => void;
    clearAllFilters: () => void;
    canAdd?: boolean;
    canDelete?: boolean;

}

export default function FilterList(props: FilterListProperties) {
    const {
        filters,
        clearAllFilters,
        onChangeFilter,
        onDeleteFilter,
        canAdd = true,
        canDelete
    } = props;
    const [showAddFilterModal, setShowAddFilterModal] = useState(false);
    return (
        <>
            <Button.Group className={className.buttonGroup}>
                {
                    canAdd && (
                        <Button
                            type="default"
                            onClick={() => setShowAddFilterModal(true)}
                            icon={<PlusOutlined />}
                        />
                    )
                }
                <Button
                    type="default"
                    onClick={clearAllFilters}
                    className={className.flexButton}>
                    Nullstill alle
                </Button>
            </Button.Group>
            <div className={className.scroll}>
                {
                    groupFilters(filters)
                        .map((filters, index) => {
                        const { label } = filters[0];
                        return (
                            <FilterRenderer
                                key={index}
                                label={label}
                                canDelete={canDelete}
                                onChange={onChangeFilter}
                                onDelete={onDeleteFilter}
                                filters={filters} />
                        )
                    })
                }
            </div>
        </>
    );
}