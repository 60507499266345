import {useInjection} from "inversify-react";
import {MapControlComponent} from "../../constants/globalTypes";
import Injectable from "../../injection/injectable";
import {Button} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import GlobalState from "../../store/interfaces/states/GlobalState";
import {bindActionCreators, Dispatch} from "redux";
import {toggleExpandFilter} from "../../store/actions/user";
import {connect, ConnectedProps} from "react-redux";
import React from "react";

function AntDFilterListControl(props: PropsFromRedux) {
  const {
    filterExpanded,
    toggleExpandFilter
  } = props;
  const MapControl = useInjection<MapControlComponent>(Injectable.PopoverWrapper);
  const element = <Button
    type={filterExpanded ? "primary" : "default"}
    icon={<FontAwesomeIcon icon={faFilter as IconProp} />}
    onClick={toggleExpandFilter}
    size="large" />;
  return (
    <MapControl
      element={element}
      tooltipTitle="Filterliste" />
  );
}

function mapStateToProps(state: GlobalState) {
  const { filterExpanded } = state.user.data;
  return { filterExpanded };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({
    toggleExpandFilter
  }, dispatch)
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(AntDFilterListControl);

