import React, {useState} from "react";
import GlobalState from "../../store/interfaces/states/GlobalState";
import {bindActionCreators, Dispatch} from "redux";
import {connect, ConnectedProps} from "react-redux";
import {Button, Modal, Typography} from "antd";
import {focusOnDataPoint, hideMoreInfoAboutDataPoint} from "../../store/actions/data";
import {analytics, AnalyticsEvent} from "../analytics";
import Injectable from "../../injection/injectable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGear} from "@fortawesome/free-solid-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {AccidentDataTableProperties} from "./AntDAccidentDataTable";
import {injectReactComponent} from "../../utils/injectionUtilities";
import {setActiveDimensionIdsInMoreInfoModal} from "../../store/actions/user";
import {DEFAULT_ACTIVE_DIMENSION_IDS_IN_MORE_INFO_MODAL} from "../../constants/defaults";
import {IncludeColumnsModalProperties} from "./IncludeColumnsModal";

import className from "../../assets/scss/components/infomodal.scss"

function AntDDataPointInfoModal(
  props: PropsFromRedux
) {
    const AccidentDataTable = injectReactComponent<AccidentDataTableProperties>(Injectable.AccidentDataTable);
    const {
        hideMoreInfoAboutDataPoint,
        showAddNewAccidentModal,
        showMoreInfoModal,
        focusOnDataPoint,
        moreInfoModalData,
        setActiveDimensionIdsInMoreInfoModal,
        activeDimensionIds = DEFAULT_ACTIVE_DIMENSION_IDS_IN_MORE_INFO_MODAL
    } = props;
    const [editing, setEditing] = useState(false);
    const IncludeColumnsModal = injectReactComponent<IncludeColumnsModalProperties>(Injectable.IncludeColumnsModal);

    return (
      <Modal
        bodyStyle={{ padding: 0 }}
        title={
            <Typography.Title level={3} style={{ margin: 0, padding: 0 }}>
                Mer info
            </Typography.Title>
        }
        open={showMoreInfoModal && !showAddNewAccidentModal}
        onOk={hideMoreInfoAboutDataPoint}
        onCancel={hideMoreInfoAboutDataPoint}
        width={1000}
        footer={[
            <div className={className.footer}>
                <div className={className.editButtonContainer}>
                    <Button
                      type="default"
                      onClick={() => setEditing(true)}
                      icon={
                          <FontAwesomeIcon
                            className={className.icon}
                            icon={faGear as IconProp} />
                      } />
                </div>
                <Button key="showInMap" type="default" onClick={() => {
                    const { id } = moreInfoModalData!;
                    focusOnDataPoint(id);
                    hideMoreInfoAboutDataPoint();
                    analytics
                      .track(AnalyticsEvent.ShowInMap, { id })
                      .catch(console.error);
                }}>
                    Vis I Kart
                </Button>
                <Button key="close" type="primary" onClick={hideMoreInfoAboutDataPoint}>
                    Ok
                </Button>
            </div>
        ]}>
          <AccidentDataTable activeDimensionIds={activeDimensionIds} />
          <IncludeColumnsModal
            show={editing}
            activeDimensionIds={activeDimensionIds}
            onClose={() => setEditing(false)}
            onDimensionsChange={setActiveDimensionIdsInMoreInfoModal} />
      </Modal>
    );
}

function mapStateToProps(state: GlobalState) {
    const {
        moreInfoModalVisible: showMoreInfoModal,
        moreInfoModalData: moreInfoModalData,
        addNewAccidentModalVisible: showAddNewAccidentModal } = state.data;
    const {
        data: {
            activeDimensionIdsInMoreInfoModal: activeDimensionIds
        }
    } = state.user;
    return {
        showAddNewAccidentModal,
        showMoreInfoModal,
        moreInfoModalData,
        activeDimensionIds
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators({
        hideMoreInfoAboutDataPoint,
        focusOnDataPoint,
        setActiveDimensionIdsInMoreInfoModal
    }, dispatch)
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(AntDDataPointInfoModal);
