import {GeoJSONLayerConfig} from "../interfaces/Config";
import {LayerKey} from "../constants/enums";
import {
  FYLKER_GEO_JSON_COLOR, FYLKER_GEO_NIGHT_MODE_JSON_COLOR,
  HOVEDOGBILED_GEO_JSON_COLOR,
  TSS_AREA_GEO_JSON_COLOR,
  TSS_BORDER_GEO_JSON_COLOR
} from "../constants/colors";
import {Fill, Stroke, Style, Text} from "ol/style";
import {hexToRGB} from "../utils/miscUtilities";

export const geoJSONLayers: GeoJSONLayerConfig[] = [
  {
    id: LayerKey.HovedOgBiled,
    url: '/static/geojson/hovedogbiled.geojson',
    label: 'Hoved og biled',
    styleColor: () => HOVEDOGBILED_GEO_JSON_COLOR
  },
  {
    id: LayerKey.TssArea,
    url: '/static/geojson/tssomroder.geojson',
    label: 'TSS områder',
    styleColor: () => TSS_AREA_GEO_JSON_COLOR
  },
  {
    id: LayerKey.TssBorder,
    url: '/static/geojson/tssgrenser.geojson',
    label: 'TSS grenser',
    styleColor: () => TSS_BORDER_GEO_JSON_COLOR
  },
  {
    id: LayerKey.Counties,
    url: '/static/geojson/fylker.geojson',
    label: 'Fylker',
    style: (feature, nightMode) => {
      const color = nightMode ? FYLKER_GEO_NIGHT_MODE_JSON_COLOR : FYLKER_GEO_JSON_COLOR;
      return new Style({
        fill: new Fill({
          color: hexToRGB(color, 0.1)
        }),
        stroke: new Stroke({
          color: color,
          width: 1
        }),
        text: new Text({
          text: feature.get('navn'),
          scale: 1.5,
          fill: new Fill({
            color: '#e2d6d6'
          }),
          stroke: new Stroke({
            color: hexToRGB(color, 0.7),
            width: 3.5
          })
        })
      });
    }
  },
]
