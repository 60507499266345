import React, {useMemo} from "react";
import {Result} from "antd";

export interface ErrorViewProperties {
  status: string;
  errorMessage: string;
}

export default function Error(
  props: any
) {
  const {
    error,
    message
  } = useMemo(() => {
    const urlSearchParams = new URLSearchParams(props.location.search);
    const error = urlSearchParams.get('error');
    const message = urlSearchParams.get('message');
    return { error, message }
  }, []);
  return (
    <Result
      status={error as any}
      title={error}
      subTitle={message}
    />
  )
}
