import {Form, Radio, Select} from "antd";
import {Chart, ComponentType, Metric, DimensionTransform, Unit} from "../../constants/enums";
import React from "react";
import {DimensionType} from "../../interfaces/Config";
import Injectable from "../../injection/injectable";
import {Trend} from "../../constants/globalTypes";
import ComponentEditorProperties from "../../interfaces/properties/ComponentEditorProperties";
import {injectReactComponent} from "../../utils/injectionUtilities";
import {PreviewAreaProperties} from "./PreivewArea";
import {useDataBuilder, useDimensionOptions} from "../../utils/hooks";
import {METRIC_OPTIONS} from "../../constants/options";

// @ts-ignore
import className from "../../assets/scss/statistics.scss"

const RESOLUTION_OPTIONS = [
  { value: DimensionTransform.Weeks, label: 'Uker' },
  { value: DimensionTransform.Months, label: 'Måneder' },
  { value: DimensionTransform.Year, label: 'År' }
]

const X_AXIS_OPTIONS = [
  { value: Unit.Date, label: 'Dato' },
  { value: Unit.Weeks, label: 'Uke' },
  { value: Unit.Months, label: 'Måned' },
  { value: Unit.Year, label: 'År' }
];

const LAYOUT = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

function trendValid(trend: Partial<Trend>) {
  return (!!trend.yAxisId || trend.metric === Metric.Count) && !!trend.resolution;
}

const INITIAL = {
  xAxis: Unit.Date,
  resolution: DimensionTransform.Year,
  chartType: Chart.Line,
  metric: Metric.Count
};

export function TrendEditor(props: ComponentEditorProperties<Trend>) {
  const {
    data,
    onDataChange,
    renderer: Renderer,
    isEditingExistingComponent
  } = props;
  const [form] = Form.useForm();
  const [trend, updateTrend, valid] = useDataBuilder<Trend>(
    ComponentType.Trend,data ?? INITIAL, onDataChange, trendValid);

  const yAxisOptions = useDimensionOptions({
    type: DimensionType.Numerical
  });
  const groupByOptions = useDimensionOptions({
    type: [DimensionType.Categorical, DimensionType.GeoCategorical]
  });
  const PreviewArea = injectReactComponent<PreviewAreaProperties>(Injectable.PreviewArea)

  return (
    <div className={className.editorContainer}>
      <div className={className.formContainer}>
        <Form
          className={className.form}
          form={form}
          name="trend-editor"
          {...LAYOUT}>
          <Form.Item
            label="X-akse"
            rules={[{ required: true }]}>
            <Select
              defaultValue={trend.xAxis}
              value={trend.xAxis}
              options={X_AXIS_OPTIONS}
              onChange={value => updateTrend(draft => ({
                ...draft,
                xAxis: value
              }))} />
          </Form.Item>
          {
            trend.xAxis === Unit.Date && (
              <Form.Item
                label="X-akse enhet"
                rules={[{ required: true }]}>
                <Select
                  defaultValue={trend.resolution}
                  value={trend.resolution}
                  options={RESOLUTION_OPTIONS}
                  onChange={value => updateTrend(draft => ({
                    ...draft,
                    resolution: value
                  }))} />
              </Form.Item>
            )
          }
          <Form.Item
            label="Aggregering">
            <Select
              defaultValue={trend.metric}
              value={trend.metric}
              options={METRIC_OPTIONS}
              onChange={(value) => {
                updateTrend(draft => ({
                  ...draft,
                  metric: value as Metric,
                  yAxisId: value !== Metric.Count ? trend.yAxisId : undefined
                }));
                /* form.setFieldsValue({
                  dimension: value !== Metric.Count ? trend.yAxis?.label : undefined,
                }); */
              }} />
          </Form.Item>
          {
            trend.metric !== Metric.Count && (
              <Form.Item
                label="Y-akse"
                rules={[{ required: true }]}>
                <Select
                  value={trend.yAxisId}
                  options={yAxisOptions}
                  onChange={yAxisId => updateTrend(draft => ({
                    ...draft,
                    yAxisId
                  }))} />
              </Form.Item>
            )
          }
          <Form.Item
            label="Grupper">
            <Select
              allowClear
              value={trend.groupById}
              options={groupByOptions}
              onChange={groupById => updateTrend(draft => ({
                ...draft,
                groupById
              }))}
            />
          </Form.Item>
          <Form.Item
            label="Diagram type">
            <Radio.Group
              buttonStyle="solid"
              defaultValue={trend.chartType}
              value={trend.chartType}
              size="middle"
              onChange={e => {
                const { value: chartType } = e.target;
                updateTrend(draft => ({ ...draft, chartType }));
              }} >
              <Radio.Button value={Chart.Line}>Linje</Radio.Button>
              <Radio.Button value={Chart.Bar}>Søyle</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Form>
      </div>
      <PreviewArea width={500} height={250}>
        {
          ({ width, height }) => (
            <Renderer
              isEditingExistingComponent={isEditingExistingComponent}
              editing={true}
              valid={valid}
              data={trend}
              width={width}
              height={height} />
          )
        }
      </PreviewArea>
    </div>
  )
}
