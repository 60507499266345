export const ACCIDENT_CATEGORY = [
    "Personulykke",
    "Grunnstøting",
    "Kontaktskade",
    "Miljøskade/Forurensing",
    "Annet/Ukjent",
    "Kollisjon",
    "Lekkasje",
    "Brann/eksplosjon",
    "Hardtværskade",
    "Savnet",
    "Kantring",
    "Maskinhavari",
    "Fremdriftshavari"
];

export const VESSEL_TYPE = [
    "Ikke Fritidsfartøy",
    "Ukjent fartøy",
    "Uspesifisert Fritidsfartøy",
    "Robåt",
    "Motorbåt",
    "Kajakk/Kano",
    "Seilbåt",
    "Gummibåt",
    "Vannscooter",
    "Annet Fritidsfartøy",
    "Seilbrett/Kite"
];
