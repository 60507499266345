import React, {Key} from "react";
import GlobalState from "../../store/interfaces/states/GlobalState";
import {connect, ConnectedProps} from "react-redux";
import {Table, Tag} from "antd";
import {DataPoint} from "../../interfaces/models/DataPoint";
import {useInjection} from "inversify-react";
import Injectable from "../../injection/injectable";
import {CoordinatesMapComponent, FunctionalComponent} from "../../constants/globalTypes";
import {useDataSourceContext} from "../datasource/DataSourceProvider";
import Config, {DimensionDefinition} from "../../interfaces/Config";
import _ from "lodash";
import {injectReactComponent} from "../../utils/injectionUtilities";
import {RedactedViewProperties, RedactedViewType} from "../dataviews/RedactedView";
import DataSource from "../../interfaces/DataSource";
import {DimensionId} from "../../constants/enums";
import ColorScale from "../../constants/charts";
import {container} from "../../injection";

import className from "../../assets/scss/components/infomodal.scss"

export interface SpecializedRendererProperties<T = any> {
    dataSource: DataSource;
    value: T,
    RedactedView: FunctionalComponent<RedactedViewProperties>;
    type: ((dimension: DimensionDefinition<DataPoint>) => RedactedViewType)|RedactedViewType;
    dimension: DimensionDefinition<DataPoint>;
}

function replaceMissingValuesWithUnknown(value: any) {
    return _.isArray(value)
        ? value.map(v => !v || (_.isString(v) && !v.length) ? "Ukjent" : v)
        : !value || (_.isString(value) && !value.length)
            ? "Ukjent"
            : value
}

function dateRenderer(
  props: SpecializedRendererProperties<number>
) {
    const {
        value,
    } = props;
    return (
      <span>{ new Date(value).toLocaleDateString() }</span>
    );
}

function numberRenderer(
  props: SpecializedRendererProperties<number>
) {
    const {
        value,
    } = props;
    return (
      <span>{ value }</span>
    );
}

function personRenderer(
    props: SpecializedRendererProperties<number>
) {
    const {
        RedactedView,
        type,
        dimension
    } = props;
    const checkedValue = replaceMissingValuesWithUnknown(props.value)
    const value = _.isArray(checkedValue)
        ? checkedValue.map((v, i) => `${v} (Person ${i+1})`).join(', ')
        : checkedValue;
    return <RedactedView
        type={_.isFunction(type) ? type(dimension) : type}
        value={replaceMissingValuesWithUnknown(value)} />
}

function tagListColoredByScale(
  colorScaleName: string
) {
    return (props: SpecializedRendererProperties<string[]>) => {
        const { dataSource, value } = props;
        const colorScale = dataSource.colorScale(colorScaleName);
        return value.map(vesselType => <Tag color={colorScale(vesselType)}>{ _.capitalize(vesselType) }</Tag>);
    }
}

export type SpecializedRenderer = (props: SpecializedRendererProperties) => React.ReactElement|React.ReactElement[];

export const SPECIALIZED_RENDERERS: Record<string, SpecializedRenderer> = {
    [DimensionId.Coordinates]: (props: SpecializedRendererProperties<[number, number]>) => {
        const {
            value: coordinates,
        } = props;
        const CoordinatesMap = container.get<CoordinatesMapComponent>(Injectable.CoordinatesMap)
        return <CoordinatesMap coordinates={[coordinates]} style={{ width: '100%', height: 275 }} />;
    },
    [DimensionId.Cause]: tagListColoredByScale(ColorScale.AccidentType),
    [DimensionId.ShipType]: tagListColoredByScale(ColorScale.ShipType),
    [DimensionId.Date]: dateRenderer,
    [DimensionId.Updated]: dateRenderer,
    [DimensionId.CreatedAt]: dateRenderer,
    [DimensionId.Dead]: numberRenderer,
    [DimensionId.Missing]: numberRenderer,
    [DimensionId.Injured]: numberRenderer,
    [DimensionId.PersonCategory]: personRenderer,
    [DimensionId.Gender]: personRenderer,
    [DimensionId.CauseOfDeath]: personRenderer,
    [DimensionId.Age]: personRenderer,
    [DimensionId.Nationality]: personRenderer,
    [DimensionId.ActivityDescription]: personRenderer,
    [DimensionId.FloatingDeviceUsed]: personRenderer,
}

export function rowsFromData(
  data: DataPoint|null,
  activeDimensions: DimensionDefinition<DataPoint>[] = [],
  specializedRenderers: Record<string, SpecializedRenderer> = {},
  type: ((dimension: DimensionDefinition<DataPoint>) => RedactedViewType)|RedactedViewType = RedactedViewType.Inline
) {

    const {dataSource} = useDataSourceContext();
    const RedactedView = injectReactComponent<RedactedViewProperties>(Injectable.RedactedView)

    if (data) {
        return activeDimensions.map((dimension, index) => {
            const {
                id,
                label,
                selector
            } = dimension;
            if (_.isFunction(specializedRenderers[id])) {
                const specializedRenderer = specializedRenderers[id];
                const value = selector(data);
                return {
                    key: index,
                    property: label,
                    id: id,
                    value: specializedRenderer({
                        dataSource,
                        value,
                        RedactedView,
                        type,
                        dimension
                    })
                };
            }
            const value = selector(data);
            return {
                key: index,
                property: label,
                id: id,
                value: <RedactedView
                  type={_.isFunction(type) ? type(dimension) : type}
                  value={replaceMissingValuesWithUnknown(value)} />
            }
        });
    }
    return [];
}

export interface AccidentDataTableProperties {
    activeDimensionIds?: Key[];
}

function AntDAccidentDataTable(
    props: PropsFromRedux & AccidentDataTableProperties
) {
    const {
        moreInfoModalData,
        activeDimensionIds = []
    } = props;
    const columns = [
        {
            title: 'Nøkkel',
            dataIndex: 'property',
            key: 'property',
        },
        {
            title: 'Verdi',
            dataIndex: 'value',
            key: 'value',
        },
    ];
    const config = useInjection<Config>(Injectable.Config);
    const dimensions = config.dimensions
      .filter(({ id }) => activeDimensionIds.includes(id))
      .sort((a, b) => activeDimensionIds.indexOf(a.id) - activeDimensionIds.indexOf(b.id));
    const rows = rowsFromData(moreInfoModalData, dimensions, SPECIALIZED_RENDERERS);
    return (
        <div className={className.tableContainer}>
            <div className={className.content} >
                <Table
                  className={className.table}
                  showHeader={false}
                  columns={columns}
                  dataSource={rows}
                  pagination={false} />
            </div>
        </div>
    );
}

function mapStateToProps(state: GlobalState) {
    const { moreInfoModalData } = state.data;
    return {
        moreInfoModalData,
    };
}

function mapDispatchToProps() {
    return {};
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(AntDAccidentDataTable);
