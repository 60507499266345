import {Action} from "redux";
import EditStatisticState from "../interfaces/states/StatisticState";
import {updateState} from "../../utils/miscUtilities";
import {
  AddNewComponentAction,
  SaveComponentAction,
  ShowEditComponentAction,
  UpdateLayoutAction
} from "../interfaces/actions/statistics";
import _ from "lodash";
import {v4 as uuid} from "uuid";
import {StatisticAction} from "../actions/statistic";
import {Layout} from "react-grid-layout";
import {ComponentType} from "../../constants/enums";


function getDefaultLayout(
  componentType: ComponentType,
  i: string
): Layout {
  switch (componentType) {
    case ComponentType.Statistic:
      return { i, x: 0, y: 0, w: 2, h: 3, resizeHandles: ['e'] };
    default:
      return { i, x: 0, y: 0, w: 5, h: 8 };
  }
}

const initialState: EditStatisticState = {
  showComponentModal: false,
  components: {},
  statisticCollections: {},
  statisticTabs: {}
}
// Use the initialState as a default value
export default function statistic(
  state = initialState,
  action: Action
) {
  switch (action.type) {
    case StatisticAction.ShowEditComponentModal: {
      const { id: editComponentId } = action as ShowEditComponentAction;
      if (_.isString(editComponentId) && state.components[editComponentId]) {
        const editComponent = state.components[editComponentId];
        return updateState(state, {
          editComponent,
          editComponentId,
          showComponentModal: true
        });
      }
      console.warn('Tried to edit non existing component');
      return state;
    }
    case StatisticAction.HideComponentModal: {
      return { ...state, showComponentModal: false };
    }
    case StatisticAction.ClearEditComponent: {
      const nextState = { ...state };
      delete nextState.editComponent;
      delete nextState.editComponentId;
      return nextState;
    }
    case StatisticAction.SaveComponent: {
      let {
        id,
        component,
      } = action as SaveComponentAction;
      if (component) {
        id = id ? id : uuid();
        const nextState = { ...state };
        nextState.components = {
          ...nextState.components,
          [id]: component
        }
        return nextState;
      }
      return state;
    }
    case StatisticAction.AddNewComponent: {
      const { componentType: type } = action as AddNewComponentAction;
      return state;
    }
    case StatisticAction.UpdateLayout: {
      const {
        layout
      } = action as UpdateLayoutAction;
      const components = { ...state.components };
      return updateState(state, {
        components
      });
    }
    default:
      return state
  }
}
