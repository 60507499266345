import React from "react";
import {InputNumber} from "antd";
import FilterInputProperties from "../interfaces/FilterInputProperties";

// @ts-ignore
import className from "../../../assets/scss/filterView.scss";

export function NumericalInput(props: FilterInputProperties) {
    const {
        filter,
        onComparatorValueChange,
    } = props;
    return (
        <InputNumber
            className={className.numberInput}
            value={filter.comparatorValue as any}
            onChange={value => onComparatorValueChange(filter, value)}
            addonAfter={filter.unit} />
    );
}
