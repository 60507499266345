import React, {useState} from "react";
import GlobalState from "../../store/interfaces/states/GlobalState";
import { bindActionCreators, Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import {Button, DatePicker, Form, Input, InputNumber, List, Modal, Select, Tag} from "antd";
import {hideAddNewAccidentModal, hideMoreInfoAboutDataPoint} from "../../store/actions/data";
import {useInjection} from "inversify-react";
import Injectable from "../../injection/injectable";
import {CoordinatesMapComponent, DataCardComponent, FunctionalComponent} from "../../constants/globalTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import Messenger from "../../interfaces/Messenger";
import {Message} from "../../constants/enums";
import {ACCIDENT_CATEGORY, VESSEL_TYPE} from "../../constants/lists";
import TextArea from "antd/es/input/TextArea";
import {useDataSourceContext} from "../datasource/DataSourceProvider";
import Chart from "../../constants/charts";
import {DataPoint} from "../../interfaces/models/DataPoint";
import _ from "lodash";
import {haversine} from "../../utils/olUtilities";

import className from "../../assets/scss/components/newaccidentmodal.scss";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

function AntDAddNewAccidentModal(
    props: PropsFromRedux
) {
    const {
        addNewAccidentModalVisible,
        moreInfoModalVisible,
        hideAddNewAccidentModal,
        hideMoreInfoAboutDataPoint,
    } = props;
    const CoordinatesMap = useInjection<CoordinatesMapComponent>(Injectable.CoordinatesMap);
    const DataCard = useInjection<DataCardComponent>(Injectable.DataCard);
    const messenger = useInjection<Messenger>(Injectable.Messenger);
    const AccidentDataTable = useInjection<FunctionalComponent>(Injectable.AccidentDataTable);

    const { dataSource } = useDataSourceContext();
    const causeColorScale = dataSource.colorScale(Chart.AccidentType);
    const shipTypeColorScale = dataSource.colorScale(Chart.ShipType);

    const [form] = Form.useForm();
    const [coordinates, setCoordinates] = useState<[number, number][]>([]);
    const [matchingDataPoints, setMatchingDataPoints] = useState<DataPoint[]>([]);
    const [confirmLoading, setConfirmLoading] = React.useState(false);

    const onChange = () => {
        const {
            date: inputDate,
            longitude,
            latitude
        } = form.getFieldsValue();
        if (inputDate && _.isNumber(longitude) && _.isNumber(latitude)) {
            dataSource.take().then(data => {
                setCoordinates([[longitude, latitude]]);
                const nextMatchingDataPoints = data
                    .filter(data => {
                        const {
                            date,
                            location: {
                                coordinates
                            } } = data;
                        const [compareLongitude, compareLatitude] = coordinates;
                        return Math.abs(inputDate.diff(new Date(date), 'days')) < 1
                            && haversine(latitude, longitude, compareLatitude, compareLongitude, "K") <= 0.3;
                    });
                setMatchingDataPoints(nextMatchingDataPoints);
            });
        }
    }

    const onOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
            onClose();
            setConfirmLoading(false);
            messenger.success(Message.AddedDataPoint);
        }, 2000);
    };

    const onClose = () => {
        form.resetFields();
        setCoordinates([]);
        setMatchingDataPoints([]);
        hideAddNewAccidentModal();
    }

    const title = moreInfoModalVisible ? (
        <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
            <div onClick={hideMoreInfoAboutDataPoint}>
                <FontAwesomeIcon
                  icon={faArrowLeft as IconProp}
                  style={{ marginRight: 10 }} />
            </div>
            <span style={{ marginTop: -2 }}>Mer info</span>
        </div>
    ) : <span>Legg til ulykke</span>

    const footer = moreInfoModalVisible ? (
        [
            <Button key="close" type="primary" onClick={hideMoreInfoAboutDataPoint}>
                Tilbake
            </Button>,
        ]
    ) : undefined;

    return (
        <Modal
            title={title}
            confirmLoading={confirmLoading}
            open={addNewAccidentModalVisible}
            onOk={onOk}
            onCancel={onClose}
            footer={footer}
            width={910}>
            {
                addNewAccidentModalVisible
                    ? moreInfoModalVisible
                        ? <AccidentDataTable />
                        : (
                            <div className={className.content}>
                                <Form
                                    form={form}
                                    name="basic"
                                    size="middle"
                                    className={className.left}
                                    labelCol={{ span: 9 }}
                                    wrapperCol={{ span: 15 }}
                                    onChange={onChange}
                                    onFinish={() => console.log('finished')}
                                    onFinishFailed={() => console.log('finished failed')}
                                    autoComplete="off">
                                    <Form.Item
                                        label="Tid"
                                        name="date"
                                        rules={[{ required: true, message: 'Dato må fylles inn' }]}>
                                        <DatePicker
                                          onChange={onChange}
                                          style={{ width: 250 }}
                                          showTime />
                                    </Form.Item>
                                    <Form.Item
                                        name="latitude"
                                        label="Breddegrad"
                                        rules={[{ required: true, message: 'Breddegrad må fylles inn' }]}>
                                        <InputNumber style={{ width: 125 }} placeholder="Breddegrad" onChange={() => console.log('WOFF!')} />
                                    </Form.Item>
                                    <Form.Item
                                        name="longitude"
                                        label="Lendgegrad"
                                        rules={[{ required: true, message: 'Lendgegrad må fylles inn' }]}>
                                        <InputNumber style={{ width: 125 }} placeholder="Lengdegrad" onChange={() => console.log('WOFF!')} />
                                    </Form.Item>
                                    <Form.Item
                                        name="cause"
                                        label="Årsaksgruppe">
                                        <Select
                                            mode="multiple"
                                            style={{ width: 250 }}
                                            tagRender={(props: any) => {
                                                const { value, label, onClose } = props;
                                                return (
                                                    <Tag
                                                        color={causeColorScale(value)}
                                                        onClose={onClose}
                                                        closable={true}>
                                                        {label}
                                                    </Tag>
                                                );
                                            }}
                                            options={ACCIDENT_CATEGORY.map(value => ({ value, label: value }))} />
                                    </Form.Item>
                                    <Form.Item
                                        name="causeDescription"
                                        label="Årsaksbeskrivelse">
                                        <Input style={{ width: 250 }} />
                                    </Form.Item>
                                    <Form.Item label="Skipstypegruppe">
                                        <Select
                                            mode="multiple"
                                            style={{ width: 250 }}
                                            tagRender={(props: any) => {
                                                const { value, label, onClose } = props;
                                                return (
                                                    <div onMouseDown={e => e.stopPropagation()}>
                                                        <Tag
                                                            color={shipTypeColorScale(value)}
                                                            onClose={onClose}
                                                            closable={true}>
                                                            {label}
                                                        </Tag>
                                                    </div>
                                                );
                                            }}
                                            options={VESSEL_TYPE.map(value => ({ value, label: value }))} />
                                    </Form.Item>
                                    <Form.Item label="Skipstypebeskrivelse">
                                        <Input style={{ width: 250 }} />
                                    </Form.Item>
                                    <Form.Item label="Beskrivelse">
                                        <TextArea style={{ width: 250 }} />
                                    </Form.Item>
                                    <Form.Item label="Skadde">
                                        <InputNumber min={0} />
                                    </Form.Item>
                                    <Form.Item label="Døde">
                                        <InputNumber min={0} />
                                    </Form.Item>
                                    <Form.Item label="Savnede">
                                        <InputNumber min={0} />
                                    </Form.Item>
                                </Form>
                                <div className={className.right}>
                                    <CoordinatesMap
                                        style={{ height: 275, width: 430 }}
                                        coordinates={coordinates} />
                                    <Form.Item
                                        style={{ margin: 0, marginTop: 20}}
                                        label="Mulige duplikater">
                                    </Form.Item>
                                    <div style={{
                                        border: "1px solid #d9d9d9",
                                        display: 'flex',
                                        flex: 1,
                                        flexDirection: 'column'
                                    }}>
                                        <div>
                                            <List
                                                grid={{ gutter: 16, column: 1 }}
                                                dataSource={matchingDataPoints}
                                                renderItem={item => (
                                                    <List.Item style={{ margin: 10 }}>
                                                        <DataCard
                                                            showInMapAction={false}
                                                            zoomInMapAction={false}
                                                            data={item} />
                                                    </List.Item>
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    : <></>
            }
        </Modal>
    )
}

function mapStateToProps(state: GlobalState) {
    const { addNewAccidentModalVisible, moreInfoModalVisible } = state.data;
    return { addNewAccidentModalVisible, moreInfoModalVisible };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators({
        hideAddNewAccidentModal,
        hideMoreInfoAboutDataPoint
    }, dispatch)
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(AntDAddNewAccidentModal);
