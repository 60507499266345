import GlobalState from "../interfaces/states/GlobalState";
import {AccidentLayerConfig, UserData} from "../interfaces/states/UserState";
import _ from "lodash";

export function getNightMode(
  state: GlobalState
) {
  return state.user.data.nightMode || false;
}

export function getAccidentLayerConfig(
  state: GlobalState
): AccidentLayerConfig {
  return state.user.data.accidentLayerConfig;
}

export function getFullName(
  state: GlobalState
): string {
  const {
    firstName = "",
    lastName = ""
  } = state.user.info || {};
  return `${_.capitalize(firstName)} ${_.capitalize(lastName)}`;
}

export function getUserData(
    state: GlobalState
): UserData {
  return state.user.data
}