import {GeoJSONLayerConfig} from "../../../interfaces/Config";
import {StyleLike} from "ol/style/Style";
import VectorSource from "ol/source/Vector";
import {GeoJSON} from "ol/format";
import VectorLayer from "ol/layer/Vector";
import _ from "lodash";
import {DEFAULT_GEO_JSON_COLOR} from "../../../constants/colors";
import {Fill, Stroke, Style} from "ol/style";
import {hexToRGB} from "../../../utils/miscUtilities";
import {useEffect, useMemo, useRef} from "react";
import {Geometry} from "ol/geom";
import BaseLayer from "ol/layer/Base";
import { v4 as uuid } from "uuid";

function getColorFromGeoJSONConfig(
  geoJSONLayerConfig: GeoJSONLayerConfig,
  nightMode: boolean
) {
  const { styleColor } = geoJSONLayerConfig;
  if (_.isFunction(styleColor)) {
    return styleColor(nightMode);
  } else if (_.isString(styleColor)) {
    return styleColor;
  }
  return DEFAULT_GEO_JSON_COLOR;
}

function getStyleFromGeoJSONLayerConfig(
  geoJSONLayerConfig: GeoJSONLayerConfig,
  nightMode: boolean
): StyleLike {
  const { style } = geoJSONLayerConfig;
  if (_.isFunction(style)) {
    return feature => style(feature, nightMode);
  } else if (style) {
    return style;
  } else {
    const color = getColorFromGeoJSONConfig(geoJSONLayerConfig, nightMode);
    return new Style({
      fill: new Fill({
        color: hexToRGB(color, 0.5)
      }),
      stroke: new Stroke({
        color,
        width: 2
      })
    });
  }
}

function createGeoJSONLayers(
  geoJSONLayers: GeoJSONLayerConfig[],
  nightMode: boolean
) {
  return geoJSONLayers.map((geoJSONLayer) => {
    const style: StyleLike = getStyleFromGeoJSONLayerConfig(geoJSONLayer, nightMode);
    const source = new VectorSource({
      url: geoJSONLayer.url,
      format: new GeoJSON()
    });
    return new VectorLayer({
      source,
      properties: {
        key: uuid(),
        label: geoJSONLayer.label,
        includeInLayerSwitcher: true,
        geoJSONLayer: geoJSONLayer
      },
      visible: false,
      style: style,
      zIndex: 1
    });
  });
}

function updateStyle(
  layers: VectorLayer<VectorSource<Geometry>>[] = [],
  nightMode: boolean = false
) {
  layers?.forEach(layer => {
    const geoJSONLayer = layer.getProperties().geoJSONLayer;
    const style: StyleLike = getStyleFromGeoJSONLayerConfig(geoJSONLayer, nightMode);
    layer.setStyle(style);
  })
}

export default function useGeoJSONLayers(
  geoJsonLayerConfigs: GeoJSONLayerConfig[],
  nightMode: boolean
) {
  const layers = useRef<VectorLayer<VectorSource<Geometry>>[]|undefined>(undefined);
  useEffect(() => updateStyle(layers.current, nightMode), [nightMode]);
  return useMemo(() => {
      layers.current = createGeoJSONLayers(geoJsonLayerConfigs, nightMode);
      return layers.current as BaseLayer[];
  }, [geoJsonLayerConfigs]);
}
