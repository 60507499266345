import React, {Key, useMemo} from "react";
import {Select, Tag, Typography} from "antd";
import {ComparatorValue, Filter} from "../types";
import {includeOperatorSelect, operatorsForType} from "../utilities/helpers";
import {NumericalInput} from "./NumericalInput";
import {CategoricalInput} from "./CategoricalInput";
import {DateInput} from "./DateInput";
import {FilterType, Operator} from "../enums";
import {optionalClassNames} from "../../../utils/miscUtilities";

// @ts-ignore
import className from "../../../assets/scss/filterView.scss";

export interface FilterRendererProperties {
    filters: Filter[];
    onChange: (filter: Filter) => void;
    onDelete: (filter: Filter) => void;
    label?: Key;
    canDelete?: boolean;
}


export default function FilterRenderer(
    props: FilterRendererProperties
) {
    const {
        label,
        filters,
        onChange,
        onDelete,
        canDelete = true
    } = props;

    const { type } = filters[0] || {};
    const operators = useMemo(() => operatorsForType(type), [type]);
    const updateOperator = (filter: Filter, operator: Operator) => onChange({ ...filter, operator });
    const updateComparatorValue = (filter: Filter, comparatorValue: ComparatorValue|ComparatorValue[]) =>
        onChange({ ...filter, comparatorValue });

    const tagElements = filters.map((filter: Filter, index: number) => (
        <Tag
            key={index}
            onClose={() => onDelete(filter)}
            className={className.filterContainer}
            closable={canDelete}>
            <div className={className.filter}>
                {
                    includeOperatorSelect(filter) ? (
                        <div>
                            <Select
                                value={filter.operator}
                                optionFilterProp="children"
                                className={className.operator}
                                onChange={value => updateOperator(filter, value)}>
                                {
                                    operators.map(([value, label], index) =>
                                        <Select.Option key={index} value={value}>{label}</Select.Option>)
                                }
                            </Select>
                        </div>
                    ) : <></>
                }
                {
                    filter.type === FilterType.Date && <DateInput
                        filter={filter}
                        onComparatorValueChange={updateComparatorValue} />
                }
                {
                    filter.type === FilterType.Categorical && <CategoricalInput
                        filter={filter}
                        onComparatorValueChange={updateComparatorValue} />
                }
                {
                    filter.type === FilterType.Numerical && <NumericalInput
                        filter={filter}
                        onComparatorValueChange={updateComparatorValue} />
                }
            </div>
        </Tag>
    ));
    const containerClassName = optionalClassNames({
        [className.moreThanOneFilter]: filters.length > 1
    });
    return (
        <div
            className={containerClassName}>
            <Typography.Title
                level={5}
                className={className.filterLabel}>
                {label}
            </Typography.Title>
            <div className={className.tags}>
                {
                    tagElements
                }
            </div>
        </div>
    );
}