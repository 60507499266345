import React, {RefObject, UIEvent, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import GlobalState from "../../store/interfaces/states/GlobalState";
import {connect, ConnectedProps, useSelector} from "react-redux";
import {useMasonry, usePositioner, useResizeObserver} from "masonic";
import {useResizeObserver as useElementResizeObserver } from "../../utils/hooks";
import {DataPoint} from "../../interfaces/models/DataPoint";
import {RenderComponentProps} from "masonic/src/use-masonry";
import {useDataSourceContext} from "../datasource/DataSourceProvider";
import _ from "lodash";
import {injectReactComponent} from "../../utils/injectionUtilities";
import {EditableDataCardProperties} from "../dataviews/AntdDEditableDataCard";
import Injectable from "../../injection/injectable";
import {useInjection} from "inversify-react";
import Config from "../../interfaces/Config";
import {Pagination, theme} from "antd";
import {FunctionalComponent} from "../../constants/globalTypes";
import {TabBarContext, TabBarContextState} from "./AntDTabBar";
import Tab from "../../constants/tabs";
import TabComponentProperties from "../../interfaces/properties/TabComponentProperties";

import tabPaneClassName from "../../assets/scss/components/tabpane.scss";
import className from "../../assets/scss/components/dataexplorer.scss";

function usePadding(
    ref: RefObject<HTMLElement>
) {
    const computedStyle = useMemo(() => {
        if (ref.current) {
            return getComputedStyle(ref.current);
        }
        return null;
    }, [ref.current])
    const horizontalPadding = useMemo(() => {
        return computedStyle
            ? parseFloat(computedStyle['paddingLeft']) + parseFloat(computedStyle['paddingRight'])
            : 0;
    }, [computedStyle]);
    return {
        horizontalPadding
    }
}

function useMasonicState() {
    const { data } = useDataSourceContext();

    const ref = useRef<HTMLDivElement>(null);
    const {
        width: observedWidth,
        height
    } = useElementResizeObserver(ref, 1);
    const width = useSelector((state: GlobalState) => state.user.data.dataPaneSize || observedWidth);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(500);
    const totalSize = useMemo(() => data.length, [data.length]);

    const items = useMemo(() => {
        const startIndex = pageSize * (page - 1);
        ref.current?.scrollTo({ left: 0, top: 0 });
        return data.slice(startIndex, startIndex + pageSize);
    }, [data, pageSize, page]);

    const columnCount = useMemo(() => Math.ceil(width / 400) || 1, [width]);

    const {
        horizontalPadding
    } = usePadding(ref);

    const positioner = usePositioner({
        width: width - horizontalPadding,
        columnGutter: horizontalPadding / 2,
        columnCount: columnCount
    }, [items]);



    useEffect(() => {
        const newMaxPage = Math.floor(data.length / pageSize) + 1;
        if (newMaxPage < page) {
            setPage(newMaxPage);
        }
    }, [data.length]);

    const resizeObserver = useResizeObserver(positioner);

    const updatePagination = useCallback((page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    }, [setPage, setPageSize]);

    return {
        ref,
        height,
        positioner,
        resizeObserver,
        page,
        pageSize,
        totalSize,
        items,
        updatePagination
    }

}

function useScroller() {
    const [scrollTop, setScrollTop] = useState(0);
    const [isScrolling, setIsScrolling] = useState(false);
    const endScroll = useCallback(_.debounce(() => setIsScrolling(false), 5), [setIsScrolling])
    const onScroll = useCallback((e: UIEvent<HTMLElement>) => {
        const { scrollTop } = e.target as HTMLElement;
        setIsScrolling(true);
        setScrollTop(scrollTop);
        endScroll();
    }, [setIsScrolling, setScrollTop, endScroll]);
    return {
        scrollTop,
        isScrolling,
        onScroll
    }
}

function MasonicDataExplorerTab(
    props: TabComponentProperties & PropsFromRedux
) {
    const { activeDimensions } = props;
    const { token } = theme.useToken();
    const config = useInjection<Config>(Injectable.Config);
    const { activeTab } = useContext<TabBarContextState>(TabBarContext);
    const { dataSource } = useDataSourceContext();
    const allDataCount = dataSource.crossfilter.all().length;
    const containerRef = React.useRef(null);

    const dimensions = useMemo(() => config.dimensions
            .filter(({ id }) => activeDimensions.includes(id))
            .sort((a, b) => activeDimensions.indexOf(a.id) - activeDimensions.indexOf(b.id)),
        [activeDimensions]);

    const {
        scrollTop,
        isScrolling,
        onScroll
    } = useScroller();

    const {
        ref,
        height,
        items,
        page,
        pageSize,
        updatePagination,
        totalSize,
        resizeObserver,
        positioner
    } = useMasonicState();

    const TextFilter = useInjection<FunctionalComponent>(Injectable.TextFilter);
    const DataCard = injectReactComponent<EditableDataCardProperties>(Injectable.DataCard);
    const DataCardRenderer = React.useCallback(
        (props: RenderComponentProps<DataPoint>) => (
            <DataCard
                key={props.index}
                data={props.data}
                dimensions={dimensions} />
        ),
        [dimensions]);

    const { colorBorderSecondary } = token;

    return (
        <div
            className={className.container}
            style={{
                background: token.colorBgContainer,
            }}>
            <div
                ref={ref}
                style={{
                    overflowY: 'auto',
                    padding: 12,
                    paddingTop: 65,
                    display: 'flex',
                    flex: 1,
                }}
                onScroll={onScroll}
            >
                {useMasonry<DataPoint>({
                    positioner,
                    scrollTop,
                    isScrolling,
                    height: height,
                    containerRef,
                    items,
                    overscanBy: 5,
                    render: DataCardRenderer,
                    resizeObserver
                })}
            </div>
            <div style={{
                borderTopColor: colorBorderSecondary,
                borderTopWidth: 1,
                borderTopStyle: 'solid',
            }}>
                <Pagination
                    style={{ margin: 12 }}
                    total={totalSize}
                    pageSizeOptions={[ 50, 100, 250, 500 ]}
                    showTotal={(total) => `Viser ${total} av ${allDataCount}`}
                    onChange={updatePagination}
                    current={page}
                    pageSize={pageSize}
                    defaultCurrent={1} />
            </div>
            <div
                className={`${tabPaneClassName.topToolBar} ${activeTab !== Tab.Data && tabPaneClassName.notVisible}`}
                style={{
                    background: token.colorBgContainer
                }}>
                <TextFilter/>
            </div>
        </div>
    )
}

function mapStateToProps(state: GlobalState) {
    const {
        activeDimensionIdsInDataCard: activeDimensions = []
    } = state.user.data;
    return { activeDimensions };
}

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(MasonicDataExplorerTab);
