import Network from "../models/Network";
import {Background, BackgroundVariant, Controls, ReactFlow, ReactFlowInstance, XYPosition} from "reactflow";
import React, {Key, useContext, useEffect, useMemo, useRef, useState} from "react";
import {useFilters, useNetworkAsFlow} from "../utilities/hooks";
import GroupNode from "./GroupNode";
import {NodeType} from "../enums";
import ResultNode from "./ResultNode";
import {Card, Select, theme} from "antd";
import StatisticDefinition from "../interfaces/StatisticDefinition";
import _ from "lodash";
import StatisticsVisualizationPlugin from "../interfaces/StatisticsVisualizationPlugin";
import {joinClassNames, lookupFromArray} from "../../../utils/miscUtilities";
import {Chart, registerables} from "chart.js";
import SplitPane from "react-split-pane";
import {useResizeObserver} from "../../../utils/hooks";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import {FilterBar} from "../../filterRenderer";
import {Renderer} from "./Renderer";

import className from "../../../assets/scss/components/riskmodel.scss";

Chart.register(...registerables);


export interface RiskModelContextState {
    updateValueForNode: (nodeId: string, currentValue: number) => void;
    result: number|undefined;
    network: Network;
    setStatisticsCategory: (category?: Key) => void;
    statisticsCategory?: Key;
    comparisonValues?: Record<Key, number>;
}

export const RiskModelContext = React.createContext<RiskModelContextState>({} as RiskModelContextState);

export function useRiskModelContext(): RiskModelContextState {
    return useContext(RiskModelContext);
}

const nodeTypes = {
    [NodeType.Group]: GroupNode,
    [NodeType.Result]: ResultNode
};

interface RiskModelViewerProperties {
    network: Network;
    statisticsDefinition: StatisticDefinition[];
    visualizationPlugins: StatisticsVisualizationPlugin[];
    positionFromId: (id: string) => XYPosition;
}

export default function RiskModelViewer(
    props: RiskModelViewerProperties
) {
    const {
        network,
        statisticsDefinition,
        positionFromId,
        visualizationPlugins = []
    } = props;

    const {
        nodes,
        edges,
        updateValueForNode,
        result,
    } = useNetworkAsFlow(network, positionFromId);

    const {
        token
    } = theme.useToken();

    const ref = useRef<HTMLDivElement|null>(null);
    const instanceRef = useRef<ReactFlowInstance|null>(null);
    const [statisticsCategory, setStatisticsCategory] = useState<Key|undefined>();
    const [comparisonKey, setComparisonKey] = useState<Key|undefined>();
    const open = useMemo(() => !_.isUndefined(statisticsCategory), [statisticsCategory]);

    const { height } = useResizeObserver(ref);

    const visualizationPluginsLookup = useMemo(
        () => lookupFromArray<StatisticsVisualizationPlugin>(visualizationPlugins, p => p.type()),
        [visualizationPlugins]);

    const statisticDataForCategory = useMemo(
        () => statisticsDefinition.filter(d => d.category === statisticsCategory), [statisticsCategory, statisticsDefinition]);

    useEffect(() => {
        setTimeout(() => {
            instanceRef.current?.fitView();
        }, 1);
    }, [open]);

    const {
        clearAllFilters,
        filters,
        filterState,
        onChangeFilter,
        onDeleteFilter,
    } = useFilters(statisticDataForCategory);

    const hasComparisonValues = !_.isUndefined(network.comparisonValues);

    const comparisonOptions = useMemo(() => network.comparisonValues
        ? _.keys(network.comparisonValues).map(key => ({value: key, label: key}))
        : [], [])

    const comparisonValues = useMemo(() => {
        return network.comparisonValues && comparisonKey ? network.comparisonValues[comparisonKey] : undefined;
    }, [comparisonKey])

    return (
        <RiskModelContext.Provider value={{
            updateValueForNode,
            result,
            network,
            setStatisticsCategory,
            statisticsCategory,
            comparisonValues
        }}>
            <div ref={ref} className={className.riskModelViewer}>
                <SplitPane
                    resizerStyle={{
                        background: token.colorBorderSecondary
                    }}
                    onDragFinished={() => instanceRef.current?.fitView()}
                    maxSize={open ? _.round(height*0.8, 0) : 0}
                    minSize={open ? 200 : 0}
                    primary="second"
                    split="horizontal">
                    <ReactFlow
                        onInit={instance => instanceRef.current = instance}
                        style={{
                            position: 'relative'
                        }}
                        fitView={true}
                        nodeTypes={nodeTypes}
                        nodes={nodes}
                        edges={edges}
                        proOptions={{ hideAttribution: true }}>
                        <Controls />
                        <Background
                            variant={BackgroundVariant.Dots}
                            gap={12}
                            size={1} />
                    </ReactFlow>
                    <div className={className.statisticsPane}>
                        <div className={joinClassNames(className.closeIcon, 'nodrag')} >
                            <FontAwesomeIcon
                                icon={faClose}
                                onClick={() => setStatisticsCategory(undefined)} />
                        </div>
                        <div className={className.content}>
                            <FilterBar
                                canAdd={false}
                                canDelete={false}
                                className={className.filterBar}
                                defaultSize={300}
                                minSize={100}
                                filters={filters}
                                filterExpanded={true}
                                onChangeFilter={onChangeFilter}
                                onDeleteFilter={onDeleteFilter}
                                clearAllFilters={clearAllFilters}>
                                <div className={className.statisticGrid}>
                                    {
                                        statisticDataForCategory.map(statisticsData => {
                                            const plugin = visualizationPluginsLookup[statisticsData.plugin.type];
                                            return (
                                                <div className={className.statisticContainer}>
                                                    <Renderer
                                                        statisticsDefinition={statisticsData}
                                                        plugin={plugin}
                                                        filterState={filterState} />
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </FilterBar>
                        </div>
                    </div>
                </SplitPane>
                {
                    hasComparisonValues && (
                        <Card className={className.comparisonValues}>
                            <span className={className.label}>Sammenlign</span>
                            <Select
                                value={comparisonKey}
                                allowClear={true}
                                onChange={setComparisonKey}
                                className={className.select}
                                options={comparisonOptions}  />
                        </Card>
                    )
                }
            </div>
        </RiskModelContext.Provider>
    );
}