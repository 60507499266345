import Node from "./Node";
import {EdgeSerialized} from "../interfaces/EdgeSerialized";
import ResultNode from "./ResultNode";

export default class Edge {
    private readonly _sourceNode: Node;
    private readonly _targetNode: Node|ResultNode;
    private readonly _weight: number;

    constructor(
        sourceNode: Node,
        targetNode: Node|ResultNode,
        weight: number
    ) {
        this._sourceNode = sourceNode;
        this._targetNode = targetNode;
        this._weight = weight;
    }

    public serialize(): EdgeSerialized {
        return {
            source: this._sourceNode.id,
            target: this._targetNode.id,
            probability: this._weight
        };
    }

    public get targetId(): string {
        return this._targetNode.id;
    }

    public get targetNode(): Node|ResultNode {
        return this._targetNode;
    }

    public get weight(): number {
        return this._weight;
    }

    public get sourceId(): string {
        return this._sourceNode.id;
    }

    public get sourceNode(): Node|ResultNode {
        return this._targetNode;
    }

}