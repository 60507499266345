import React from "react";
import {Card, Button, theme} from "antd";
import {Typography} from "antd";
import {useInjection} from "inversify-react";
import Injectable from "../../injection/injectable";
import Client from "../../interfaces/Client";

// @ts-ignore
import className from "../../assets/scss/main.scss";

export default function Login() {
  const {
    token
  } = theme.useToken();
  const client = useInjection<Client>(Injectable.Client)
  return (
    <div
      style={{
        background: token.colorBgLayout
      }}
      className={className.loginContainer}>
      <Card className={className.loginCard}>
        <div className={className.loginContent}>
          <Typography.Title level={2}>
            Du er ikke logget inn
          </Typography.Title>
          <Typography.Title level={5}>
            Trykk under for å logge inn
          </Typography.Title>
          <Button
            type="primary"
            size="large"
            className={className.loginButton}
            onClick={() => client.redirectToLogin()}>
            Logg inn
          </Button>
        </div>
      </Card>
    </div>
  )
}
