import * as React from "react";
import {Button, Dropdown, Menu, Switch, theme, Typography, Alert} from "antd";
import {Link} from "react-router-dom";
import {bindActionCreators, Dispatch} from "redux";
import {connect, ConnectedProps, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faUser, faSun, faMoon} from "@fortawesome/free-solid-svg-icons";
import {useInjection} from "inversify-react";
import Injectable from "../../injection/injectable";
import {FunctionalComponent} from "../../constants/globalTypes";
import {showAddNewAccidentModal} from "../../store/actions/data";
import {RouterPath} from "../../constants/enums";
import GlobalState from "../../store/interfaces/states/GlobalState";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {analytics} from "../analytics";
import {toggleNightMode} from "../../store/actions/user";
import {logout} from "../../store/actions/auth";
import {getFullName} from "../../store/selectors/user";

import className from "../../assets/scss/components/toolbar.scss";

function createRouteLogger(
  route: RouterPath
) {
  return () => analytics.page({ route }).catch(console.error);
}

function AntDToolbar(props: PropsFromRedux) {
  const {
    activeKey,
    showAddNewAccidentModal,
    toggleNightMode,
    nightMode,
    logout
  } = props;
  const { token } = theme.useToken();
  const UserMenu = useInjection<FunctionalComponent>(Injectable.UserMenu);
  const userFullName = useSelector(getFullName);
  return (
    <div className={className.container} style={{
      borderBottomColor: token.colorBorderSecondary
    }}>
      <div className={className.menuContainer}>
        <Menu
          mode="horizontal"
          activeKey={activeKey}
          style={{ width: '100%' }}
          items={[
            {
              key: RouterPath.Home,
              label: (
                <Link to={RouterPath.Home} onClick={createRouteLogger(RouterPath.Home)}>
                  Utforsker
                </Link>
              )
            },
            /* {
              key: RouterPath.Statistics,
              label: (
                <Link to={RouterPath.Statistics} onClick={createRouteLogger(RouterPath.Statistics)}>
                  Statistikk
                </Link>
              )
            },
            {
              key: RouterPath.Prediction,
              label: (
                <Link to={RouterPath.Prediction} onClick={createRouteLogger(RouterPath.Prediction)}>
                  Prediksjon
                </Link>
              )
            },*/
            /*{
              key: RouterPath.DataExtractor,
              label: (
                <Link to={RouterPath.DataExtractor} onClick={createRouteLogger(RouterPath.DataExtractor)}>
                  Datauttrekker
                </Link>
              )
            },*/
            {
              key: RouterPath.RiskModel,
              label: (
                <Link to={RouterPath.RiskModel} onClick={createRouteLogger(RouterPath.RiskModel)}>
                  Risiko Modell
                </Link>
              )
            },
            {
              key: RouterPath.Models,
              label: (
                <Link to={RouterPath.Models} onClick={createRouteLogger(RouterPath.Models)}>
                  Modeller
                </Link>
              )
            },
          ]} />
      </div>
      <div
        className={className.buttonContainer}
        style={{
          background: token.colorBgContainer
        }}>
        <div className={className.userNameContainer}>
          <Typography.Title level={5}>
            {userFullName}
          </Typography.Title>
        </div>
        <Switch
          onChange={() => toggleNightMode()}
          checkedChildren={<FontAwesomeIcon icon={faMoon as IconProp} />}
          unCheckedChildren={<FontAwesomeIcon icon={faSun as IconProp} />}
          checked={nightMode}
        />
        {
          activeKey === RouterPath.Home && (
            <Button
              onClick={showAddNewAccidentModal}
              type="default"
              shape="circle">
              <FontAwesomeIcon icon={faPlus as IconProp} />
            </Button>
          )
        }
        <Dropdown
          overlay={<UserMenu />}
          placement="bottomRight">
          <Button
            onClick={() => logout()}
            type="default"
            shape="circle">
            <FontAwesomeIcon icon={faUser as IconProp} />
          </Button>
        </Dropdown>
      </div>
      <Alert
        message="Det er ikke lov til å bruke plattformen til kommersiell virksomhet."
        banner
        closable
      />
    </div>
  );
}

function mapStateToProps(state: GlobalState) {
  const { authenticated, waitingForAuthStatus: authenticating } = state.auth;
  const { nightMode } = state.user.data;
  const activeKey = (state.router.location as any).pathname;
  return { activeKey, authenticated, authenticating, nightMode };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({
    showAddNewAccidentModal,
    toggleNightMode,
    logout
  }, dispatch)
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(AntDToolbar);
