import Network from "./models/Network";
import {GroupID} from "./enums";
import {useNetworkAsFlow} from "./utilities/hooks";

export const POSSIBLE_VALUES = [
    { value: 1, label: "1", color: "#DE5539" },
    { value: 2, label: "2", color: "#EF9548" },
    { value: 3, label: "3", color: "#FCF467" },
    { value: 4, label: "4", color: "#D1E55F" },
    { value: 5, label: "5", color: "#4CA389" },
];

export function testNetwork() {

    const network = new Network({
        resultNodeLabel: 'Risiko for fritidsfartøy',
        possibleValues: POSSIBLE_VALUES
    });

    const certificateNode = network.addNode({
        label: 'Sertikatkrav',
        possibleValues: POSSIBLE_VALUES,
        defaultValue: 3,
        weight: 1.0,
        statisticsCategory: 'certificate'
    });

    const speedPortAndWaterLawNode = network.addNode({
        label: 'Fartsforskrifter og havne- og farvannsloven',
        possibleValues: POSSIBLE_VALUES,
        defaultValue: 3,
        weight: 1.0,
        statisticsCategory: 'speedPortAndWaterLaw'
    });

    const alcoholNode = network.addNode({
        label: 'Promillegrense',
        possibleValues: POSSIBLE_VALUES,
        defaultValue: 3,
        weight: 1.0,
        statisticsCategory: 'alcohol'
    });

    const technicalRequirements = network.addNode({
        label: 'Tekniske krav og vedlikeholdskrav',
        possibleValues: POSSIBLE_VALUES,
        defaultValue: 3,
        weight: 1.0,
        statisticsCategory: 'technicalRequirements'
    });

    const rentalRequirements = network.addNode({
        label: 'Krav til utleie',
        possibleValues: POSSIBLE_VALUES,
        defaultValue: 3,
        weight: 1.0,
        statisticsCategory: 'rentalRequirements'
    });

    const trainingNode = network.addNode({
        label: 'Opplæring/Kurs/kompetansehevende aktivitet',
        possibleValues: POSSIBLE_VALUES,
        defaultValue: 3,
        weight: 0.4,
        statisticsCategory: 'trainingNode'
    });

    const disseminationOfRegulationNode = network.addNode({
        label: 'Holdningsskapende arbeid & formidling av regelverk',
        possibleValues: POSSIBLE_VALUES,
        defaultValue: 3,
        weight: 0.6,
        statisticsCategory: 'disseminationOfRegulation'
    });

    const controlsAndPresenceNode = network.addNode({
        label: 'Kontroller og tilstedeværelse',
        possibleValues: POSSIBLE_VALUES,
        defaultValue: 3,
        weight: 0.8,
        statisticsCategory: 'controlsAndPresence'
    });

    network.addEdge(certificateNode, trainingNode, 0.8);
    network.addEdge(speedPortAndWaterLawNode, trainingNode, 0.2);

    network.addEdge(speedPortAndWaterLawNode, disseminationOfRegulationNode, 0.5);
    network.addEdge(alcoholNode, disseminationOfRegulationNode, 0.5);

    network.addEdge(alcoholNode, controlsAndPresenceNode, 0.5);
    network.addEdge(technicalRequirements, controlsAndPresenceNode, 0.5);

    network.addEdge(trainingNode, network.resultNode, 1.0/3);
    network.addEdge(disseminationOfRegulationNode, network.resultNode, 1.0/3);
    network.addEdge(controlsAndPresenceNode, network.resultNode, 1.0/3);

    const group1 = network.addNodeGroup(
        'Regelverk og krav',
        [certificateNode, speedPortAndWaterLawNode, alcoholNode, technicalRequirements, rentalRequirements],
        GroupID.Rules);
    const group2 = network.addNodeGroup(
        'Regulerende aktiviteter',
        [trainingNode, disseminationOfRegulationNode, controlsAndPresenceNode],
        GroupID.Regulations);

    network.setComparisonValues({
        2017: {
            [group1.id]: 2,
            [group2.id]: 1,
            result: 2
        },
        2018: {
            [group1.id]: 5,
            [group2.id]: 4,
            result: 5
        },
    });
    network.execute();

    return network;
}