import {Select} from "antd";
import React from "react";
import FilterInputProperties from "../interfaces/FilterInputProperties";
import {isMultipleOperator} from "../utilities/helpers";

export function CategoricalInput(
    props: FilterInputProperties
) {
    const { filter, onComparatorValueChange } = props;
    const {
        uniqueValues = []
    } = filter;

    return (
        <Select
            maxTagCount={2}
            maxTagTextLength={10}
            mode={isMultipleOperator(filter.operator) ? "multiple" : undefined}
            value={filter.comparatorValue as any}
            optionFilterProp="children"
            onChange={value => onComparatorValueChange(filter, value)}
            style={{ width: '100%' }}
            dropdownMatchSelectWidth={false}>
            {
                uniqueValues.map(
                    (value: string|number, index: number) =>
                        <Select.Option key={index} value={value}>{value}</Select.Option>)
            }
        </Select>
    );
}
