import React, {CSSProperties, Key, useCallback, useEffect, useState} from "react";
import {DragDropContext, Draggable, DraggingStyle, Droppable, DropResult, NotDraggingStyle} from "react-beautiful-dnd";
import {Modal, theme, Typography, Button} from "antd";
import {useSelector} from "react-redux";
import {getNightMode} from "../../store/selectors/user";
import {useDimension} from "../../utils/hooks";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGripVertical} from "@fortawesome/free-solid-svg-icons";
import {useInjection} from "inversify-react";
import Config from "../../interfaces/Config";
import _ from "lodash";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import Injectable from "../../injection/injectable";
// import {AliasToken} from "antd/lib/theme";

// @ts-ignore
import className from "../../assets/scss/components/infomodal.scss"
import {} from "antd";

export interface IncludeColumnsModalProperties {
  onClose: () => void;
  activeDimensionIds: Key[];
  onDimensionsChange: (activeDimensionIds: Key[]) => void;
  show?: boolean;
}

const getListStyle = (
  isDraggingOver: boolean,
  token: any
): Partial<CSSProperties> => {
  return {
    background: isDraggingOver ? token.controlItemBgActiveHover : 'transparent',
    width: 250,
    height: 350
  };
};

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined
) => ({
  ...draggableStyle
});

function DraggableDimensionName(
  props: {
    dimensionId: Key,
    index: number
  }
) {
  const { token } = theme.useToken();
  const nightMode = useSelector(getNightMode);
  const {
    dimensionId,
    index
  } = props;
  const dimension = useDimension(dimensionId);
  return (
    <Draggable
      key={dimensionId.toString()}
      draggableId={dimensionId.toString()}
      index={index}>
      {
        (provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={className.item}
            style={{
              ...getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style
              ),
              backgroundColor: nightMode ? token.colorBgBase : token.colorFillSecondary
            }}>
            <FontAwesomeIcon
              className={className.icon}
              icon={faGripVertical as IconProp} />
            {dimension?.label}
          </div>
        )
      }
    </Draggable>
  )
}

const enum DroppableId {
  Active = "active",
  Inactive = "inactive"
}

interface DataColumnEditorProperties {
  activeDimensionIds: Key[];
  onChange: (activeDimensionIds: Key[]) => void;
}

interface DataColumnListProperties {
  dimensionIds: Record<string, Key[]>;
  droppableId: DroppableId;
  label: string;
  className: string;
}

function DataColumnList(
  props: DataColumnListProperties
) {
  const {
    dimensionIds,
    label,
    droppableId
  } = props;
  const [dragContextIsAvailable, setDragContextIsAvailable] = React.useState(false);
  useEffect(() => setDragContextIsAvailable(true), []);
  const { token } = theme.useToken();
  const nightMode = useSelector(getNightMode);
  return (
    <div
      className={props.className}>
      <Typography.Title level={5}>
        {label}
      </Typography.Title>
      {
        dragContextIsAvailable ? (
          <Droppable
            key={droppableId}
            droppableId={droppableId}>
            {(provided, { isDraggingOver }) => (
              <div
                className="ant-card-bordered"
                style={{
                  ...getListStyle(isDraggingOver, token),
                  borderColor: nightMode ? token.colorBorder : token.colorBorderSecondary,
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderRadius: token.borderRadius
                }}
                ref={provided.innerRef}>
                <div className={className.columnList}>
                  {dimensionIds[droppableId].map((dimensionId, index) => (
                    <DraggableDimensionName
                      index={index}
                      key={dimensionId.toString()}
                      dimensionId={dimensionId} />
                  ))}
                  {provided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
        ) : <></>
      }
    </div>
  );
}

function DataColumnEditor(
  props: DataColumnEditorProperties
) {
  const { activeDimensionIds, onChange } = props;
  const config = useInjection<Config>(Injectable.Config);

  const [dimensionIds, setDimensionIds] = useState<Record<string, Key[]>>({
    [DroppableId.Active]: activeDimensionIds,
    [DroppableId.Inactive]: config.dimensions
      .filter(({ id }) => !activeDimensionIds.includes(id))
      .map(({ id }) => id)
  });

  const onDragEnd = useCallback((result: DropResult) => {
    const nextDimensions = _.cloneDeep(dimensionIds)
    const { source, destination } = result;
    if (source && destination) {
      const moveDimension = nextDimensions[source.droppableId][source.index];
      nextDimensions[source.droppableId].splice(source.index, 1);
      nextDimensions[destination.droppableId].splice(destination.index, 0, moveDimension);
      setDimensionIds(nextDimensions);
      onChange(nextDimensions[DroppableId.Active]);
    }
  }, [dimensionIds]);

  const moveAll = useCallback((target: DroppableId) => {
    const source = target === DroppableId.Active ? DroppableId.Inactive : DroppableId.Active;
    const nextDimensions = {
      [target]: dimensionIds[DroppableId.Active].concat(dimensionIds[DroppableId.Inactive]),
      [source]: []
    };
    setDimensionIds(nextDimensions);
    onChange(nextDimensions[DroppableId.Active]);
  }, [dimensionIds]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className={className.editingContainer}>
        <div className={className.columnListContainer}>
          <DataColumnList
            dimensionIds={dimensionIds}
            droppableId={DroppableId.Inactive}
            label="Ikke Inkludert"
            className={className.left} />
          <Button
            size="large"
            onClick={() => moveAll(DroppableId.Active)}
            className={className.moveColumnsButton}>
            Flytt alle ⇝
          </Button>
        </div>
        <div className={className.columnListContainer}>
          <DataColumnList
            dimensionIds={dimensionIds}
            droppableId={DroppableId.Active}
            label="Inkludert"
            className={className.right} />
          <Button
            size="large"
            onClick={() => moveAll(DroppableId.Inactive)}
            className={className.moveColumnsButton}>
            ⇜ Flytt alle
          </Button>
        </div>
      </div>
    </DragDropContext>
  );
}


export default function IncludeColumnsModal(
  props: IncludeColumnsModalProperties
) {
  const {
    onDimensionsChange,
    onClose,
    activeDimensionIds
  } = props;

  const [nextActiveDimensionIds, setNextActiveDimensionIds] = useState(activeDimensionIds)

  const onOk = useCallback(() => {
    onDimensionsChange(nextActiveDimensionIds);
    onClose();
  }, [nextActiveDimensionIds]);

  return (
    <Modal
      title={
        <Typography.Title level={3} style={{ margin: 0, padding: 0 }}>
          Velg hvilke kolonner som skal være inkludert
        </Typography.Title>
      }
      destroyOnClose={true}
      onOk={onOk}
      onCancel={onClose}
      open={props.show}
      width={600}>
      <DataColumnEditor
        onChange={setNextActiveDimensionIds}
        activeDimensionIds={activeDimensionIds} />
    </Modal>
  )
}
