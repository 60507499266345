import React, {useMemo} from "react";
import dayjs from "dayjs";
import {DatePicker} from "antd";
import FilterInputProperties from "../interfaces/FilterInputProperties";

export function DateInput(props: FilterInputProperties) {
    const { filter, onComparatorValueChange } = props;
    const comparatorValue = filter.comparatorValue as number;

    const date = useMemo(() => {
        const date = dayjs.unix(comparatorValue);
        return date.isValid() ? date : null;
    }, [comparatorValue]);

    return (
        <DatePicker
            placeholder="Dato"
            style={{ display: 'flex', flex: 1 }}
            size="small"
            value={date}
            onChange={(nextDate: any) => {
                onComparatorValueChange(filter, nextDate?.unix());
            }}/>
    );
}