import ComponentEditorProperties from "../../interfaces/properties/ComponentEditorProperties";
import {Categorical} from "../../constants/globalTypes";
import {Form, Radio, Select} from "antd";
import {DimensionType} from "../../interfaces/Config";
import Injectable from "../../injection/injectable";
import {useDataBuilder, useDimensionOptions} from "../../utils/hooks";
import React from "react";
import {injectReactComponent} from "../../utils/injectionUtilities";
import {PreviewAreaProperties} from "./PreivewArea";
import {Chart, ComponentType, Metric} from "../../constants/enums";
import {METRIC_OPTIONS} from "../../constants/options";

// @ts-ignore
import className from "../../assets/scss/statistics.scss";

const LAYOUT = {
  labelCol: { span: 7 },
  wrapperCol: { span: 15 },
};

function categoricalValid(categorical: Partial<Categorical>) {
  return !!categorical.xAxisId && (categorical.metric === Metric.Count || !!categorical.yAxisId);
}

const DEFAULT_INITIAL = {
  chartType: Chart.Bar,
  metric: Metric.Count
}

export function CategoricalEditor(props: ComponentEditorProperties<Categorical>) {
  const {
    data,
    onDataChange,
    renderer: Renderer,
    isEditingExistingComponent
  } = props;
  const [form] = Form.useForm();
  const [categorical, updateCategorical, valid] = useDataBuilder<Categorical>(
    ComponentType.Categorical, data ?? DEFAULT_INITIAL, onDataChange, categoricalValid);

  const categoricalOptions = useDimensionOptions({
    type: [DimensionType.Categorical, DimensionType.GeoCategorical]
  });
  const numericalOptions = useDimensionOptions({
    type: DimensionType.Numerical
  });

  const PreviewArea = injectReactComponent<PreviewAreaProperties>(Injectable.PreviewArea)

  return (
    <div className={className.editorContainer}>
      <div className={className.formContainer}>
        <Form
          className={className.form}
          form={form}
          name="trend-editor"
          {...LAYOUT}>
          <Form.Item
            label={categorical.chartType === Chart.Pie ? "Dimensjonkolonne" : "X-akse"}
            rules={[{ required: true }]}>
            <Select
              defaultValue={categorical.xAxisId}
              value={categorical.xAxisId}
              options={categoricalOptions}
              onChange={(value, option: any) => updateCategorical(draft => ({
                ...draft,
                xAxisId: option.dimension.id
              }))} />
          </Form.Item>
          <Form.Item
            label="Aggregering">
            <Select
              defaultValue={categorical.metric}
              value={categorical.metric}
              options={METRIC_OPTIONS}
              onChange={(value, option) => {
                updateCategorical(draft => ({
                  ...draft,
                  metric: value as Metric,
                  yAxisId: value !== Metric.Count ? categorical.yAxisId : undefined
                }));
                /* form.setFieldsValue({
                  dimension: value !== Metric.Count ? categorical.yAxis?.label : undefined,
                }); */
              }} />
          </Form.Item>
          {
            categorical.metric !== Metric.Count && (
              <Form.Item
                label={ categorical.chartType === Chart.Pie ? "Verdikolonne" : "Y-akse" }
                rules={[{ required: true }]}>
                <Select
                  value={categorical.yAxisId}
                  options={numericalOptions}
                  onChange={(value, option: any) => updateCategorical(draft => ({
                    ...draft,
                    yAxisId: option.dimension.id
                  }))} />
              </Form.Item>
            )
          }
          <Form.Item
            name="groupby"
            label="Grupper">
            <Select
              allowClear
              value={categorical.groupById}
              options={categoricalOptions}
              onChange={(value, option: any) => updateCategorical(draft => ({
                ...draft,
                groupById: option && option.dimension.id
              }))}
            />
          </Form.Item>
          <Form.Item
            label="Diagram type">
            <Radio.Group
              buttonStyle="solid"
              defaultValue={categorical.chartType}
              value={categorical.chartType}
              size="middle"
              onChange={e => {
                updateCategorical(draft => ({
                  ...draft,
                  chartType: e.target.value
                }));
              }} >
              <Radio.Button value={Chart.Bar}>Søyle</Radio.Button>
              <Radio.Button value={Chart.Pie}>Kake</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Form>
      </div>
      <PreviewArea width={500} height={300}>
        {
          ({ width, height }) => (
            <Renderer
              isEditingExistingComponent={isEditingExistingComponent}
              editing={true}
              valid={valid}
              data={categorical}
              width={width}
              height={height} />
          )
        }
      </PreviewArea>
    </div>
  )
}
