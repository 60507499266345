import {DataPoint} from "../../interfaces/models/DataPoint";
import {DimensionFunction} from "./constants/types";
import Fuse from "fuse.js";
import {Dimension} from "crossfilter2";
import {isDefined} from "../../utils/validationUtilities";
import _ from "lodash";

export default class TextDimension {

  private readonly _idAccessor: (dataPoint: DataPoint) => (string | number);
  private readonly _dimensionFunction: DimensionFunction<String>;
  private _fuse?: Fuse<any>;
  private _crossfilterDimension: Dimension<any, any>;
  private _text: string|null = null;

  constructor(
    idAccessor: (dataPoint: DataPoint) => string | number,
    dimensionFunction: DimensionFunction<String>,
    crossfilterDimension: Dimension<any, any>
  ) {
    this._idAccessor = idAccessor;
    this._dimensionFunction = dimensionFunction;
    this._crossfilterDimension = crossfilterDimension;
  }

  public updateFuse(data: any[]) {
    const mappedData = data.map(d => ({
      id: this._idAccessor(d),
      text: this._dimensionFunction(d)
    }));
    this._fuse = new Fuse(mappedData,{
      includeScore: true,
      includeMatches: true,
      threshold: 0.3,     // default = 0.6
      distance: 100,      // default = 100
      keys: ['text']
    });
    this.filter(this._text);
  }

  public filter(text: string|null) {
    this._text = text;
    if (text == null || text.length == 0) {
      this._crossfilterDimension.filterAll();
    } else {
      const result = this._fuse?.search(text).map(d => d.item.id);
      let lookup: Record<string, boolean> = {};
      if (isDefined(result)) {
        lookup = result!.length > 0
          ? _.zipObject(result!, result!.map(() => true))
          : {};
      }
      this._crossfilterDimension.filter(rowId => lookup[rowId as string]);
    }
  }

}
