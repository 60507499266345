import {Filter, SimpleFilter, TruthFunction, ValueAccessor} from "../constants/globalTypes";
import {FilterType, Type} from "../constants/enums";
import {Polygon} from "ol/geom";
import _ from "lodash";

export function isDefined<T = any>(
    value: any
): value is T {
    return value !== null && value !== undefined;
}

export function isPolygon(
    value: any = {}
): value is Polygon {
    return _.isFunction(value.getInteriorPoint);
}


export function isStringArray(
  value: any
): value is Array<string> {
    return _.isArray(value) && value.length > 0 && value.every(v => _.isString(v));
}

export function isSimpleFilter(
  value?: any
): value is SimpleFilter {
    return value && value.type === FilterType.Geo;
}

export function isFilter(
  value?: any
): value is Filter {
    return !isSimpleFilter(value);
}

export async function whenDefined(...accessors: ValueAccessor[]): Promise<any> {
    return new Promise(((resolve, reject) => {
        const interval = setInterval(function() {
            const allDefined = accessors.every(accessor => isDefined(accessor()))
            if (allDefined) {
                clearInterval(interval);
                const data = accessors.map(accessor => accessor());
                resolve(...data);
            }
        }, 10);
    }));
}


export function isArray(
    value: any,
    typeOf: TruthFunction<any> = () => true
): value is Array<any> {
    return isDefined(value) && value.constructor === [].constructor && (!typeOf || value.every(typeOf));
}

export function typeOfObject(
    obj: any
): Type {
    if (isDefined(obj)) {
        const [first, second] = obj.constructor.toString().split(' ').slice(0, 2);
        if (first === Type.Function && typeof second === Type.String) {
            if (second === 'true' || second === 'false') {
                return Type.Boolean;
            }
            return second.slice(0, -2).toLowerCase() as Type;
        } else if (first === 'class') {
            return first as Type;
        }
    }
    return Type.None;
}

export function isString(
    value: any
): value is string {
    return typeOfObject(value) === Type.String;
}

export function isFunction<T extends Function>(
    arg: any
): arg is T {
    return typeOfObject(arg) === Type.Function;
}

export function isNumber(
    value: any
): value is number {
    return typeOfObject(value) === Type.Number;
}
