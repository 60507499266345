import * as React from "react";
import {bindActionCreators, Dispatch} from "redux";
import {connect, ConnectedProps} from "react-redux";
import {resolve} from "inversify-react";
import Injectable from "../../injection/injectable";
import DataSource from "../../interfaces/DataSource";
import GlobalState from "../../store/interfaces/states/GlobalState";
import DataSourceProviderProps from "../../interfaces/properties/DataSourceProviderProperties";
import {DataPoint} from "../../interfaces/models/DataPoint";
import DataProvider from "./DataProvider";
import {injectable} from "inversify";
import {Dimension as CrossfilterDimension, NaturallyOrderedValue} from "crossfilter2";
import _ from "lodash";
import {createFilterFunctionFromFilters, isActiveFilter} from "../../utils/filterUtilities";
import {Filter, SimpleFilter} from "../../constants/globalTypes";
import {isFilter} from "../../utils/validationUtilities";
import Config, {DimensionDefinition} from "../../interfaces/Config";
import {lookupFromArray} from "../../utils/miscUtilities";
import {Key} from "react";

export interface DataContextState {
    data: DataPoint[];
    dataSource: DataSource;
}

interface DataProviderState {
    data: DataPoint[];
    activeFilters: (Filter|SimpleFilter)[];
}

export const DataSourceContext = React.createContext<DataContextState>({} as DataContextState);

export function useDataSourceContext(): DataContextState {
    return React.useContext(DataSourceContext) as DataContextState;
}

@injectable()
class DataSourceProvider extends React.Component<DataSourceProviderProps & PropsFromRedux, DataProviderState> {

    @resolve(Injectable.Config)
    private readonly _config!: Config;

    @resolve(Injectable.DataProvider)
    private readonly _dataProvider!: DataProvider;

    @resolve(Injectable.DataSource)
    private readonly _dataSource!: DataSource;
    private _dimension?: CrossfilterDimension<DataPoint, NaturallyOrderedValue>;
    private _dimensionLookup: Record<Key, DimensionDefinition<DataPoint>> = {};
    private _populateDataCalled: boolean = false;

    constructor(props: DataSourceProviderProps & PropsFromRedux) {
        super(props);
        this.state = {
            data: [] as DataPoint[],
            activeFilters: []
        };
    }

    public componentDidMount() {
        this._dimension = this._dataSource.crossfilter.dimension(((d: DataPoint) => d) as any);
        this._dimensionLookup = lookupFromArray(
            this._dimensions, ({ id }: DimensionDefinition<DataPoint>) => id);
        this._setupEventListeners();
        this._populateSourceWithData();
        this._initializeFilters();
    }

    public componentDidUpdate(
        prevProps: Readonly<DataSourceProviderProps & PropsFromRedux>,
        prevState: Readonly<any>,
        snapshot?: any
    ) {
        if (!this.props.authenticated && this._dataSource.hasData()) {
            this._dataSource.clear();
        }

        const previousFilterLookup = lookupFromArray<Filter>(
          prevProps.filters.filter(isFilter), f => f.filterId);
        const currentFilterLookup = lookupFromArray<Filter>(
          this.props.filters.filter(isFilter), f => f.filterId);

        const updatedDimensionIdLookup: Record<string, boolean> = {};

        _.values(currentFilterLookup)
          .filter(filter => DataSourceProvider.wasFilterChanged(filter, previousFilterLookup[filter.filterId]))
          .forEach(filter => {
              const { dimensionId } = filter;
              if (!updatedDimensionIdLookup[dimensionId]) {
                  this._updateFilter(filter, currentFilterLookup);
                  updatedDimensionIdLookup[dimensionId] = true;
              }
          });

        _.values(previousFilterLookup)
          .filter(({ filterId, dimensionId }) => !currentFilterLookup[filterId] && !updatedDimensionIdLookup[dimensionId])
          .forEach(filter => {
              const { dimensionId } = filter;
              if (!updatedDimensionIdLookup[dimensionId]) {
                  this._updateFilter(filter, currentFilterLookup);
                  updatedDimensionIdLookup[dimensionId] = true;
              }
          });

    }

    public render() {
        const { children } = this.props;
        const { data } = this.state;
        const dataSource = this._dataSource;
        return (
            <DataSourceContext.Provider
              value={{ data, dataSource }}>
                {children}
            </DataSourceContext.Provider>
        );
    }

    private get _dimensions(): DimensionDefinition<DataPoint>[] {
        return this._config.dimensions;
    }

    private _populateSourceWithData(): void {
        if (!this._populateDataCalled) {
            this._populateDataCalled = true;
            this._dataProvider.populateDataSource(this._dataSource);
        }
    }

    private _initializeFilters() {
        const currentFilterLookup = lookupFromArray<Filter>(
          this.props.filters.filter(isFilter), f => f.filterId);
        this.props.filters
          .filter(isFilter)
          .forEach(filter => this._updateFilter(filter, currentFilterLookup));
    }

    private _updateFilter(
      filter: Filter,
      currentFilterLookup: Record<string, Filter>
    ): void {
        const { dimensionId } = filter;
        const label = this._dimensionLookup[dimensionId].label;
        const activeFiltersWithSameDimensionId = _.values(currentFilterLookup)
          .filter(({ dimensionId }) => dimensionId === filter.dimensionId)
          .filter(isActiveFilter);
        const nextFilterFunctionForDimension = createFilterFunctionFromFilters(activeFiltersWithSameDimensionId);
        const { selector, isArray } = this._dimensionLookup[dimensionId];
        this._dataSource
          .dimension(dimensionId, label, selector, isArray)
          .filter(nextFilterFunctionForDimension);
    }

    private _setupEventListeners(): void {
        this._dataSource.onDataFiltered(dataSource => {
            const filters = dataSource.getFilters();
            this._dataSource.takeFiltered().then(data => {
                this.setState(prevState => Object.assign({}, prevState, { filters, data }));
            });
        });
        this._dataSource.onDataChanged(() => {
            this._dataSource.takeFiltered().then(data => {
                this.setState(prevState => Object.assign({}, prevState, { data }));
            });
        });
    }

    private static wasFilterChanged(
      filter: Filter,
      oldFilter?: Filter
    ) {
        return !oldFilter || !_.isEqual(oldFilter, filter);
    }

}

function mapStateToProps(state: GlobalState) {
    const { authenticated } = state.auth;
    const { filters } = state.data;
    return { authenticated, filters };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators({
    }, dispatch)
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(DataSourceProvider);
