import { Action } from "redux";
import UserState, {UserData, UserInfo} from "../interfaces/states/UserState";
import {UserAction} from "../actions/user";
import {
  AddSavedFilterAction,
  HydrateDataAction,
  SetActiveDimensionAction, UpdateAccidentLayerConfigAction, UpdateDimensionGraphsAction,
  UpdateHomeStatisticViewAction, UpdatePaneSizeAction
} from "../interfaces/actions/user";
import {DEFAULT_USER_DATA} from "../../constants/defaults";
import {isActiveFilter} from "../../utils/filterUtilities";
import {container} from "../../injection";
import Config from "../../interfaces/Config";
import Injectable from "../../injection/injectable";
import {RESIZER_BORDER_SIZE} from "../../constants/misc";
const dataString = localStorage.getItem("fbp-user-data");
const data = dataString ? JSON.parse(dataString) : {};

const {
  filterBarDefaultSize: filterBarSize
} = container.get<Config>(Injectable.Config);

const mapPaneSize = (window.innerWidth - RESIZER_BORDER_SIZE - filterBarSize) * 0.5;
const dataPaneSize = window.innerWidth - 2 * RESIZER_BORDER_SIZE - filterBarSize - mapPaneSize!;

const initialState: UserState = {
  data: {
    ...DEFAULT_USER_DATA,
    ...{
      filterBarSize,
      mapPaneSize,
      dataPaneSize
    },
    ...data
  }
};

function updateUserData(state: UserState, update: Partial<UserData>) {
  return {
    ...state,
    data: {
      ...state.data,
      ...update
    }
  }
}

function getDataPaneSize(
  filterBarSize: number,
  mapPaneSize: number,
  filterBarExpanded: boolean
) {
  let size = window.innerWidth - 2 * RESIZER_BORDER_SIZE - mapPaneSize;
  if (filterBarExpanded) {
    size -= filterBarSize;
  }
  return size;
}

// Use the initialState as a default value
export default function user(
  state = initialState,
  action: Action
) {
  switch (action.type) {
    case UserAction.UpdateFilterBarSize: {
      const { size: filterBarSize } = action as UpdatePaneSizeAction;
      const {
        mapPaneSize,
        filterExpanded
      } = state.data;
      const dataPaneSize = getDataPaneSize(filterBarSize, mapPaneSize!, filterExpanded);
      return updateUserData(state,{ filterBarSize, dataPaneSize });
    }
    case UserAction.UpdateMapSize: {
      const { size: mapPaneSize } = action as UpdatePaneSizeAction;
      const {
        filterBarSize,
        filterExpanded
      } = state.data;
      const dataPaneSize = getDataPaneSize(filterBarSize!, mapPaneSize, filterExpanded);
      return updateUserData(state,{ mapPaneSize, dataPaneSize });
    }
    case UserAction.ToggleExpandFilter: {
      const filterExpanded = !state.data.filterExpanded;
      const {
        filterBarSize,
        mapPaneSize
      } = state.data;
      const dataPaneSize = getDataPaneSize(filterBarSize!, mapPaneSize!, filterExpanded);
      return updateUserData(state, { filterExpanded, dataPaneSize });
    }
    case UserAction.UpdateAccidentLayerConfig: {
      const { accidentLayerConfig } = action as UpdateAccidentLayerConfigAction;
      return updateUserData(state,{ accidentLayerConfig });
    }
    case UserAction.UpdateHomeStatisticView: {
      const { homeStatisticView } = action as UpdateHomeStatisticViewAction;
      return updateUserData(state,{ homeStatisticView });
    }
    case UserAction.ToggleNightMode: {
      const nightMode = !state.data.nightMode;
      return updateUserData(state,{ nightMode });
    }
    case UserAction.SetActiveDimensionIdsInMoreInfoModal: {
      const {
        activeDimensionIds: activeDimensionIdsInMoreInfoModal
      } = action as SetActiveDimensionAction;
      return updateUserData(state,{ activeDimensionIdsInMoreInfoModal });
    }
    case UserAction.SetActiveDimensionIdsInDataCard: {
      const {
        activeDimensionIds: activeDimensionIdsInDataCard
      } = action as SetActiveDimensionAction;
      return updateUserData(state,{ activeDimensionIdsInDataCard });
    }
    case UserAction.AddSavedFilter: {
      const { name, filters } = action as AddSavedFilterAction;
      const {
        savedFilters = []
      } = state.data;
      const nextSavedFilters = savedFilters.concat({
        name,
        filters: filters.filter(isActiveFilter)
      });
      return updateUserData(state,{
        savedFilters: nextSavedFilters
      });
    }
    case UserAction.HydrateUserInfo: {
      const { data: info } = action as HydrateDataAction<UserInfo>;
      return {
        ...state,
        info
      }
    }
    case UserAction.HydrateUserDataSuccess: {
      const { data } = action as HydrateDataAction<UserData>;
      return updateUserData(state,{
        ...DEFAULT_USER_DATA,
        ...data
      });
    }
    case UserAction.UpdateDimensionGraphs: {
      const { dimensionGraphs } = action as UpdateDimensionGraphsAction;
      return updateUserData(state, {
        dimensionGraphs
      });
    }
  }
  return state;
}
