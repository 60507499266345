import StatisticDefinition from "../interfaces/StatisticDefinition";
import StatisticsVisualizationPlugin from "../interfaces/StatisticsVisualizationPlugin";
import {Filter} from "../../filterRenderer/types";
import React, {useMemo} from "react";


interface RendererProperties {
    statisticsDefinition: StatisticDefinition;
    plugin: StatisticsVisualizationPlugin;
    filterState: Record<string, Filter>;
}
export function Renderer(props: RendererProperties) {
    const {
        statisticsDefinition,
        plugin,
        filterState = {}
    } = props;
    const StatisticRenderer = useMemo(() => plugin.createRenderer(), []);
    return <StatisticRenderer
                filterState={filterState}
                statisticDefinition={statisticsDefinition} />
}