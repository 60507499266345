import React from "react";
import GlobalState from "../../store/interfaces/states/GlobalState";
import {bindActionCreators, Dispatch} from "redux";
import {connect, ConnectedProps, useSelector} from "react-redux";
import TabComponentProperties from "../../interfaces/properties/TabComponentProperties";
import {updateHomeStatisticView} from "../../store/actions/user";
import {DEFAULT_HOME_STATISTICS_VIEW} from "../../constants/defaults";
import {StatisticViewMakerProperties} from "../statisticViewMaker";
import {injectReactComponent} from "../../utils/injectionUtilities";
import Injectable from "../../injection/injectable";

export interface StatisticsTabProperties extends TabComponentProperties {

}

function StatisticsTab(
    props: StatisticsTabProperties & PropsFromRedux
) {
    const {
        homeStatisticView = DEFAULT_HOME_STATISTICS_VIEW,
        updateHomeStatisticView
    } = props;
    const StatisticViewMaker = injectReactComponent<StatisticViewMakerProperties>(Injectable.StatisticViewMaker);
    const width = useSelector((state: GlobalState) => state.user.data.dataPaneSize!);
    return (
        <div style={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
        }}>
            <StatisticViewMaker
                width={width}
                statisticView={homeStatisticView}
                onStatisticViewChange={updateHomeStatisticView}
            />
        </div>
    )
}

function mapStateToProps(state: GlobalState) {
  const {
    homeStatisticView
  } = state.user.data || {};
  return {
    homeStatisticView
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({
    updateHomeStatisticView
  }, dispatch)
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(StatisticsTab);

