// TODO: Currently storing this in local storage for demo, this is not safe and should be changed

import axios from "axios";
import {Message} from "../constants/enums";

export function downloadData() {
    const host = process.env.API_HOST;
    const port = process.env.API_PORT;
    const version = process.env.API_VERSION;
    const proto = (host === "localhost") || (host === "127.0.0.1") ? "http" : "https";
    const url = `${proto}://${host}:${port}/api/${version}/accidents/download`;
    const config = {
    };
    return axios({url: url, method: 'GET', responseType: 'blob', ...config}).then(
        (response) => {
            const dlUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a')
            link.href = dlUrl;
            link.setAttribute('download', 'accidents.xlsx');
            document.body.appendChild(link)
            link.click()
        }
    )
    .then(() => Promise.resolve(Message.DataDownloaded))
    .catch(err => {
        console.error(err);
        return Promise.reject(Message.DataDownloadedFailed);
    });

}
