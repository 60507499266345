import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock} from "@fortawesome/free-solid-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import React, {CSSProperties} from "react";
import {useSelector} from "react-redux";
import GlobalState from "../../store/interfaces/states/GlobalState";
import {theme, Typography} from "antd";
import _ from "lodash";

// @ts-ignore
import className from "../../assets/scss/components/datacard.scss";

export enum RedactedViewType {
  Full = 0,
  Inline = 1,
  InlineCompact = 2
}

export interface RedactedViewProperties {
  value?: any;
  type?: RedactedViewType;
  style?: Partial<CSSProperties>;
  compact?: boolean;
}

function checkIfRedacted(
  value: any
) {
  const redactionString = useSelector((state: GlobalState) => state.data.redactionString);
  const redactionInt = useSelector((state: GlobalState) => state.data.redactionInt);
  return (_.isArray(value) && (value.includes(redactionString)
    || value.includes(redactionInt)))
    || value === redactionString || value === redactionInt;
}

function getClassName(type: RedactedViewType) {
  switch (type) {
    case RedactedViewType.Full:
      return className.redacted;
    case RedactedViewType.Inline:
    case RedactedViewType.InlineCompact:
      return `${className.redactedInline} ${className.compact}`;
  }
}

export default function RedactedView(
  props : RedactedViewProperties
) {
  const {
    value,
    style,
    type = RedactedViewType.Full
  } = props;
  const { token } = theme.useToken();
  if (checkIfRedacted(value)) {
    const {
      colorWarning: color
    } = token;
    return (
      <div className={getClassName(type)}>
        <div
          style={{ color }}
          className={className.iconContainer}>
          <FontAwesomeIcon
            icon={faLock as IconProp}
            className={className.lock} />
        </div>
        {
          type === RedactedViewType.InlineCompact ? (
            <Typography.Text
              strong
              type="warning" className={className.text}>
              Ingen tilgang
            </Typography.Text>
          ) : (
            <Typography.Title
              level={type === RedactedViewType.Full ? 5 : 4}
              type="warning" className={className.text}>
              Du har ikke tilgang til denne informasjonen
            </Typography.Title>
          )
        }
      </div>
    )
  }
  return <div style={style}>{value?.toString() || 'Ukjent'}</div>
}
