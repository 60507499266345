import React, {useMemo} from "react";
import {findClosestColor} from "../utilities/helpers";
import _ from "lodash";
import {RiskInputOption} from "../types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowTrendUp, faArrowTrendDown, faArrowRight} from "@fortawesome/free-solid-svg-icons";

// @ts-ignore
import className from "../../../assets/scss/components/riskmodel.scss";

interface IndicatorProperties {
    value: number;
    possibleValues: RiskInputOption[];
    trend?: number;
    label?: string;
    noLabel?: boolean;
}

function Trend(props: Pick<IndicatorProperties, "trend">) {
    const {
        trend
    } = props;
    switch (trend) {
        case 1:
            return <FontAwesomeIcon icon={faArrowTrendUp} />
        case 0:
            return <FontAwesomeIcon icon={faArrowRight} />
        case -1:
            return <FontAwesomeIcon icon={faArrowTrendDown} />;
        default: return <></>;
    }
}

function Label(props: Pick<IndicatorProperties, "noLabel"|"label"|"value">) {
    const { noLabel, label, value } = props;
    return noLabel
        ? <></>
        : (
            <span>
                {
                    !_.isUndefined(label)
                        ? label
                        : _.toString(value)
                }
            </span>
        );
}

export function Indicator(props: IndicatorProperties) {
    const {
        value,
        label,
        possibleValues,
        trend,
        noLabel = false
    } = props;
    const color = useMemo(() => findClosestColor(value, possibleValues), [value]);
    return (
        <div
            style={{
                background: color
            }}
            className={className.indicatorMenuItem}>
            {
                _.isNumber(trend)
                    ? <Trend trend={trend} />
                    : <Label label={label} noLabel={noLabel} value={value} />
            }
        </div>
    );
}
