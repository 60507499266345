import React, {CSSProperties, isValidElement, Key, ReactElement, useMemo} from "react";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {rowsFromData, SPECIALIZED_RENDERERS, SpecializedRendererProperties} from "../ui/AntDAccidentDataTable";
import {faInfo, IconDefinition, faFilter} from "@fortawesome/free-solid-svg-icons";
import * as dataActions from "../../store/actions/data";
import {focusOnDataPoint, showMoreInfoAboutDataPoint} from "../../store/actions/data";
import {DimensionId, LayerType} from "../../constants/enums";
import {Button, Card, theme, Tooltip} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DimensionDefinition} from "../../interfaces/Config";
import GlobalState from "../../store/interfaces/states/GlobalState";
import {bindActionCreators, Dispatch} from "redux";
import {connect, ConnectedProps, useSelector} from "react-redux";
import {getAccidentLayerConfig, getNightMode} from "../../store/selectors/user";
import {RedactedViewType} from "./RedactedView";
import _ from "lodash";

import className from "../../assets/scss/components/datacard.scss";
import {DataPoint} from "../../interfaces/models/DataPoint";

interface DataCardActionProperties {
  text?: string;
  icon: IconDefinition|ReactElement;
  onClick?: () => void;
  color?: string;
}

export interface EditableDataCardProperties {
  data: any;
  style?: Partial<CSSProperties>;
  dimensions?: DimensionDefinition<DataPoint>[];
  infoAction?: boolean;
  zoomInMapAction?: boolean;
  showInMapAction?: boolean;
}

function DescriptionRenderer(props: SpecializedRendererProperties<[number, number]>) {
  const { value, RedactedView } = props;
  if (_.isString(value)) {
    return (
        <Card className={className.descriptionContainer}>
          <RedactedView
              type={RedactedViewType.Full}
              value={value}
              style={{margin: 10}}/>
        </Card>
    )
  }
  return <Card className={className.descriptionContainer} />;


}

const DIMENSIONS_WITHOUT_PROPERTY_LABEL: Key[] = [DimensionId.Description];
const SPECIALIZED_RENDERERS_DATA_CARD = {
  ...SPECIALIZED_RENDERERS,
  [DimensionId.Coordinates]: (props: SpecializedRendererProperties<[number, number]>) => {
    const {
      value: coordinates,
    } = props;
    return <div>{coordinates.toString()}</div>
  },
  [DimensionId.Description]: DescriptionRenderer
}

const DataCardAction = (
  props: DataCardActionProperties
) => {
  const { text, icon, onClick, color } = props;

  return (
    <Tooltip title={text}>
      <Button
        type="default"
        size="large"
        className={className.action}
        style={{
          border: 0,
          background: "transparent",
          boxShadow: "none",
          fontSize: 20
        }}
        onClick={onClick}>
        {
          isValidElement(icon) ? icon : (
            <FontAwesomeIcon
              icon={icon as IconProp}
              className={className.icon}
              style={{ color }} />
          )
        }
      </Button>
    </Tooltip>
  );
}

function getRedactionViewTypeFromDimension(d: DimensionDefinition<DataPoint>) {
  return d.id === DimensionId.Description
    ? RedactedViewType.Inline
    : RedactedViewType.InlineCompact
}

const AntdEditableDataCard = (props: EditableDataCardProperties & PropsFromRedux) => {
  const {
    data,
    focusOnDataPoint,
    showMoreInfoAboutDataPoint,
    style = {},
    dimensions = [],
    zoomInMapAction = true,
    infoAction = true,
  } = props;
  const nightMode = useSelector(getNightMode);
  const { token } = theme.useToken();
  const {
    width = '100%',
    height = 'auto'
  } = style;
  const { id } = data;
  const { layerType } = useSelector(getAccidentLayerConfig);
  const rows = rowsFromData(data, dimensions, SPECIALIZED_RENDERERS_DATA_CARD, getRedactionViewTypeFromDimension);
  return useMemo(() => {
    return (
      <Card
        className={className.card}
        style={{
          ...style,
          background: nightMode ? token.colorBgElevated : token.colorBgContainerDisabled,
        }}>
        <table className={className.table}>
          <tbody>
          {
            rows.map(({
                        id,
                        value,
                        property
                      }) => {
              const includeProperty = !DIMENSIONS_WITHOUT_PROPERTY_LABEL.includes(id);
              return (
                <tr key={id}>
                  {
                    includeProperty && (
                      <td
                        key="property"
                        style={{
                          width: 1,
                          whiteSpace: 'nowrap',
                          paddingRight: 16,
                          verticalAlign: 'top',
                          paddingLeft: 2
                        }}>
                        <strong>{property}:</strong>
                      </td>
                    )
                  }
                  <td
                    key="value"
                    colSpan={includeProperty ? 1 : 2}>
                    {value}
                  </td>
                </tr>
              );
            })
          }
          </tbody>
        </table>
        <div className={className.actions}>
          {
            zoomInMapAction && layerType === LayerType.ClusteredPoints ? (
              <DataCardAction
                key="zoom"
                icon={faFilter}
                text="Zoom i Kart"
                onClick={() => focusOnDataPoint(id, 8 + 1)}
              />
            ) : <></>
          }
          {
            infoAction ? (
              <DataCardAction
                key="info"
                icon={faInfo}
                text="Mer info"
                onClick={() => showMoreInfoAboutDataPoint(data)} />
            ) : <></>
          }
        </div>
      </Card>
    );
  }, [id, width, height, nightMode, dimensions]);
};

function mapStateToProps(state: GlobalState) {
  const {
    activeDimensionIdsInDataCard
  } = state.user.data;
  const { focusId, redactionString } = state.data;
  return { focusId, redactionString, activeDimensionIdsInDataCard };
}

function mapDispatchToProps(dispatch: Dispatch) {
  const {
    focusOnDataPoint,
    showMoreInfoAboutDataPoint,
    unFocusDataPoint
  } = dataActions;
  return bindActionCreators({
    focusOnDataPoint,
    showMoreInfoAboutDataPoint,
    unFocusDataPoint
  }, dispatch)
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AntdEditableDataCard);
