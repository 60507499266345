import React, {PropsWithChildren} from "react";
import GlobalState from "../../store/interfaces/states/GlobalState";
import { Dispatch, bindActionCreators } from "redux";
import { connect, ConnectedProps } from "react-redux";
import {callOnce} from "../../utils/hooks";
import {authCheck} from "../../store/actions/auth";
import {Space, Spin, theme} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import {hydrateUserData} from "../../store/actions/user";
import {Redirect} from "react-router-dom";
import {RouterPath} from "../../constants/enums";

function CheckAuthWrapper(
    props: PropsWithChildren<PropsFromRedux>
) {
  const {
    authenticated,
    children,
    waitingForAuthStatus,
    hydrateUserData
  } = props;
  const { token } = theme.useToken();
  callOnce(props.authCheck);
  callOnce(hydrateUserData, () => authenticated == true);
  if (waitingForAuthStatus) {
    const antIcon = <LoadingOutlined style={{ fontSize: 64 }} spin />;
    return (
      <Space
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          padding: 100,
          background: token.colorBgLayout
        }}
        size="large">
        <Spin indicator={antIcon}  size="large" />
      </Space>
    )
  } else if (authenticated) {
    return (
      <>
        {children}
      </>
    );
  } else if (!authenticated && !waitingForAuthStatus) {
    return <Redirect to={RouterPath.Login} />;
  }
  return <></>;
}

function mapStateToProps(state: GlobalState) {
    const { waitingForAuthStatus, authenticated, errorStatus } = state.auth;
    const {
      data: userData
    } = state.user;
    return {
      waitingForAuthStatus,
      authenticated,
      errorStatus,
      userData
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators({
      authCheck,
      hydrateUserData
    }, dispatch)
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(CheckAuthWrapper);
