import Analytics from 'analytics';
// @ts-ignore
import googleAnalytics from '@analytics/google-analytics';
// @ts-ignore
import googleTagManager from '@analytics/google-tag-manager';

export const enum AnalyticsEvent {
    FilterChanged = 'FilterChanged',
    TabChanged = 'TabChanged',
    LogoutClicked = 'LogoutClicked',
    LoggedOut = 'LoggedOut',
    LoginFailed = 'LoginFailed',
    LoginSucceeded = 'LoginSucceeded',
    Console = 'Console',
    ShowInMap = 'ShowInMap',
    ScrollingDataExplorer = 'ScrollingDataExplorer',
    OpenNewAccidentModal = 'OpenNewAccidentModal',
    OpenMoreInfoModal = 'OpenMoreInfoModal',
    SetLayoutAlternative = 'SetLayoutAlternative',
}

const googleAnalyticsTrackingId = process.env.GOOGLE_ANALYTICS_TRACKING_ID;
const googleTagManagerContainerId = process.env.GOOGLE_TAG_MANAGER_CONTAINER_ID;

const plugins = [];

if (googleAnalyticsTrackingId) {
    plugins.push(googleAnalytics({
        trackingId: googleAnalyticsTrackingId,
    }));
}

if (googleTagManagerContainerId) {
    plugins.push(googleTagManager({
        containerId: googleTagManagerContainerId
    }));
}

export const analytics = Analytics({
    app: 'FritidsbåtApp',
    version: '0.1',
    plugins: plugins
});
