import { Action } from "redux";
import AuthState from "../interfaces/states/AuthState";
import { AuthAction } from "../actions/auth";
import { updateState } from "../../utils/miscUtilities";
import {analytics, AnalyticsEvent} from "../../components/analytics";

const initialState: AuthState = {
    waitingForAuthStatus: true
};

// Use the initialState as a default value
export default function data(
    state = initialState,
    action: Action
) {
    switch (action.type) {
        case AuthAction.AuthCheckRequest: {
            return updateState<AuthState>(state, {
                waitingForAuthStatus: true,
            });
        }
        case AuthAction.AuthCheckSuccess: {
            return updateState<AuthState>(state, {
                waitingForAuthStatus: false,
                authenticated: true,
            });
        }
        case AuthAction.AuthCheckFailure: {
            const { errorStatus } = action as any;
            analytics.track(AnalyticsEvent.LoginFailed).catch(console.error);
            return updateState<AuthState>(state, {
                waitingForAuthStatus: false,
                authenticated: false,
                errorStatus: errorStatus
            });
        }
        case AuthAction.LogoutSuccess: {
            analytics.track(AnalyticsEvent.LoginFailed).catch(console.error);
            return updateState<AuthState>(state, {
                waitingForAuthStatus: false,
                authenticated: false,
            });
        }
        default:
            return state;
    }
}
