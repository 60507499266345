import React, {useEffect, useMemo, useState} from "react";
import {Button, Checkbox, Form, Modal, Radio, Select, Tooltip} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExplosion} from "@fortawesome/free-solid-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {DimensionId, LayerType} from "../../constants/enums";
import {DimensionType} from "../../interfaces/Config";
import {useDimensionOptions} from "../../utils/hooks";
import {useDispatch, useSelector} from "react-redux";
import {getAccidentLayerConfig} from "../../store/selectors/user";
import {updateAccidentLayerConfig} from "../../store/actions/user";

import className from "../../assets/scss/components/map.scss";

const AGGREGATION_TYPE_OPTIONS = [
  { value: LayerType.Counties, label: 'Fylker' },
  { value: LayerType.HeatMap, label: 'Heatmap' },
];

const COUNT_AGGREGATION: { value: DimensionId|null, label: string } = {
  value: null,
  label: 'Antall',
};

function allowNormalize(
  layerType: LayerType,
  aggregationValueDimensionId: DimensionId|null
): boolean {
  return layerType !== LayerType.HeatMap && layerType !== LayerType.ClusteredPoints && !!aggregationValueDimensionId;
}

interface PopoverContentProperties {
  onSubmit: () => void;
}

function AccidentControlPopoverContent(
  props: PopoverContentProperties
) {

  const {
    onSubmit
  } = props;

  const dispatch = useDispatch();

  const {
    layerType: initialLayerType,
    normalized: initialNormalized,
    aggregationValueDimensionId: initialAggregationDimensionId
  } = useSelector(getAccidentLayerConfig);

  const [layerType, setLayerType] = useState(initialLayerType);
  const [isAggregated, setIsAggregated] = useState(layerType !== LayerType.ClusteredPoints);
  const [aggregationLayer, setAggregationLayer] = useState(
    layerType === LayerType.ClusteredPoints ? LayerType.Counties : layerType);
  const [aggregationValueDimensionId, setAggregationValueDimensionId] = useState(initialAggregationDimensionId);
  const [normalized, setNormalized] = useState(initialNormalized);
  const showNormalizeItem = allowNormalize(layerType, aggregationValueDimensionId);

  const [form] = Form.useForm();
  const numericalDimensionOptions = useDimensionOptions({
    type: [DimensionType.Numerical]
  }) as (typeof COUNT_AGGREGATION)[];
  const aggregationTypeOptions = useMemo(
    () => [COUNT_AGGREGATION].concat(numericalDimensionOptions),
    [numericalDimensionOptions]);

  useEffect(() => {
    if (isAggregated) {
      if (aggregationLayer) {
        setLayerType(aggregationLayer);
      }
    } else {
      setLayerType(LayerType.ClusteredPoints);
    }
  }, [isAggregated, aggregationLayer]);

  useEffect(() => {
    if (!showNormalizeItem) {
      setNormalized(false);
    }
  }, [layerType, aggregationValueDimensionId, showNormalizeItem])

  return (
    <Form
      className={className.accidentControl}
      form={form}>
      <Form.Item>
        <Radio.Group
          className={className.radioGroup}
          buttonStyle="solid"
          defaultValue={0}
          value={isAggregated}
          size="middle"
          onChange={v => setIsAggregated(v.target.value)}>
          <Radio.Button className={className.radioButton} value={false}>Punkter</Radio.Button>
          <Radio.Button className={className.radioButton} value={true}>Aggregert</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        rules={[{ required: true }]}>
        <Select
          disabled={!isAggregated}
          value={aggregationLayer}
          options={AGGREGATION_TYPE_OPTIONS}
          onChange={setAggregationLayer} />
      </Form.Item>
      <Form.Item
        className={showNormalizeItem ? className.marginBottom8 : undefined}
        rules={[{ required: true }]}>
        <Select
          disabled={!isAggregated}
          value={aggregationValueDimensionId}
          options={aggregationTypeOptions}
          onChange={setAggregationValueDimensionId} />
      </Form.Item>
      {
        showNormalizeItem ? (
          <Form.Item
            className={className.marginBottom8}
            label=""
            rules={[{ required: true }]}>
            <Checkbox checked={normalized} onChange={e => setNormalized(e.target.checked)}>
              Normaliser
            </Checkbox>
          </Form.Item>
        ) : <></>
      }
      <Form.Item
        className={className.lastFormItem}
        rules={[{ required: true }]}>
        <Button
          onClick={() => {
            updateAccidentLayerConfig({
              layerType,
              aggregationValueDimensionId,
              normalized
            })(dispatch);
            onSubmit();
          }}
          className={className.updateButton}
          type="default">
          Oppdater
        </Button>
      </Form.Item>
    </Form>
  )
}

export default function AntDAccidentControl() {

  const [accidentModalOpen, setAccidentModalOpen] = useState(false);
  const closeModal = () => setAccidentModalOpen(false);
  return (
    <>
      <Tooltip
        placement="top"
        title="Ulykker">
        <Button
          onClick={() => setAccidentModalOpen(true)}
          type="default"
          icon={<FontAwesomeIcon icon={faExplosion as IconProp} />}
          size="large" />
      </Tooltip>
      <Modal
        open={accidentModalOpen}
        onCancel={closeModal}
        destroyOnClose={true}
        width={250}
        footer={false} >
        <AccidentControlPopoverContent onSubmit={closeModal} />
      </Modal>
    </>
  );
}

