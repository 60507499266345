import Build from "../interfaces/Build";
import Injectable from "../injection/injectable";
import AntDTextFilter from "../components/dataviews/AntDTextFilter";
import FilterGrid from "../components/ui/FilterGrid";
import AntDLayerSwitcherControl from "../components/ui/AntDLayerSwitcherControl";
import PermissionWrapper from "../components/ui/PermissionWrapper";
import TabPane from "../components/ui/TabPane";
import AntDUserMenu from "../components/ui/AntDUserMenu";
import DataProvider from "../components/datasource/DataProvider";
import AntDDownloadDataControl from "../components/ui/AntDDownloadDataControl";
import {StatisticEditor} from "../components/statisticViewMaker/StatisticEditor";
import StatisticsTab from "../components/ui/StatisticsTab";
import AntDFilterListControl from "../components/ui/AntDFilterListControl";
import {TrendRenderer} from "../components/statisticViewMaker/TrendRenderer";
import SharedLayout from "../components/ui/SharedLayout";
import FilterView from "../components/ui/FilterView";
import AntDTabBar from "../components/ui/AntDTabBar";
import {ComponentType} from "../constants/enums";
import AntDMessenger from "../components/message/AntDMessenger";
import AntDAddedFilters from "../components/ui/AntDAddedFilters";
import AntDToolbar from "../components/ui/AntDToolbar";
import DataSourceProvider from "../components/datasource/DataSourceProvider";
import StatisticViewMaker from "../components/statisticViewMaker";
import CategoricalRenderer from "../components/statisticViewMaker/CategoricalRenderer";
import {TrendEditor} from "../components/statisticViewMaker/TrendEditor";
import CrossfilterDataSource from "../components/datasource/CrossfilterDataSource";
import AntDAccidentControl from "../components/ui/AntDAccidentControl";
import RendererWrapper from "../components/statisticViewMaker/RendererWrapper";
import ClientImplementation from "../components/auth/ClientImplementation";
import AntDDataPointInfoModal from "../components/ui/AntDDataPointInfoModal";
import AntDAddNewAccidentModal from "../components/ui/AntDAddNewAccidentModal";
import {OlCoordinatesMap} from "../components/dataviews/OlCoordinatesMap";
import {CategoricalEditor} from "../components/statisticViewMaker/CategoricalEditor";
import OlMainMap from "../components/dataviews/map/OlMainMap";
import AntDMapFilterControl from "../components/ui/AntDMapFilterControl";
import AntDAccidentDataTable from "../components/ui/AntDAccidentDataTable";
import {PreviewArea} from "../components/statisticViewMaker/PreivewArea";
import ComponentRendererContainer from "../components/statisticViewMaker/ComponentRendererContainer";
import {FilterRenderer} from "../components/ui/FilterRenderer";
import StatisticRenderer from "../components/statisticViewMaker/StatisticsRenderer";
import AntDLogin from "../components/ui/AntDLogin";
import {analytics} from "../components/analytics";
import CheckAuthWrapper from "../components/ui/CheckAuthWrapper";
import AddOrEditComponentModal from "../components/statisticViewMaker/AddOrEditComponentModal";
import ThemedScrollArea from "../components/ui/ThemedScrollArea";
import AntDGradientControl from "../components/ui/AntDGradientControl";
import AntDPopoverWrapper from "../components/ui/AntDPopoverWrapper";
import RedactedView from "../components/dataviews/RedactedView";
import MasonryDataExplorerTab from "../components/ui/MasonryDataExplorerTab";
import AntdEditableDataCard from "../components/dataviews/AntdDEditableDataCard";
import IncludeColumnsModal from "../components/ui/IncludeColumnsModal";
import MasonicDataExplorerTab from "../components/ui/MasonicDataExplorerTab";

export const build: Build = {
    singletons: {
        [Injectable.DataProvider]: DataProvider,
        [Injectable.Client]: ClientImplementation,
    },
    constructors: {
        [Injectable.DataSource]: CrossfilterDataSource,
        [Injectable.Messenger]: AntDMessenger
    },
    components: {
        [Injectable.AccidentDataTable]: AntDAccidentDataTable,
        [Injectable.SharedLayout]: SharedLayout,
        [Injectable.AddedFilters]: AntDAddedFilters,
        [Injectable.DataExplorerTab]: MasonicDataExplorerTab,
        [Injectable.FilterGrid]: FilterGrid,
        [Injectable.MainMap]: OlMainMap,
        [Injectable.CoordinatesMap]: OlCoordinatesMap,
        [Injectable.AddAccidentMap]: OlMainMap,
        [Injectable.PopoverWrapper]: AntDPopoverWrapper,
        [Injectable.TabBar]: AntDTabBar,
        [Injectable.FilterListControl]: AntDFilterListControl,
        [Injectable.MapFilterControl]: AntDMapFilterControl,
        [Injectable.LayerSwitcherControl]: AntDLayerSwitcherControl,
        [Injectable.DownloadDataControl]: AntDDownloadDataControl,
        [Injectable.AccidentControl]: AntDAccidentControl,
        [Injectable.TabPane]: TabPane,
        [Injectable.Toolbar]: AntDToolbar,
        [Injectable.DataCard]: AntdEditableDataCard,
        [Injectable.DataPointInfoModal]: AntDDataPointInfoModal,
        [Injectable.AddNewAccidentModal]: AntDAddNewAccidentModal,
        [Injectable.Login]: AntDLogin,
        [Injectable.UserMenu]: AntDUserMenu,
        [Injectable.TextFilter]: AntDTextFilter,
        [Injectable.PermissionWrapper]: PermissionWrapper,
        [Injectable.CheckAuthWrapper]: CheckAuthWrapper,
        [Injectable.DataSourceProvider]: DataSourceProvider,
        [Injectable.StatisticViewMaker]: StatisticViewMaker,
        [Injectable.FilterView]: FilterView,
        [Injectable.AddComponentModal]: AddOrEditComponentModal,
        [Injectable.RendererWrapper]: RendererWrapper,
        [Injectable.PreviewArea]: PreviewArea,
        [Injectable.ComponentRendererContainer]: ComponentRendererContainer,
        [Injectable.FilterRenderer]: FilterRenderer,
        [Injectable.StatisticsTab]: StatisticsTab,
        [Injectable.ScrollArea]: ThemedScrollArea,
        [Injectable.GradientControl]: AntDGradientControl,
        [Injectable.RedactedView]: RedactedView,
        [Injectable.IncludeColumnsModal]: IncludeColumnsModal,
    },
    factories: {
    },
    constants: {
        [Injectable.Analytics]: analytics
    },
    statisticComponents: {
        [ComponentType.Trend]: {
            type: ComponentType.Trend,
            label: 'Trend diagram',
            editor: TrendEditor,
            renderer: TrendRenderer
        },
        [ComponentType.Statistic]: {
            type: ComponentType.Statistic,
            label: 'Statistikk',
            editor: StatisticEditor,
            renderer: StatisticRenderer
        },
        [ComponentType.Categorical]: {
            type: ComponentType.Categorical,
            label: 'Kategorisk diagram',
            editor: CategoricalEditor,
            renderer: CategoricalRenderer
        }
    }
}

export default build;
