import React from "react";
import {useInjection} from "inversify-react";
import {MapControlComponent} from "../../constants/globalTypes";
import Injectable from "../../injection/injectable";
import {Button} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import GlobalState from "../../store/interfaces/states/GlobalState";
import {bindActionCreators, Dispatch} from "redux";
import {connect, ConnectedProps} from "react-redux";
import {setSelectionType} from "../../store/actions/data";
import {downloadData} from "../../utils/backendUtilities";
import Messenger from "../../interfaces/Messenger";
import {Message} from "../../constants/enums";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

function AntDDownloadDataControl(props: PropsFromRedux) {
    const MapControl = useInjection<MapControlComponent>(Injectable.PopoverWrapper);
    const messenger = useInjection<Messenger>(Injectable.Messenger);
    const element = <Button
                        type="default"
                        icon={<FontAwesomeIcon icon={faDownload as IconProp} />}
                        size="large" />;
    return (
        <MapControl
            element={element}
            tooltipTitle="Last ned data"
            popoverItems={[
                {
                    label: "Alle datapunkter",
                    onClick: () => messenger.async(downloadData(), Message.DataDownloading)
                },
                {
                    label: "Filtrerte datapunkter",
                    onClick: () => messenger.async(downloadData(), Message.DataDownloading)
                },
            ]}/>
    );
}

function mapStateToProps(state: GlobalState) {
    const { selectionType } = state.data;
    const { authenticated } = state.auth;
    return { selectionType, authenticated };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators({
        activeSelection: setSelectionType
    }, dispatch)
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(AntDDownloadDataControl);

