import React, {memo, useMemo} from "react";
import {Handle, NodeProps, Position} from "reactflow";
import {ResultNodeData} from "../types";
import {useRiskModelContext} from "./RiskModelViewer";
import Box from "./Box";

import className from "../../../assets/scss/components/riskmodel.scss";

const ResultNode = memo((
    props: NodeProps<ResultNodeData>
) => {
    const {
        isConnectable
    } = props;
    const {
        network
    } = useRiskModelContext()

    const value = network.getResult() || 0;
    const { comparisonValues } = useRiskModelContext();
    const comparisonValue = useMemo(
        () => comparisonValues ? comparisonValues.result : undefined, [comparisonValues]);

    return (
        <div className={className.resultNode}>
            <Box
                label={network.resultNode.label}
                value={value}
                comparisonValue={comparisonValue}>
                <Handle
                    type="target"
                    position={Position.Left}
                    id="in"
                    style={{ background: 'black' }}
                    isConnectable={isConnectable}
                />
            </Box>
        </div>
    )
});

export default ResultNode;
