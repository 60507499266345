import {container} from "../../injection";
import Injectable from "../../injection/injectable";
import Client from "../../interfaces/Client";
import {AxiosResponse} from "axios";

export function authCheckAsync(): Promise<any> {
  const client = container.get<Client>(Injectable.Client);
  return client.get({
    apiEndpoint: 'auth/user_info',
    withCredentials: true
  })
    .then(({
             data = {}
    }) => {
      return data;
    });
}

export function logoutAsync(): Promise<AxiosResponse> {
  const client = container.get<Client>(Injectable.Client);
  return client.get({
    apiEndpoint: 'auth/logout',
    withCredentials: true
  })
}
