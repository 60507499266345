import {faFilter, faGlobeEurope, faThLarge, faChartLine} from "@fortawesome/free-solid-svg-icons";
import {TabDefinition} from "./globalTypes";

export default class Tab {
    public static Data: TabDefinition = {
        name: "Data",
        icon: faThLarge,
        closable: false
    };
    public static Map: TabDefinition = {
        name: "Kart",
        icon: faGlobeEurope,
        closable: false
    };
    public static Filter: TabDefinition = {
        name: "Filtre",
        icon: faFilter,
        closable: false
    };
  static Statistics: TabDefinition = {
      name: "Statistikk",
      icon: faChartLine,
  }
};
