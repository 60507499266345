import * as d3 from "d3";
import {Aggregation, Chart, DimensionTransform, Unit} from "../../../../constants/enums";
import {DimensionType} from "../../../../interfaces/Config";

export const COLORS = d3.schemeSet3;
export const HEATMAP_SCHEME = [
    "#2c003e",
    "#6a003f",
    "#9c0040",
    "#cf0041",
    "#ff0052",
    "#ff6347",
    "#ffb347",
    "#fddc56",
];
export const ALLOW_MULTIPLE_CHARTS = [Chart.Bar, Chart.Line];
export const Y_AXIS_CHARTS = [Chart.Bar, Chart.Line, Chart.Heat, Chart.Bubble];
export const VALUE_CHARTS = [Chart.Pie, Chart.Heat, Chart.Bubble];
export const GROUP_BY_CHARTS = [Chart.Bar, Chart.Line, Chart.Pie, Chart.Bubble, Chart.Scatter];
export const DATE_UNITS = [Unit.Date, Unit.Year, Unit.Weeks, Unit.Months];
export const NUMERICAL_UNITS = [Unit.Integer, Unit.Float];

export const ALL_DIMENSION_TYPES = [
    DimensionType.Date,
    DimensionType.Categorical,
    DimensionType.Numerical,
    DimensionType.GeoCategorical
]

export const UNITS = [
    { label: 'Dag', value: Unit.Date },
    { label: 'Måneder', value: Unit.Months },
    { label: 'Uker', value: Unit.Weeks },
    { label: 'År', value: Unit.Year },
    { label: 'Flyttall', value: Unit.Integer },
    { label: 'Heltall', value: Unit.Float },
];

export const DIMENSION_TRANSFORM_OPTIONS = [
    {label: 'Dato', value: DimensionTransform.Date},
    {label: 'Måned', value: DimensionTransform.Months},
    {label: 'Uke', value: DimensionTransform.Weeks},
    {label: 'År', value: DimensionTransform.Year},
];

export const AGGREGATION_OPTIONS = [
    {value: Aggregation.Count, label: 'Antall'},
    {value: Aggregation.Sum, label: 'Sum'},
    {value: Aggregation.Average, label: 'Gjennomsnitt'},
    {value: Aggregation.Max, label: 'Maximum'},
    {value: Aggregation.Min, label: 'Minimum'},
    {value: Aggregation.StandardDeviation, label: 'Standardavvik'},
];