import React, {useRef} from 'react';
import SplitPane from "react-split-pane";
import {useInjection} from "inversify-react";
import Injectable from "../../injection/injectable";
import {
    MainMapComponent,
    PermissionWrapperComponent,
    FunctionalComponent,
    DataPointInfoModalComponent,
    StatisticsTabComponent
} from "../../constants/globalTypes";
import Tab from "../../constants/tabs";
import {theme} from "antd";
import {useSelector} from "react-redux";
import {getNightMode} from "../../store/selectors/user";
import {useActions} from "../../utils/hooks";
import {setDragging} from "../../store/actions/layout";
import {setMapSize} from "../../store/actions/user";
import GlobalState from "../../store/interfaces/states/GlobalState";
import TabComponentProperties from "../../interfaces/properties/TabComponentProperties";
import {injectReactComponent} from "../../utils/injectionUtilities";

// @ts-ignore
import className from "../../assets/scss/home.scss";

export default function Home() {

    const { token } = theme.useToken();
    const nightMode = useSelector(getNightMode);
    const ref = useRef<SplitPane>(null);
    const actions = useActions({ setDragging, setMapSize });

    const TabPane = useInjection<React.FunctionComponent<any>>(Injectable.TabPane);
    const AddedFilters = useInjection<React.FunctionComponent>(Injectable.AddedFilters);
    const Map = useInjection<MainMapComponent>(Injectable.MainMap);
    const DataExplorerTab = injectReactComponent<TabComponentProperties>(Injectable.DataExplorerTab);
    const StatisticsTab = useInjection<StatisticsTabComponent>(Injectable.StatisticsTab);
    const FilterControl = useInjection<FunctionalComponent>(Injectable.FilterListControl);
    const SelectionControl = useInjection<FunctionalComponent>(Injectable.MapFilterControl);
    const LayerSwitcherControl = useInjection<FunctionalComponent>(Injectable.LayerSwitcherControl);
    const DownloadDataControl = useInjection<FunctionalComponent>(Injectable.DownloadDataControl);
    const AddNewAccidentModal = useInjection<FunctionalComponent>(Injectable.AddNewAccidentModal);
    const DataSourceProvider = useInjection<FunctionalComponent>(Injectable.DataSourceProvider);
    const DataPointInfoModal = useInjection<DataPointInfoModalComponent>(Injectable.DataPointInfoModal);
    const FilterView = useInjection<PermissionWrapperComponent>(Injectable.FilterView);
    const CheckAuthWrapper = useInjection<FunctionalComponent>(Injectable.CheckAuthWrapper);

    const width = useSelector((state: GlobalState) => state.user.data.mapPaneSize)

    return (
      <CheckAuthWrapper>
          <DataSourceProvider
            active={true}>
              <FilterView>
                  {
                      // @ts-ignore
                      <SplitPane
                        ref={ref}
                        resizerStyle={{
                            background: token.colorBorderSecondary
                        }}
                        onDragStarted={() => actions.setDragging(true)}
                        onDragFinished={size => {
                            if (ref) {
                                actions.setDragging(false);
                                actions.setMapSize(size);
                            }
                        }}
                        split="vertical"
                        defaultSize={width}
                        minSize={100}
                        className={className.splitPane}>
                          <Map
                            tab={Tab.Map}
                            nightMode={nightMode}
                            topLeftControls={[
                                <FilterControl />
                            ]}
                            topRightControls={[
                                <SelectionControl key={0} />,
                                <LayerSwitcherControl key={1} />,
                                <DownloadDataControl key={2} />,
                            ]} />
                          <TabPane
                            id="right"
                            forceShowTabs={true}
                            header={<AddedFilters />} >
                              [
                              <DataExplorerTab
                                tab={Tab.Data} />,
                              <StatisticsTab
                                extraContent={(
                                  <>
                                  </>
                                )}
                                tab={Tab.Statistics} />
                              ]
                          </TabPane>
                      </SplitPane>
                  }
              </FilterView>
              <DataPointInfoModal />
              <AddNewAccidentModal />
          </DataSourceProvider>
      </CheckAuthWrapper>
    );
}
