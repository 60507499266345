import {
  ComparatorValue,
  Filter,
  FilterFunction,
  FilterWithSelector,
  GeoFilterValue,
  SimpleFilter
} from "../constants/globalTypes";
import dayjs from "dayjs";
import {DimensionId, FilterType, GeoFilterType, Operator} from "../constants/enums";
import _ from "lodash";
import {DimensionType} from "../interfaces/Config";
import {alwaysTrue} from "./miscUtilities";
import {isFilter, isSimpleFilter, isStringArray} from "./validationUtilities";
import {v4 as uuid} from "uuid";
import {useFilterLabel} from "./hooks";
import {DEFAULT_DESCRIPTION_FILTER, DEFAULT_GEO_FILTER} from "../constants/defaults";
import {fromLonLat, toLonLat} from "ol/proj";
import {OPERATORS} from "../constants/filter";
import {Dimension} from "crossfilter2";

function createDateFilterFunction(
  filter: Filter
) {
  const { operator } = filter;
  const timestamp = filter.comparatorValue as number;
  const comparatorDate = timestamp && dayjs.unix(timestamp);
  switch (operator) {
    case Operator.LesserThan:
      return (v: ComparatorValue) => dayjs(v as string).isBefore(comparatorDate);
    case Operator.LesserThanOrEqual:
      return (v: ComparatorValue) => dayjs(v as string).isSameOrBefore(comparatorDate);
    case Operator.Equal:
      return (v: ComparatorValue) => dayjs(v as string).isSame(comparatorDate);
    case Operator.NotEqual:
      return (v: ComparatorValue) => !dayjs(v as string).isSame(comparatorDate);
    case Operator.GreaterThan:
      return (v: ComparatorValue) => dayjs(v as string).isAfter(comparatorDate);
    case Operator.GreaterThanOrEqual:
      return (v: ComparatorValue) => dayjs(v as string).isSameOrAfter(comparatorDate);
  }
  return alwaysTrue;
}

function createFilterFunctionFromValuesAndOperator<T>(
  filter: Filter,
  comparatorValues: ComparatorValue[],
  transform: (ComparatorValue: any) => ComparatorValue = (value: ComparatorValue) => value
) {
  const { operator } = filter;
  comparatorValues = comparatorValues.map(transform);
  switch (operator) {
    case Operator.LesserThan:
      return (value: ComparatorValue|ComparatorValue[]) => transform(value) < comparatorValues[0];
    case Operator.LesserThanOrEqual:
      return (value: ComparatorValue|ComparatorValue[]) => transform(value) <= comparatorValues[0];
    case Operator.GreaterThan:
      return (value: ComparatorValue|ComparatorValue[]) => transform(value) > comparatorValues[0];
    case Operator.GreaterThanOrEqual:
      return (value: ComparatorValue|ComparatorValue[]) => {
        return transform(value) >= comparatorValues[0];
      };
    case Operator.Equal:
      return (value: ComparatorValue|ComparatorValue[]) => {
        return _.isArray(value)
          ? value.every((v: any) => comparatorValues.includes(v))
          : comparatorValues.includes(value);
      };
    case Operator.NotEqual:
      return (value: ComparatorValue|ComparatorValue[]) => {
        return _.isArray(value)
          ? !value.some((v: any) => comparatorValues.includes(v))
          : !comparatorValues.includes(value);
      };
  }
  return alwaysTrue;
}

function createTextFilterFunction(
  filter: Filter
) {
  const { comparatorValue } = filter;
  if (_.isString(comparatorValue)) {
    return (text: ComparatorValue) => (text as string).toLowerCase().includes(comparatorValue.toLowerCase());
  }
  return alwaysTrue;
}

export function filterDimension(
    dimension: Dimension<any, any>,
    filter: Filter|Filter[]
) {
  const filterFunction = createFilterFunctionFromFilters(filter);
  if (_.isFunction(dimension.filterFunction)) {
    dimension.filterFunction(filterFunction);
  }
}

export function createFilterFunctionFromFilter(
  filter: Filter
): FilterFunction {
  let { type, comparatorValue } = filter;
  const comparatorValues = _.isArray(comparatorValue)
    ? comparatorValue
    : _.isUndefined(comparatorValue) ? [] : [comparatorValue];
  switch (type) {
    case FilterType.Text:
      return createTextFilterFunction(filter);
    case FilterType.Numerical:
      return createFilterFunctionFromValuesAndOperator(filter, comparatorValues);
    case FilterType.Categorical:
      return createFilterFunctionFromValuesAndOperator(filter, comparatorValues);
    case FilterType.Date:
      return createDateFilterFunction(filter);
  }
  return alwaysTrue;
}

export function isActiveFilter(
  filter: Filter|SimpleFilter
) {
  if (isFilter(filter)) {
    return _.isNumber(filter.comparatorValue)
      || _.isString(filter.comparatorValue) && filter.comparatorValue.length > 0
      || (_.isArray(filter.comparatorValue) && !_.isEmpty(filter.comparatorValue));
  } else if (isSimpleFilter(filter)) {
    return !_.isUndefined(filter.settings);
  }
  return false;
}

function combineFilterFunctions(filterFunctions: FilterFunction[]) {
  return (value: ComparatorValue) => {
    for (const filterFunction of filterFunctions) {
      if (!filterFunction(value)) {
        return false;
      }
    }
    return true;
  }
}

export function createFilterFunctionFromFilters(
    filters: Filter|Filter[]
): (dataPoint: any) => boolean {
  const filterFunctions: FilterFunction[] = _.castArray(filters).map(createFilterFunctionFromFilter);
  return filterFunctions.length > 0
      ? combineFilterFunctions(filterFunctions)
      : alwaysTrue;
}


export function isMultipleOperator(operator?: Operator) {
  return operator == Operator.NotEqual || operator == Operator.Equal;
}

export function operatorsForType(
  type: FilterType
): [Operator, string][] {
  switch (type) {
    case FilterType.Categorical:
      return [Operator.Equal, Operator.NotEqual].map(v => [v, OPERATORS[v]]);
    case FilterType.Text:
      return [Operator.In].map(v => [v, OPERATORS[v]]);
    case FilterType.Numerical:
    case FilterType.Date:
    default: {
      const nonTextOperators = _.omit(OPERATORS, [Operator.In]);
      return _.entries(nonTextOperators) as any as [Operator, any][];
    }
  }
}

export function getFilterTypeFromDimensionType(dimensionType: DimensionType) {
  switch (dimensionType) {
    case DimensionType.Date:
      return FilterType.Date;
    case DimensionType.Numerical:
      return FilterType.Numerical;
    case DimensionType.Categorical:
    case DimensionType.GeoCategorical:
      return FilterType.Categorical;
  }
}

export function getDefaultOperatorForFilterType(
  filterType: FilterType
) {
  return _.isNumber(filterType) && Operator.Equal;
}

export function isFilterValid(filter: Partial<FilterWithSelector>) {
  console.log('validating filter', filter);
  return !_.isUndefined(filter.type)
    && !_.isUndefined(filter.dimensionId)
    && !!filter.operator;
}

export function getLabelTextForFilter(
  filter: Filter|SimpleFilter,
  maxLength: number,
  comparatorValue?: ComparatorValue,
) {
  const label = useFilterLabel(filter);
  if (isSimpleFilter(filter)) {
    return label;
  } else if (isFilter(filter)) {
    if (comparatorValue) {
      return `${label} ${OPERATORS[filter.operator]} ${comparatorValue}`;
    } else if (filter.type === FilterType.Text) {
      return `"${filter.comparatorValue}" i ${label}`;
    } else if (filter.type === FilterType.Date) {
      const dateString = dayjs.unix(filter.comparatorValue as number).format('MM/DD/YYYY');
      return `${label} ${OPERATORS[filter.operator]} ${dateString}`;
    } else if (isStringArray(filter.comparatorValue)) {
      const comparatorValueCount = filter.comparatorValue.length;
      const truncatedComparatorValues = filter.comparatorValue.map((v: any) => _.truncate(v, { length }))
      if (comparatorValueCount > 2) {
        let comparatorValueString = truncatedComparatorValues.slice(0, 2).toString();
        comparatorValueString += `+${comparatorValueCount - 2}`;
        return `${label} ${OPERATORS[filter.operator]} ${comparatorValueString}`;
      }
      return `${label} ${OPERATORS[filter.operator]} ${truncatedComparatorValues.toString()}`;
    } else {
      return `${label} ${OPERATORS[filter.operator]} ${filter.comparatorValue}`;
    }
  }
}

export function groupFiltersWithSameDimension(
  filters: Filter[]
) {
  const groupedFilters: Filter[][] = [];
  let currentFilterGroup: Filter[] = [];
  for (const filter of filters) {
    if (currentFilterGroup.length == 0 || currentFilterGroup[0].dimensionId === filter.dimensionId) {
      currentFilterGroup.push(filter);
    } else {
      if (!_.isEmpty(currentFilterGroup)) {
        groupedFilters.push(currentFilterGroup);
      }
      currentFilterGroup = [filter];
    }
  }
  if (!_.isEmpty(currentFilterGroup)) {
    groupedFilters.push(currentFilterGroup);
  }
  return groupedFilters;
}

export function shouldIncludeInFilterBar(
  filter: Filter|SimpleFilter
) {
  return filter.shouldIncludeInFilterView !== false;
}

export function serializeFilters(filters: (Filter|SimpleFilter)[]) {
  return filters
    .filter(isActiveFilter)
    .map(filter => {
      if (isFilter(filter) && filter.comparatorValue) {
        const { operator, comparatorValue, dimensionId, type } = filter;
        return `${type}|${operator}|${comparatorValue.toString()}|${dimensionId}`;
      } else if (isSimpleFilter(filter) && filter.settings) {
        switch (filter.type) {
          case FilterType.Geo:
            const { type } = filter;
            const { type: geoType, value } = filter.settings as GeoFilterValue;
            if (geoType === GeoFilterType.Polygon) {
              const latLonValue = (value as number[][]).map(v => toLonLat(v).map(n => _.round(n, 2)));
              return `${type}|${geoType}|${latLonValue.toString()}`;
            }
            if (geoType === GeoFilterType.Extent) {
              const latLonValue = (value as any).map((n: number) => _.round(n, 2));
              return `${type}|${geoType}|${latLonValue.toString()}`;
            }
            return `${type}|${geoType}|${value.toString()}`;
        }
      }
    })
    .filter(_.isString)
    .join(";");
}

function createFilterFromSerializedData(
  type: number,
  filterData: string[],
  comparatorValue: any
) {
  const [, operator, , dimensionId] = filterData;
  return {
    filterId: uuid(),
    type,
    operator,
    comparatorValue,
    dimensionId
  };
}

function createGeoFilter(
  type: number,
  filterData: string[],
): SimpleFilter<GeoFilterValue> {
  const geoType = parseInt(filterData[1]);
  let value = filterData[2].split(',') as any[];
  if (geoType === GeoFilterType.Polygon ) {
    value = _.chunk(value.map(parseFloat), 2).map(v => fromLonLat(v));
  }
  if (geoType === GeoFilterType.Extent) {
    value = value.map(parseFloat);
  }
  const { filterId, label } = DEFAULT_GEO_FILTER;
  return {
    type,
    filterId,
    label,
    settings: {
      type: geoType,
      value
    }
  }
}

export function deserializeFilters(
  filtersString: string
): (Filter|SimpleFilter)[] {
  return filtersString.split(';').map(filterString => {
    const filterData = filterString.split('|');
    const type = parseInt(filterData[0])
    switch (type) {
      case FilterType.Geo: {
        return createGeoFilter(type, filterData);
      }
      case FilterType.Categorical: {
        const comparatorValue = filterData[2].split(',');
        return createFilterFromSerializedData(type, filterData, comparatorValue);
      }
      case FilterType.Numerical:
      case FilterType.Date: {
        const comparatorValue = parseFloat(filterData[2]);
        return createFilterFromSerializedData(type, filterData, comparatorValue);
      }
      case FilterType.Text: {
        const comparatorValue = filterData[2];
        return createFilterFromSerializedData(type, filterData, comparatorValue);
      }
    }
  }) as (Filter|SimpleFilter)[];
}

function hasGeoFilter(
  filters: (Filter|SimpleFilter)[]
) {
  return filters.some(f => f.type === FilterType.Geo);
}

function hasDescriptionFilter(
  filters: (Filter|SimpleFilter)[]
) {
  return filters.some(f => isFilter(f) && f.dimensionId === DimensionId.Description);
}

export function ensureFiltersIncludePredefinedFilters(
  filters: (Filter|SimpleFilter)[]
) {
  const additionalFilters = [];
  if (!hasGeoFilter(filters)) {
    additionalFilters.push(DEFAULT_GEO_FILTER);
  }
  if (!hasDescriptionFilter(filters)) {
    additionalFilters.push(DEFAULT_DESCRIPTION_FILTER);
  }
  return filters.concat(additionalFilters)
}

export function initializeFiltersFromUrlParameters(): (Filter|SimpleFilter)[] {
  const urlParameters = new URLSearchParams(window.location.search);
  const filtersString = urlParameters.get('f');
  if (filtersString) {
    const filters = deserializeFilters(filtersString);
    return ensureFiltersIncludePredefinedFilters(filters);
  }
  return [
    DEFAULT_GEO_FILTER,
    DEFAULT_DESCRIPTION_FILTER
  ];
}
