import Network from "../models/Network";
import NodeGroup from "../models/NodeGroup";
import {Node as ReactFlowNode, Edge as ReactFlowEdge, XYPosition} from "reactflow";
import objectPath from "object-path";
import {RESULT_NODE_ID} from "../constants";
import hash from "object-hash";
import {useCallback, useEffect, useMemo, useState} from "react";
import {GroupNodeData} from "../types";
import {useImmer} from "use-immer";
import Node from "../models/Node";
import {NodeType} from "../enums";
import StatisticDefinition from "../interfaces/StatisticDefinition";
import {groupFilters, operatorsForType} from "../../filterRenderer/utilities/helpers";
import {v4 as uuid} from "uuid";
import _ from "lodash";
import {FilterType} from "../../filterRenderer/enums";
import {Filter} from "../../filterRenderer/types";
import {lookupFromArray} from "../../../utils/miscUtilities";

export function useNetworkAsFlow(
    network: Network,
    positionFromId: (id: string) => XYPosition
) {
    const [nodeValues, updateNodeValues] = useImmer<Record<string, number>>({});

    const updateValueForNode = useCallback((nodeId: string, nextValue: number) => updateNodeValues(draft => ({
        ...draft,
        [nodeId]: nextValue
    })), [updateNodeValues]);

    const {
        edges,
        nodes,
        result
    } = useMemo(() => {
        network.updateNodeValues(nodeValues);
        const groupByNode: Record<string, NodeGroup> = {}
        const flowNodes: ReactFlowNode<GroupNodeData|undefined>[] = [];
        network.nodeGroups?.forEach((nodeGroup, index) => {
            const { nodeIds, label, id } = nodeGroup;
            nodeIds.forEach(nodeId => groupByNode[nodeId] = nodeGroup);
            const hasIncomingEdges = nodeGroup.nodeIds.some(nodeId => network.hasIncomingEdges(nodeId));
            const hasOutingEdges = nodeGroup.nodeIds.some(nodeId => network.hasOutgoingEdges(nodeId));
            flowNodes.push({
                id: id,
                type: NodeType.Group,
                data: {
                    nodes: nodeIds.map(nodeId => network.getNode(nodeId).serialize()),
                    label: label,
                    hasIncomingEdges,
                    hasOutingEdges
                },
                position: positionFromId(id)
            });
        });
        flowNodes.push({
            id: RESULT_NODE_ID,
            type: NodeType.Result,
            data: undefined,
            position: positionFromId(RESULT_NODE_ID)
        });
        const edgeLookup = {};
        const flowEdges: ReactFlowEdge[] = [];
        network.edges.forEach(edge => {
            if (!!groupByNode[edge.sourceId] && (!!groupByNode[edge.targetId] || edge.targetId === RESULT_NODE_ID)) {
                const sourceNodegroupId = groupByNode[edge.sourceId].id;
                const targetNodeGroupId = edge.targetId === RESULT_NODE_ID
                    ? RESULT_NODE_ID
                    : groupByNode[edge.targetId].id;
                const hasEdge = objectPath.get(edgeLookup, [sourceNodegroupId, targetNodeGroupId], false);
                if (!hasEdge) {
                    objectPath.set(edgeLookup, [sourceNodegroupId, targetNodeGroupId], true);
                    flowEdges.push({
                        id: hash([sourceNodegroupId, targetNodeGroupId]),
                        source: sourceNodegroupId,
                        sourceHandle: 'out',
                        target: targetNodeGroupId,
                        targetHandle: 'in'
                    });
                }
            }
        });
        console.log('edgeLookup', edgeLookup);
        console.log('flowEdges', flowEdges);
        return {
            nodes: flowNodes,
            edges: flowEdges,
            result: network.execute()
        };
    }, [network.hash, nodeValues]);

    return {
        nodes,
        edges,
        updateValueForNode,
        result
    };
}


export function useFilters(
    statisticDefinitions: StatisticDefinition[],
) {
    const initialFilters = useMemo(() => {
        const filters = statisticDefinitions.flatMap(statisticDefinition => {
            const {
                filters,
                data
            } = statisticDefinition;
            return filters.map(({ column, type, label }) => {
                const possibleOperators = operatorsForType(type);
                return {
                    filterId: uuid(),
                    group: column,
                    type,
                    operator: possibleOperators[0][0],
                    label: label || _.startCase(column),
                    uniqueValues: type === FilterType.Categorical
                        ? _.uniq(data.map(d => d[column] as string))
                        : undefined
                }
            });
        });
        return groupFilters(filters).map(filterGroup => {
            const uniqueValues = _.uniq(filterGroup.flatMap(({ uniqueValues = [] }) => uniqueValues));
            return {
                ...filterGroup[0],
                uniqueValues
            }
        }) as Filter[];
    }, [statisticDefinitions]);

    const [filters, setFilters] = useState<Filter[]>(initialFilters);

    const onChangeFilter = useCallback((filter: Filter) => {
        const nextFilters = filters.slice();
        const index = filters.map(({ filterId }) => filterId).indexOf(filter.filterId);
        nextFilters[index] = filter;
        setFilters(nextFilters)
    }, [filters, setFilters]);

    const onDeleteFilter = useCallback((filter: Filter) => {
        const nextFilters = filters.filter(({ filterId }) => filterId !== filter.filterId);
        setFilters(nextFilters);
    }, [filters, setFilters]);

    const clearAllFilters = useCallback(() => {
        const nextFilters = filters.map(filter => ({
            ...filter,
            comparatorValue: undefined
        }));
        setFilters(nextFilters);
    }, [filters, setFilters]);

    const filterState = useMemo(
        () => lookupFromArray<Filter>(
            filters, ({group}) => group!
        ), [filters]);

    useEffect(() => setFilters(initialFilters), [initialFilters]);

    return {
        clearAllFilters,
        filters,
        onChangeFilter,
        onDeleteFilter,
        filterState
    }
}