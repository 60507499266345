import {RESULT_NODE_ID} from "../constants";

export default class ResultNode {
    private readonly _label: string;

    constructor(label: string) {
        this._label = label;
    }

    public get id(): string {
        return RESULT_NODE_ID;
    }

    public get label(): string {
        return this._label;
    }

}