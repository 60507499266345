import React from "react";
import { Menu } from "antd";
import { ConnectedProps, connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import GlobalState from "../../store/interfaces/states/GlobalState";
import * as authActions from "../../store/actions/auth";
import {analytics, AnalyticsEvent} from "../analytics";

function AntDUserMenu(props: PropsFromRedux) {
    const { logout } = props;
    return (
        <Menu
          style={{ padding: 0 }}
          items={[
              {
                  key: 1,
                  label: 'Logg ut',
                  onClick: () => {
                      logout();
                      analytics.track(AnalyticsEvent.LogoutClicked).catch(console.error);
                  }
              }
          ]}
        />
    );
}

function mapStateToProps(
    state: GlobalState
) {
    return {
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
    const { logout } = authActions;
    return bindActionCreators({
        logout
    }, dispatch)
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(AntDUserMenu);
