import {Dispatch} from "redux";
import {SetLayoutAlternativeAction} from "../interfaces/actions/layout";

export const enum LayoutAction {
    SetDragging = 'SET_DRAGGING'
}

export function setDragging(
    dragging: boolean
) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: LayoutAction.SetDragging,
            dragging
        } as SetLayoutAlternativeAction);
    };
}
