import dayjs from "dayjs";
import React, {useMemo, useRef} from "react";
import * as d3 from "d3";
import {UnitFunction} from "dc";
import {createTwoAxisChart} from "../../utils/chartsUtilities";
import {DimensionTransform, Unit} from "../../constants/enums";
import {SelectorFunction, Trend} from "../../constants/globalTypes";
import ComponentRendererProperties from "../../interfaces/properties/ComponentRendererProperties";
import Injectable from "../../injection/injectable";
import {RendererWrapperProperties} from "./RendererWrapper";
import {injectReactComponent} from "../../utils/injectionUtilities";
import {useComponentCreator, useDimension} from "../../utils/hooks";

function createDimensionFunction(trend: Partial<Trend>): SelectorFunction {
  switch (trend.xAxis) {
    case Unit.Date:
      return ({ date }) => dayjs(date);
    case Unit.Weeks:
      return ({ date }) => dayjs(date).week();
    case Unit.Months:
      return ({ date }) => dayjs(date).month();
    case Unit.Year:
    default:
      return ({ date }) => dayjs(date).year();
  }
}

function getXUnits(trend: Partial<Trend>): UnitFunction|undefined {
  if (trend.xAxis === Unit.Date) {
    switch (trend.resolution) {
      case DimensionTransform.Weeks:
        return d3.timeWeek as any as UnitFunction;
      case DimensionTransform.Months:
        return d3.timeMonth as any as UnitFunction;
      case DimensionTransform.Year:
        return d3.timeYear as any as UnitFunction;
      default:
      case DimensionTransform.Date:
        return d3.timeDays as any as UnitFunction;
    }
  }
}


export function TrendRenderer(props: ComponentRendererProperties<Trend>) {
  const ref = useRef<HTMLDivElement>(null);
  const { data: trend, width, height, valid, editing, isEditingExistingComponent } = props;
  const {
    xAxis,
    resolution,
    yAxisId,
    groupById,
    metric
  } = trend;

  const yAxis = useDimension(yAxisId);
  const groupBy = useDimension(groupById);

  const xUnits = useMemo(() => getXUnits(trend), [trend]);
  const [createChart, changed] = useComponentCreator<Trend>((crossfilter, trend) => {
    const dimensionFunction = createDimensionFunction(trend);
    const dimension = crossfilter.dimension(dimensionFunction);
    return createTwoAxisChart(trend.chartType!,
      {
        element: ref.current!,
        xAxis: xAxis!,
        yAxis: yAxis!,
        resolution: resolution!,
        groupBy: groupBy!,
        crossfilterDimension: dimension,
        metric,
        width,
        height,
        xUnits,
        crossfilter,
        editing
      });
  }, props);

  const RendererWrapper = injectReactComponent<RendererWrapperProperties>(Injectable.RendererWrapper);

  return (
    <RendererWrapper
      isEditingExistingComponent={isEditingExistingComponent}
      editing={editing}
      valid={valid}
      changed={changed}
      onRerender={createChart}>
      <div ref={ref} />
    </RendererWrapper>
  );

}
