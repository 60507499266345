import {injectReactComponent} from "../../utils/injectionUtilities";
import {RendererWrapperProperties} from "./RendererWrapper";
import Injectable from "../../injection/injectable";
import React, {useRef, useState} from "react";
import ComponentRendererProperties from "../../interfaces/properties/ComponentRendererProperties";
import {Statistic} from "../../constants/globalTypes";
import _ from "lodash";
import {Card} from "antd";
import {createNumberDisplay} from "../../utils/chartsUtilities";
import {useComponentCreator, useDimension} from "../../utils/hooks";

import className from "../../assets/scss/components/filtergrids.scss";

export default function StatisticRenderer(props: ComponentRendererProperties<Statistic>) {
  const ref = useRef<HTMLDivElement>(null);
  const [title, setTitle] = useState("");
  const [percentageOfTotal, setPercentageOfTotal] = useState(false);
  const [visible, setVisible] = useState(false);
  const {
    data: statistic,
    valid,
    editing,
    isEditingExistingComponent
  } = props;
  const {
    dimensionId
  } = statistic;
  const dimension = useDimension(dimensionId);
  const [createStatistic, changed] = useComponentCreator<Statistic>((crossfilter, ) => {
    const crossfilterDimension = crossfilter.dimension(d => d.id);
    const componentResizer = createNumberDisplay({
      element: ref.current!,
      dimension: dimension!,
      metric: statistic.metric!,
      percentageOfTotal: statistic.percentageOfTotal!,
      crossfilterDimension,
      crossfilter
    });
    setTitle(statistic.title!);
    setPercentageOfTotal(!!statistic.percentageOfTotal);
    setVisible(true);
    return componentResizer;
  }, props);
  const RendererWrapper = injectReactComponent<RendererWrapperProperties>(Injectable.RendererWrapper);
  return (
    <RendererWrapper
      isEditingExistingComponent={isEditingExistingComponent}
      editing={editing}
      valid={valid}
      changed={changed}
      onRerender={createStatistic}>
      <Card
        bordered={false}
        className={className.statisticCard}
        style={{
          display: visible ? 'flex' : 'none',
          background: !editing ? 'transparent' : undefined,
          width: editing ? 220 : undefined
        }}>
        <div className="ant-statistic">
          <div className="ant-statistic-title">
            {
              _.startCase(title)
            }
          </div>
          <div className="ant-statistic-content">
            <div className="ant-statistic-content-value">
              <div ref={ref} />
            </div>
            <div className="ant-statistic-content-suffix">
              { percentageOfTotal && "%" }
            </div>
          </div>
        </div>
      </Card>
    </RendererWrapper>
  );
}
