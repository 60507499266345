import {applyMiddleware, Middleware} from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas";

const REDUX_LOGGING = process.env.NODE_ENV === "development";

const sagaMiddleware = createSagaMiddleware();
const middleware: Middleware[] = [thunk, sagaMiddleware];

if (REDUX_LOGGING) {
    middleware.push(logger);
}

export const runSaga = () => sagaMiddleware.run(rootSaga);

export default applyMiddleware(...middleware);
