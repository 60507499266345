import React, {FC, useCallback, useEffect, useMemo, useState} from "react";
import StatisticsVisualizationPlugin, {RendererProperties} from "../interfaces/StatisticsVisualizationPlugin";
import StatisticDefinition from "../interfaces/StatisticDefinition";
import crossfilterFunc, {Dimension} from "crossfilter2";
import {lookupFromArray} from "../../../utils/miscUtilities";
import {Chart, ChartType, LegendOptions} from "chart.js";
import {COLORS} from "../../diagramEditor/implementations/dc/constants";
import {addOpacityToColor} from "../utilities/helpers";
import _ from "lodash";
import {filterDimension} from "../../filterRenderer/utilities/helpers";
import {Filter} from "../../filterRenderer/types";
import hash from "object-hash";

// @ts-ignore
import className from "../../../assets/scss/components/riskmodel.scss";

export type StackedByColumnValuesBarChartConfig = {
    xColumn: string;
    yColumn: string;
    stackColumn: string;
    label: string;
}

export type StackedByColumnsBarChartConfig = {
    xColumn: string;
    yColumns: string|string[];
    label: string;
}

export type BarChartConfig = StackedByColumnValuesBarChartConfig|StackedByColumnsBarChartConfig;

type BarChartData = Record<string, number|string>;

function useChartJSBarChart(
    statisticData: StatisticDefinition<BarChartConfig>,
    filterState: Record<string, Filter>
) {
    const { plugin, filters, data } = statisticData;

    const {
        crossfilter,
        group,
        filterDimensionLookup
    } = useMemo(() => {
        const crossfilter = crossfilterFunc(statisticData.data)
        const {
            xColumn
        } = plugin.config as BarChartConfig;
        const dimension = crossfilter.dimension(d => d[xColumn]);
        const group = createGroup(dimension, plugin.config);
        const filterDimensionLookup = lookupFromArray<any, Dimension<any, any>>(filters.map(({ column }) => ({
            id: column,
            dimension: crossfilter.dimension(d => d[column])
        })), ({ id }) => id, ({ dimension }) => dimension);

        return {
            crossfilter,
            filterDimensionLookup,
            group
        };
    }, [hash(data)]);

    useEffect(() => {
        crossfilter.remove(() => true);
        crossfilter.add(statisticData.data);
    }, [statisticData.data.length]);

    const updateDimensions = useCallback(() => {
        _.keys(filterDimensionLookup)
            .forEach(key => {
                const dimension = filterDimensionLookup[key];
                if (!filterState[key] || !filterState[key].comparatorValue) {
                    dimension.filterAll();
                } else {
                    const filter = filterState[key];
                    filterDimension(dimension, filter);
                }
            })
    }, [filterState,filterDimensionLookup ]);

    const chartData = useMemo(() => {
        updateDimensions();
        const filteredData = group!.all();
        const uniqueValues = _.uniq(filteredData.flatMap(d => _.keys(d.value)));
        return {
            labels: filteredData.map(d => d.key),
            datasets: filteredData.length > 0
                ? uniqueValues.map((key, i) => ({
                    label: key,
                    data: filteredData.map(d => d.value[key]),
                    borderColor: COLORS[i],
                    backgroundColor: addOpacityToColor(COLORS[i], 0.5)
                }))
                : []
        };
    }, [filterState, statisticData.data.length, filterDimensionLookup, updateDimensions]);

    const config = useMemo(() => ({
        type: "bar" as ChartType,
        data: chartData,
        options: {
            animation: {
                duration: 0
            },
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    position: 'top',
                } as LegendOptions<any>,
                title: {
                    display: _.isString(plugin.config?.label),
                    text: plugin.config?.label
                }
            },
            scales: {
                x: {
                    stacked: true,
                },
                y: {
                    stacked: true
                }
            }
        },
    }), [chartData]);
    return { config, chartData };
}

function isStackedByColumnValuesBarChartConfig(value: any = {}): value is StackedByColumnValuesBarChartConfig {
    const { stackColumn, yColumn } = value;
    return !!stackColumn && !!yColumn
}

function isStackedByColumnsBarChartConfig(value: any = {}): value is StackedByColumnsBarChartConfig {
    const { yColumns } = value;
    return !!yColumns
}


function createGroup(
    dimension: Dimension<any, any>,
    pluginConfig?: BarChartConfig
) {
    if (isStackedByColumnsBarChartConfig(pluginConfig)) {
        const yColumns = _.toArray(pluginConfig.yColumns);
        return dimension.group<number, Record<string, number>>().reduce(
            (p, v) => {
                yColumns.forEach(key => p[key] = (p[key] || 0) + (v[key] as number));
                return p;
            },
            (p, v) => {
                yColumns.forEach(key => p[key] = (p[key] || 0) - (v[key] as number));
                return p;
            },
            () => ({})
        );
    } else if (isStackedByColumnValuesBarChartConfig(pluginConfig)) {
        const { stackColumn, yColumn } = pluginConfig;
        return dimension.group<number, Record<string, number>>().reduce(
            (p, v) => {
                const key = v[stackColumn];
                p[key] = (p[key] || 0) + (v[yColumn] as number);
                return p;
            },
            (p, v) => {
                const key = v[stackColumn];
                p[key] = (p[key] || 0) - (v[yColumn] as number);
                return p;
            },
            () => ({})
        );
    }
}

export default class BarChartStatisticVisualizer implements StatisticsVisualizationPlugin<BarChartConfig, BarChartData> {

    public createRenderer(): FC<RendererProperties> {
        return (props: RendererProperties<BarChartConfig, BarChartData>) => {
            const {
                filterState,
                statisticDefinition
            } = props;
            const [chart, setChart] = useState<Chart|null>();
            const {config, chartData} = useChartJSBarChart(statisticDefinition, filterState);
            useEffect(() => {
                if (chart) {
                    chart.data = chartData;
                    chart.update();
                }
            }, [chartData]);
            return useMemo(() => (
                <canvas
                    ref={ref => {
                        if (ref) {
                            const ctx = ref!.getContext("2d")!
                            const chart = new Chart(ctx, config);
                            setChart(chart);
                        }
                    }}
                    className={className.canvas}
                />
            ), []);
        };
    }

    public type(): string {
        return 'bar';
    }

    public validateData(data: StatisticDefinition<BarChartConfig, Record<string, string | number>>): boolean {
        throw new Error("Method not implemented.");
    }

}