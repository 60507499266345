import React, {useCallback, useMemo, useRef, useState} from "react";
import {Button, Card, Dropdown, Menu} from "antd";
import {FullscreenExitOutlined, FullscreenOutlined, PlusOutlined} from "@ant-design/icons";
import Injectable from "../../injection/injectable";
import {injectReactComponent} from "../../utils/injectionUtilities";
import {callSetterOnValueChange, useResizeObserver} from "../../utils/hooks";
// @ts-ignore
import reductio from "reductio";
import {Scrollbars} from "react-custom-scrollbars";
import GridLayout, {Layout} from "react-grid-layout";
import _ from "lodash";
import {useInjection} from "inversify-react";
import {Component, ComponentDefinition, PartialComponent} from "../../constants/globalTypes";
import filterGridClassName from "../../assets/scss/components/filtergrids.scss";
import {ComponentRendererContainerProperties} from "./ComponentRendererContainer";
import {ComponentType} from "../../constants/enums";
import {AddComponentModalProperties} from "./AddOrEditComponentModal";
import {useImmer} from "use-immer";
import {FullScreen, useFullScreenHandle} from "react-full-screen";
import {v4 as uuid} from "uuid";

import className from "../../assets/scss/statistics.scss";

export interface StatisticView {
  components: Component[];
  layout: Record<string, Layout>;
}

export interface StatisticViewMakerProperties {
  onStatisticViewChange: (state: StatisticView) => void;
  statisticView: StatisticView;
  width?: number;
}

function getDefaultLayout(
  component: Component
): Layout {
  const {
    type,
    id
  } = component;
  switch (type) {
    case ComponentType.Statistic:
      return { i: id, x: 0, y: 0, w: 2, h: 3, resizeHandles: ['e'] };
    default:
      return { i: id, x: 0, y: 0, w: 5, h: 8 };
  }
}

function renderMenu(
  callback: (type: ComponentType) => void,
  componentDefinitions: ComponentDefinition[]
) {
  return (
    <Menu
      items={
        componentDefinitions.map(({ label, type }) => (
          {
            label: label,
            key: type,
            onClick: () => callback(type)
          }
        ))
      }
    />
  )
}

type StatisticViewMakerState = {
  componentType?: ComponentType;
  componentIndex?: number;
  openAddModal?: boolean;
  initialComponent?: PartialComponent;
}

function useStatisticViewMakerState(
  props: StatisticViewMakerProperties
) {
  const {
    statisticView: initialStatisticView,
    onStatisticViewChange
  } = props;
  const [statisticView, setStatisticView] = useState<StatisticView>(initialStatisticView);
  const [state, updateState] = useImmer<StatisticViewMakerState>({});
  const {
    openAddModal = false,
    componentIndex,
    initialComponent
  } = state;

  const updateStatisticView = useCallback((nextStatisticView: StatisticView) => {
      onStatisticViewChange(nextStatisticView);
      setStatisticView(nextStatisticView);
  },[onStatisticViewChange, setStatisticView]);

  const initializeNewComponent = useCallback((componentType: ComponentType) => updateState({
    componentType,
    openAddModal: true,
    initialComponent: { type: componentType, id: uuid() }
  }), [updateState]);

  const editExistingComponent = useCallback((component: Component) => {
    const componentIndex = statisticView.components.indexOf(component);
    updateState({
      openAddModal: true,
      componentIndex,
      initialComponent: component
    });
  }, [updateState]);

  const deleteComponent = useCallback((component: Component) => {
    const components = statisticView.components.filter(c => c.id !== component.id);
    const layout = { ...statisticView.layout };
    delete layout[component.id];
    updateStatisticView({ components, layout });
  }, [statisticView, updateStatisticView]);

  const clearComponent = useCallback(() => updateState({}), [updateState]);

  const onFinish = useCallback((component: Component) => {
    const components = statisticView.components.slice();
    const layout = { ... statisticView.layout };
    layout[component.id] = layout[component.id] || getDefaultLayout(component);
    if (_.isNumber(componentIndex)) {
      components[componentIndex] = component;
    } else {
      components.push(component as Component);
    }
    updateStatisticView({ ...statisticView, components, layout });
  }, [statisticView, componentIndex]);

  const updateLayout = useCallback((nextLayout: Layout[]) => {
    const layout = {...statisticView.layout};
    nextLayout.forEach(componentLayout => {
      layout[componentLayout.i] = componentLayout;
    });
    if (!_.isEqual(layout, statisticView.layout)) {
      updateStatisticView({ ...statisticView, layout });
    }
  }, [statisticView]);

  const isEditingExistingComponent = _.isNumber(componentIndex);

  return {
    statisticView,
    initializeNewComponent,
    editExistingComponent,
    clearComponent,
    onFinish,
    initialComponent,
    openAddModal,
    updateLayout,
    isEditingExistingComponent,
    deleteComponent
  }
}

interface StatisticViewToolsProperties {
  initializeNewComponent: (componentType: ComponentType) => void;
  onFullscreenClick: (open: boolean) => void;
}

export function StatisticViewTools(props: StatisticViewToolsProperties) {
  const {
    initializeNewComponent,
    onFullscreenClick
  } = props;
  const [isFullScreenOpen, setIsFullScreenOpen] = useState(false);
  callSetterOnValueChange(onFullscreenClick, isFullScreenOpen);

  const componentDefinitions = useInjection<ComponentDefinition[]>(Injectable.ComponentDefinitions)
  const Menu = useMemo(() => renderMenu(initializeNewComponent, componentDefinitions), []);
  return (
    <div className={className.toolbar}>
      {
        !isFullScreenOpen && (
          <Dropdown overlay={Menu} placement="bottomRight" arrow>
            <Button
              type="default"
              icon={<PlusOutlined />}
              size="middle" />
          </Dropdown>
        )
      }
      <Button
        type="default"
        size="middle"
        onClick={() => setIsFullScreenOpen(!isFullScreenOpen)}
        icon={
          isFullScreenOpen
            ? <FullscreenExitOutlined />
            : <FullscreenOutlined />
        }
      />
    </div>
  );
}

function StatisticViewMaker(
  props: StatisticViewMakerProperties
) {
  const ref = useRef(null);
  const size = useResizeObserver(ref);
  const {
    isEditingExistingComponent,
    statisticView,
    initialComponent,
    openAddModal,
    initializeNewComponent,
    editExistingComponent,
    clearComponent,
    onFinish,
    updateLayout,
    deleteComponent
  } = useStatisticViewMakerState(props);
  const {
    width
  } = props;

  const AddComponentModal = injectReactComponent<AddComponentModalProperties>(Injectable.AddComponentModal);
  const ComponentRenderer = injectReactComponent<ComponentRendererContainerProperties>(
    Injectable.ComponentRendererContainer);

  const handle = useFullScreenHandle();
  return (
    <FullScreen
      handle={handle}
      className={className.statisticViewMakerContainer}>
      <Card
        ref={ref}
        className={className.content}
        bordered={false}>
        <StatisticViewTools
          onFullscreenClick={open =>
            open
              ? handle.enter()
              : handle.exit()
          }
          initializeNewComponent={initializeNewComponent} />
        <GridLayout
          draggableHandle={`.${filterGridClassName.draggableHandle}`}
          cols={12}
          rowHeight={30}
          onLayoutChange={updateLayout}
          width={width || size.width}
          containerPadding={[20, 20]}>
          {
            statisticView.components.map((component) => {
              const layout = statisticView.layout[component.id];
              return (
                <div
                  key={component.id}
                  data-grid={layout}>
                  <ComponentRenderer
                    component={component}
                    onEdit={() => editExistingComponent(component)}
                    onDelete={() => deleteComponent(component)}
                    {...component} />
                </div>
              );
            })
          }
        </GridLayout>
        <AddComponentModal
          isEditingExistingComponent={isEditingExistingComponent}
          open={openAddModal}
          onHide={clearComponent}
          onFinish={onFinish}
          initialComponent={initialComponent} />
      </Card>
    </FullScreen>
  )
}

export default StatisticViewMaker;
