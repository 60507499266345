import * as React from "react";
import {DataPoint} from "../../interfaces/models/DataPoint";
import DataSource from "../../interfaces/DataSource";
import {inject, injectable} from "inversify";
import Injectable from "../../injection/injectable";
import Config, {DimensionType} from "../../interfaces/Config";
import {Message} from "../../constants/enums";
import Messenger from "../../interfaces/Messenger";
import AuthState from "../../store/interfaces/states/AuthState";
import {Store} from "redux";
import Client from "../../interfaces/Client";
import { addRedactionString } from "../../store/actions/data";
import _ from "lodash";

export type Accidents = {
  data: DataPoint[];
  redactionString: string;
  redactionInt: number;
}

@injectable()
export default class DataProvider {

  @inject(Injectable.Store)
  private readonly _store!: Store;

  @inject(Injectable.Config)
  private readonly _config!: Config;

  @inject(Injectable.Messenger)
  private readonly _messenger!: Messenger;

  @inject(Injectable.Client)
  private readonly _client!: Client;

  private _data: DataPoint[] = [];
  private _dataChangeCallbacks: ((data: DataPoint[]) => void)[] = [];

  public populateDataSource(dataSource: DataSource): void {
    if (this._data.length > 0) {
      dataSource.data(this._data);
    } else {
      const fetchPromise = this._fetch();
      this._messenger.async(fetchPromise, Message.DataFetching);
      fetchPromise.then(accidents => {
        this._updateData(accidents);
        dataSource.data(this._data);
      });
    }
  }

  public onDataChange(callback: (data: DataPoint[]) => void) {
    this._dataChangeCallbacks.push(callback);
    callback(this._data);
  }

  public get data(): DataPoint[] {
    return this._data;
  }

  private _fetch(): Promise<Accidents> {
    return this._config.debug
      ? this._fetchLocal()
      : this._fetchBackend();
  }

  private _fetchLocal(): Promise<Accidents> {
    const { authenticated } = this.authState;
    if (authenticated) {
      return this._client.get({
        url: this._config.dataUrl,
      }).then(result => result.data);
    }
    return Promise.reject();
  }

  private _fetchBackend(): Promise<Accidents> {
    const { authenticated } = this.authState;
    if (authenticated) {
      return this._client.get({
        apiEndpoint: 'accidents',
        withCredentials: true
      }).then(result => result.data);
    }
    return Promise.reject();
  }

  private _updateData(
    accidents: Accidents
  ) {
    const {
      data,
      redactionString,
      redactionInt
    } = accidents;
    addRedactionString(redactionString, redactionInt)(this._store.dispatch);
    this._data = data;
    this._dataChangeCallbacks.forEach(dataChangeCallback => dataChangeCallback(this._data))
  }

  private get authState(): AuthState {
    return this._store.getState().auth
  }

}
