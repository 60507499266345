import { message } from 'antd';
import {Message} from "../../constants/enums";
import Messenger from "../../interfaces/Messenger";
import {inject, injectable} from "inversify";
import Injectable from "../../injection/injectable";
import Config from "../../interfaces/Config";
import {DEFAULT_MESSAGE_DURATION} from "../../constants/defaults";

@injectable()
export default class AntDMessenger implements Messenger {

    // @resolve(Injectable.Config)
    private readonly _config!: Config;

    constructor(
        @inject(Injectable.Config) config: Config
    ) {
        this._config = config;
    }

    public async(
        promise: Promise<Message>,
        loadingMessage: string
    ): void {
        const messagePromise = promise
          .then(() => Promise.resolve(Message.DataFetched))
          .catch(err => {
              console.error(err);
              return Promise.reject(Message.DataFetchFailed);
          });
        const hide = message.loading(loadingMessage, 0);
        messagePromise
            .then(successMessage => {
                hide();
                return this.success(successMessage);
            })
            .catch(errorMessage => {
                hide();
                return this.error(errorMessage);
            });
    }

    public error(errorMessage: string): PromiseLike<any> {
        return message.error(errorMessage, this.messageDurationInSeconds);
    }

    public success(successMessage: string): PromiseLike<any> {
        return message.success(successMessage, this.messageDurationInSeconds);
    }

    private get messageDurationInSeconds() {
        const {
            messageDuration = DEFAULT_MESSAGE_DURATION
        } = this._config || {};
        return messageDuration / 1000;
    }

}
