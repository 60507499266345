import {Categorical, Statistic} from "../../constants/globalTypes";
import ComponentRendererProperties from "../../interfaces/properties/ComponentRendererProperties";
import React, {useMemo, useRef} from "react";
import {injectReactComponent} from "../../utils/injectionUtilities";
import {RendererWrapperProperties} from "./RendererWrapper";
import Injectable from "../../injection/injectable";
import _ from "lodash";
import {createOrdinalChart} from "../../utils/chartsUtilities";
import {Chart} from "../../constants/enums";
import {DimensionDefinition} from "../../interfaces/Config";
import {DataPoint} from "../../interfaces/models/DataPoint";
import {useComponentCreator, useCrossfilter, useDimension} from "../../utils/hooks";

export class Mapper {

  private readonly _ordinalLookup: string[];
  private readonly _integerLookup: Record<string, number>;
  public ordinalToInteger: (ordinal: string) => number;
  public integerToOrdinal: (i: number) => string;

  constructor (keys: string[]) {
    this._ordinalLookup = [];
    this._integerLookup = {};

    keys.forEach(key => {
      if(!this._integerLookup.hasOwnProperty(key)) {
        this._integerLookup[key] = this._ordinalLookup.length;
        this._ordinalLookup.push(key);
      }
    });

    this.ordinalToInteger = this._ordinalToInteger.bind(this);
    this.integerToOrdinal = this._integerToOrdinal.bind(this);
  }

  private _ordinalToInteger(ordinal: string) {
    return this._integerLookup[ordinal];
  }

  private _integerToOrdinal(i: number) {
    return this._ordinalLookup[i];
  }

  public length() {
    return this._ordinalLookup.length;
  }
}

function cartesian(...a: any[]) {
  return a.reduce((a, b) => a.flatMap((d: any) => b.map((e: any) => [d, e].flat())));
}

export function createCategoricalDimensionFunction(
  chartType: Chart,
  mapper: Mapper,
  dimension: DimensionDefinition<DataPoint>,
  groupBy?: DimensionDefinition<DataPoint>,
) {
  if (groupBy && chartType === Chart.Pie) {
    if (groupBy.isArray || dimension.isArray) {
      return (d: DataPoint) => {
        const groupByValue = [].concat(groupBy.selector(d));
        const dimensionValue = [].concat(dimension.selector(d));
        return cartesian(groupByValue, dimensionValue);
      }
    } else {
      return (d: DataPoint) => [groupBy.selector(d), dimension.selector(d)];
    }
  }
  return (d: DataPoint) => {
    const value = dimension.selector(d);
    return dimension.isArray
      ? value.map(mapper!.ordinalToInteger)
      : mapper!.ordinalToInteger(value);
  }
}

export default function CategoricalRenderer(props: ComponentRendererProperties<Categorical>) {
  const ref = useRef<HTMLDivElement>(null);
  const RendererWrapper = injectReactComponent<RendererWrapperProperties>(Injectable.RendererWrapper);
  const {
    data: categorical,
    width,
    height,
    valid,
    editing,
    isEditingExistingComponent
  } = props;
  const {
    chartType,
    xAxisId,
    yAxisId,
    groupById,
    metric
  } = categorical;

  const crossfilter = useCrossfilter();

  const xAxis = useDimension(xAxisId);
  const yAxis = useDimension(yAxisId);
  const groupBy = useDimension(groupById);

  const categoriesMapper = useMemo(() => {
    if (crossfilter && xAxis) {
      const data = crossfilter.all();
      const categories = _.chain(data).map(xAxis.selector).flatten().uniq().value();
      return new Mapper(categories);
    }
  }, [xAxis]);

  const [createCategoricalChart, changed] = useComponentCreator<Statistic>((crossfilter) => {
    const dimensionFunction = createCategoricalDimensionFunction(chartType!, categoriesMapper!, xAxis!, groupBy);
    const crossfilterDimension = crossfilter.dimension(
      dimensionFunction,
      xAxis?.isArray || (groupBy && chartType === Chart.Pie && groupBy.isArray));
    return createOrdinalChart(categorical.chartType!,
      categoriesMapper!,
      {
        element: ref.current!,
        yAxis: yAxis!,
        groupBy: groupBy!,
        metric,
        crossfilterDimension,
        width,
        height,
        crossfilter,
        editing
      }
    );
  }, props);

  return (
    <RendererWrapper
      isEditingExistingComponent={isEditingExistingComponent}
      editing={editing}
      valid={valid}
      changed={changed}
      onRerender={createCategoricalChart}>
      <div ref={ref} />
    </RendererWrapper>
  );

}
