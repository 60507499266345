import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider as ReactReduxProvider } from 'react-redux';
import { Provider as InversifyReactProvider } from 'inversify-react';
import App from './app';
import store from './store';
import {container} from "./injection";

const element = document.getElementById('root') as HTMLElement;

ReactDOM.render(
  <InversifyReactProvider container={container}>
    <ReactReduxProvider store={store}>
      <App />
    </ReactReduxProvider>
  </InversifyReactProvider>,
  element
);
