import { StyleConfig } from "./globalTypes";

export const FOCUS_COLOR = '#d92f71';
export const MARKER_FILL_COLOR = '#212121';
export const MARKER_STROKE_COLOR = '#9f9f9f';
export const MARKER_FILTERED_COLOR = 'rgba(159, 159, 159, 0.3)';
export const DEFAULT_GEO_JSON_COLOR = '#40434E';
export const FYLKER_GEO_JSON_COLOR = '#070707';
export const FYLKER_GEO_NIGHT_MODE_JSON_COLOR = '#40434E';
export const HOVEDOGBILED_GEO_JSON_COLOR = '#FFA400';
export const TSS_AREA_GEO_JSON_COLOR = '#E75A7C';
export const TSS_BORDER_GEO_JSON_COLOR = '#164983';
export const DRAW_FEATURE_COLOR = '#1e71d4';

export const FILTERED_IN_STYLE_CONFIG: StyleConfig = {
  markerFill: '#212121',
  clusterStroke: '#757575',
  clusterFill: '#212121',
  textColor: '#FFF'
}

export const FILTERED_IN_STYLE_CONFIG_NIGHT_MODE: StyleConfig = {
  markerFill: '#ababab',
  clusterFill: '#212121',
  clusterStroke: '#ababab',
  textColor: '#ababab'
}

export const FILTERED_OUT_STYLE_CONFIG: StyleConfig = {
  markerFill: 'rgba(159, 159, 159, 0.3)',
  clusterFill: 'rgba(159, 159, 159, 0.3)',
  clusterStroke: 'rgba(159, 159, 159, 0.3)',
  textColor: '#FFF'
}

export const FILTERED_OUT_STYLE_CONFIG_NIGHT_MODE: StyleConfig = {
  markerFill: 'rgba(171, 171, 171, 0.1)',
  clusterFill: 'rgba(159, 159, 159, 0.1)',
  clusterStroke: 'rgba(171, 171, 171, 0.1)',
  textColor: 'rgba(171, 171, 171, 0.4)'
}
