import React, {useState} from "react";
import { Radio } from "antd";
import {useInjection} from "inversify-react";
import Injectable from "../../injection/injectable";
import {
    DataPointInfoModalComponent,
    FunctionalComponent,
    PermissionWrapperComponent
} from "../../constants/globalTypes";

export default function Models() {
    const [model, setModel] = useState("frekvens");
    const onChange = (e: any) => setModel(e.target.value);
    const CheckAuthWrapper = useInjection<FunctionalComponent>(Injectable.CheckAuthWrapper);
    return (
        <CheckAuthWrapper>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1, paddingTop: 20 }}>
                <Radio.Group onChange={onChange} defaultValue={model} buttonStyle="solid" style={{ paddingBottom: 50 }}>
                    <Radio.Button value="frekvens">Frekvens</Radio.Button>
                    <Radio.Button value="konsekvens">Konsekvens</Radio.Button>
                </Radio.Group>
                <img src={`/static/images/svg/${model}.svg`} />
            </div>
        </CheckAuthWrapper>
    )
}
