import { createBrowserHistory } from 'history'
import {combineReducers, createStore} from "redux";
import middleware, { runSaga } from "./middleware";
import {connectRouter} from "connected-react-router";
import * as reducers from "./reducers";
import {addStoreToContainer} from "../injection";

export const history = createBrowserHistory();
const router = connectRouter(history);
const combinedReducers = combineReducers({
    ...reducers,
    router
});

const store = createStore(combinedReducers, middleware);

runSaga();

addStoreToContainer(store);

export default store;
