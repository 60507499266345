import React from "react";
import {useInjection} from "inversify-react";
import Injectable from "../../injection/injectable";
import {
  PermissionWrapperComponent,
  FunctionalComponent,
  DataPointInfoModalComponent
} from "../../constants/globalTypes";

// @ts-ignore
import className from "../../assets/scss/statistics.scss";

export default function Statistics() {
  const StatisticViewMaker = useInjection<PermissionWrapperComponent>(Injectable.StatisticViewMaker);
  const FilterView = useInjection<PermissionWrapperComponent>(Injectable.FilterView);
  const DataSourceProvider = useInjection<FunctionalComponent>(Injectable.DataSourceProvider);
  const CheckAuthWrapper = useInjection<FunctionalComponent>(Injectable.CheckAuthWrapper);

  return (
    <DataSourceProvider>
      <CheckAuthWrapper>
          <div className={className.pageContainer}>
            <FilterView>
              <StatisticViewMaker />
            </FilterView>
          </div>
      </CheckAuthWrapper>
    </DataSourceProvider>
  );
}
