import * as dc from "dc";
import {CoordinateGridMixin, Legend, PieChart, SunburstChart, UnitFunction} from "dc";
import * as d3 from "d3";
import {Crossfilter, Dimension as CrossfilterDimension, NaturallyOrderedValue} from "crossfilter2";
import {DimensionDefinition} from "../interfaces/Config";
import _ from "lodash";
import {DataPoint} from "../interfaces/models/DataPoint";
import {Chart, Metric, DimensionTransform, Unit} from "../constants/enums";
import dayjs from "dayjs";
import {Mapper} from "../components/statisticViewMaker/CategoricalRenderer";
// @ts-ignore
import reductio from "reductio";
import {MONTHS} from "../constants/misc";
import {ComponentResizer} from "../constants/globalTypes";

// const COLORS = ["#a6cee3","#1f78b4","#b2df8a","#33a02c","#fb9a99","#e31a1c","#fdbf6f","#ff7f00","#cab2d6","#6a3d9a","#ffff99","#b15928"];
const COLORS = d3.schemeSet3;
const ITEM_HEIGHT = 13;
const ITEM_GAP = 5;
const DEFAULT_MARGIN = { top: 10, right: 10, bottom: 20, left: 42 };

export interface SizeChartOptions {
  width: number;
  height: number;
}

export interface BaseChartOptions {
  element: HTMLDivElement;
  crossfilterDimension: CrossfilterDimension<DataPoint, any>;
  crossfilter: Crossfilter<DataPoint>;
  editing?: boolean;
}

export interface TwoDimensionChartOptions {
  xAxis?: Unit;
  yAxis: DimensionDefinition<DataPoint>;
  groupBy?: DimensionDefinition<DataPoint>;
  groupByValues?: string[];
  metric?: Metric;
  rotateXAxisLabels?: boolean;
}

export interface TimeChartOptions {
  xUnits?: UnitFunction;
  resolution?: DimensionTransform;
}

export interface OrdinalChartOptions {
  mapper?: Mapper;
}

export interface NumberDisplayOptions extends BaseChartOptions {
  dimension: DimensionDefinition<DataPoint>;
  metric: Metric;
  percentageOfTotal?: boolean;
}

export type LineChartOptions = TwoDimensionChartOptions & SizeChartOptions & TimeChartOptions & BaseChartOptions;
export type BarChartOptions = TwoDimensionChartOptions & SizeChartOptions & TimeChartOptions & BaseChartOptions;
export type PieChartOptions = TwoDimensionChartOptions & OrdinalChartOptions & SizeChartOptions & BaseChartOptions;
export type SunburstChartOptions = TwoDimensionChartOptions & OrdinalChartOptions & SizeChartOptions & BaseChartOptions;

function getMaxItems(groupByValues: string[], height: number) {
  let itemCount = groupByValues.length;
  while (true) {
    const totalHeightOfItems = (ITEM_HEIGHT + ITEM_GAP) * itemCount - ITEM_GAP + DEFAULT_MARGIN.top;
    if (totalHeightOfItems < height) {
      return itemCount;
    } else {
      itemCount -= 1;
    }
  }
}

function addAggregations(reducer: reductio, metric: Metric, selector?: (d: DataPoint) => any) {
  return reducer
    .count((metric & Metric.Count) === Metric.Count)
    .avg((metric & Metric.Average) === Metric.Average ? selector : undefined)
    .sum((metric & Metric.Sum) === Metric.Sum ? selector : undefined)
    .max((metric & Metric.Max) === Metric.Max ? selector : undefined)
    .min((metric & Metric.Min) === Metric.Min ? selector : undefined)
    .std((metric & Metric.StandardDeviation) === Metric.StandardDeviation ? selector : undefined);
}

function createFilter(
    yAxis?: DimensionDefinition<DataPoint>,
    groupBy?: DimensionDefinition<DataPoint>,
    value?: any
) {
  if (groupBy) {
    return groupBy.isArray
      ? (d: DataPoint) => {
        return groupBy.selector(d).includes(value) && yAxis?.selector(d) !== -1;
      }
      : (d: DataPoint) => {
        return groupBy.selector(d) === value && yAxis?.selector(d) !== -1
      };
  }
  return (d: DataPoint) => {
    return yAxis?.selector(d) !== -1
  };
}

function createGroup(
  options: {
    chartOptions: TwoDimensionChartOptions & TimeChartOptions & BaseChartOptions,
    uniqueValue?: string,
    ignoreGroupBy?: boolean
  }
) {
  const {
    crossfilterDimension,
    yAxis,
    xUnits,
    groupBy,
    metric = Metric.Count,
    groupByValues,
  } = options.chartOptions;

  const {
    uniqueValue,
    ignoreGroupBy = false
  } = options;

  let group = _.isFunction(xUnits)
    ? crossfilterDimension.group(d => (xUnits as Function)(d) as NaturallyOrderedValue)
    : crossfilterDimension.group();

  const reducer = reductio();
  if (groupBy && uniqueValue && !ignoreGroupBy) {
    const filter = createFilter(yAxis, groupBy, uniqueValue);
    reducer.filter(filter);
    addAggregations(reducer, metric, yAxis?.selector);
  } else if (groupBy && _.isUndefined(uniqueValue) && !ignoreGroupBy) {
    for (const groupByValue of groupByValues!) {
      const filter = createFilter(yAxis, groupBy, groupByValue);
      const value = reducer.value(groupByValue).filter(filter);
      addAggregations(value, metric, yAxis?.selector);
    }
  } else {
    const filter = createFilter(yAxis);
    reducer.filter(filter);
    addAggregations(reducer, metric, yAxis?.selector);
  }
  reducer.value("countWithoutFiltered")
    .filter(createFilter(yAxis))
    .count(true);
  reducer.value("countWithFiltered")
    .count(true);
  return reducer(group);
}

function getTrendTickFormatter(
  options: TwoDimensionChartOptions & TimeChartOptions & BaseChartOptions
) {
  const { xAxis, resolution } = options;
  if (xAxis === Unit.Months) {
    return (v: any) => MONTHS[v];
  }
  if (xAxis === Unit.Date) {
    if (resolution === DimensionTransform.Year) {
      return  (v: any) => dayjs(v).year().toString();
    } else if (resolution === DimensionTransform.Months) {
      return  (v: any) => `${dayjs(v).year()}, ${MONTHS[dayjs(v).month()]}`;
    } else if (resolution === DimensionTransform.Weeks) {
      return  (v: any) => `${dayjs(v).year()}, ${dayjs(v).week()}`;
    }
  }
  return (v: any) => v.toString();
}

function addTickFormatter(
  chart: CoordinateGridMixin<any>,
  options: TwoDimensionChartOptions & BaseChartOptions,
  mapper?: Mapper
) {
  if (mapper) {
    chart.xAxis()
      .tickValues(d3.range(mapper.length()))
      .tickFormat(mapper.integerToOrdinal);
  } else {
    const tickFormatter = getTrendTickFormatter(options);
    chart.xAxis().tickFormat(tickFormatter);
  }
}

function getYAxisLabel(
  options: TwoDimensionChartOptions & SizeChartOptions & BaseChartOptions
) {
  if (options.metric === Metric.Count) {
    return "Antall ulykker";
  } else if (options.yAxis) {
    return options.yAxis.label;
  }
  return undefined;
}

function generateValueAccessorByMetric(metric: Metric) {
  switch (metric) {
    case Metric.Sum:
      return (d: any) => d.sum;
    case Metric.Average:
      return (d: any) => d.avg;
    case Metric.Max:
      return (d: any) => d.max;
    case Metric.Min:
      return (d: any) => d.min;
    case Metric.StandardDeviation:
      return (d: any) => d.std;
    case Metric.Count:
    default:
      return (d: any) => d.count;
  }
}

function setupTwoAxisChart(
  chart: CoordinateGridMixin<any>,
  options: TwoDimensionChartOptions & TimeChartOptions & SizeChartOptions & BaseChartOptions
) {
  const {
    crossfilterDimension,
    width,
    height,
    xUnits,
  } = options;
  const yAxisLabel = getYAxisLabel(options);
  chart
    .transitionDuration(0)
    .width(width)
    .height(height)
    .x(d3.scaleLinear())
    .brushOn(false)
    .elasticX(true)
    .elasticY(true)
    .yAxisLabel(yAxisLabel)
    .margins({ ... DEFAULT_MARGIN })
    .dimension(crossfilterDimension)
    .renderlet(() => adjustMarginsForXAxis(chart, options))


  if (_.isFunction(xUnits)) {
    chart.xUnits(xUnits);
  }
}

export function createLegend(
  options: TwoDimensionChartOptions & SizeChartOptions
) {
  const {
    groupByValues = [],
    height
  } = options;
  let maxItems = getMaxItems(groupByValues, height);
  return dc.legend().itemHeight(ITEM_HEIGHT).gap(ITEM_GAP).maxItems(maxItems);
}

export function adjustMarginsForXAxis(
  chart: CoordinateGridMixin<any>,
  options: TwoDimensionChartOptions
) {
  const {
    rotateXAxisLabels = true
  } = options;
  if (rotateXAxisLabels) {
    chart.selectAll('g.x text')
      .attr('transform', 'translate(-10,10) rotate(315)')
      .style("text-anchor", "end");
    const { height: height } = (chart.svg().select('.axis.x').node() as SVGGraphicsElement).getBBox();
    const oldMargins = { ...chart.margins()  };
    if (oldMargins.bottom < height ) {
      chart.margins({
        ...oldMargins,
        bottom: height
      }).render();
    }
  }
}

export function adjustMarginsForLegend(
  chart: CoordinateGridMixin<any>,
  width: number,
  legend: Legend
) {
  const { width: legendWidth } = (chart.svg().select('.dc-legend').node() as SVGGraphicsElement).getBBox();
  const oldMargins = { ...chart.margins()  };
  legend
    .x(width - legendWidth)
    .y(oldMargins.top);
  chart.margins({
    ...oldMargins,
    right: legendWidth + 2 * oldMargins.top
  }).render();
}

export function adjustPositionAndRadiusForLegend(
  chart: PieChart | SunburstChart
) {
  const RADIUS_PADDING = 10;
  const { width: legendWidth } = (chart.svg().select('.dc-legend').node() as SVGGraphicsElement).getBBox();
  const width = chart.width();
  const height = chart.height();
  const newWidth = width - legendWidth;
  const averageX = (legendWidth + width) / 2;
  chart.radius(Math.min(newWidth / 2, height / 2) - RADIUS_PADDING);
  chart.cx(averageX);
  chart.render();
}

export function createStackedBarChart(
  chartOptions: BarChartOptions,
  mapper?: Mapper
): ComponentResizer {
  const {
    element,
    crossfilterDimension,
    groupByValues = [],
    width,
    metric = Metric.Count
  } = chartOptions;
  const chart = dc.barChart(element as any);
  const group = createGroup({chartOptions});
  setupTwoAxisChart(chart, chartOptions);
  addTickFormatter(chart, chartOptions, mapper);
  const legend = createLegend(chartOptions);
  const valueAccessor = generateValueAccessorByMetric(metric);

  groupByValues.forEach((groupByValue, index) => {
    index === 0
      // @ts-ignore
      ? chart.group(group, groupByValue, d => {
        return valueAccessor(d.value[groupByValue])
      })
      : chart.stack(group, groupByValue, d => valueAccessor(d.value[groupByValue]));
  });

  chart
    .legend(legend)
    .centerBar(true)
    .xAxisPadding(0.5)
    // @ts-ignore
    .colors(d3.scaleOrdinal(COLORS));

  chart.render();

  adjustMarginsForLegend(chart, width, legend);

  return {
    dispose() {
      crossfilterDimension.dispose();
      group.dispose();
      dc.chartRegistry.deregister(chart);
    },
    setSize(width: number, height: number) {
      chart.width(width).height(height);
      let maxItems = getMaxItems(groupByValues, height);
      legend.maxItems(maxItems);
      adjustMarginsForLegend(chart, width, legend);
    }
  }
}

export function createMultilineChart(
  chartOptions: LineChartOptions
): ComponentResizer {
  const {
    element, crossfilterDimension, width, xUnits, groupByValues = [],
    metric = Metric.Count
  } = chartOptions;
  const composite = dc.compositeChart(element as any);
  const groups = groupByValues.map(uniqueValue => createGroup({ chartOptions, uniqueValue }));
  setupTwoAxisChart(composite, chartOptions);
  addTickFormatter(composite, chartOptions);
  const legend = createLegend(chartOptions);
  const valueAccessor = generateValueAccessorByMetric(metric);

  composite
    .legend(legend)
    .renderHorizontalGridLines(true)
    .compose(groups.map((group, index) => {
      const chart = dc.lineChart(composite)
        .dimension(crossfilterDimension)
        .colors(COLORS[index % COLORS.length])
        .valueAccessor(d => valueAccessor(d.value))
        .group(group, groupByValues[index]);
      if (xUnits) {
        chart.xUnits(xUnits);
      }
      return chart;
    }));

  if (xUnits) {
    composite.xUnits(xUnits);
  }

  composite.transitionDuration(0);
  composite.render();

  adjustMarginsForLegend(composite, width, legend);

  return {
    dispose() {
      crossfilterDimension.dispose();
      groups.forEach(group => group.dispose());
      dc.chartRegistry.deregister(composite);
    },
    setSize(width: number, height: number) {
      composite.width(width).height(height).render();
      adjustMarginsForLegend(composite, width, legend);
    }
  }
}

export function createLineChart(
  chartOptions: LineChartOptions
): ComponentResizer {
  const {
    element,
    crossfilterDimension,
    metric = Metric.Count
  } = chartOptions;

  const chart = dc.lineChart(element as any);
  const group = createGroup({chartOptions});
  setupTwoAxisChart(chart, chartOptions);
  addTickFormatter(chart, chartOptions);
  const valueAccessor = generateValueAccessorByMetric(metric);

  chart
    .group(group)
    .valueAccessor(d => valueAccessor(d.value))
    .render();

  return {
    dispose() {
      crossfilterDimension.dispose();
      group.dispose();
      dc.chartRegistry.deregister(chart);
    },
    setSize(width: number, height: number) {
      chart.width(width).height(height).render()
    }
  }
}

export function createBarChart(
  chartOptions: LineChartOptions,
  mapper?: Mapper
): ComponentResizer {
  const {
    element,
    crossfilterDimension,
    metric = Metric.Count
  } = chartOptions;

  const chart = dc.barChart(element as any);
  const group = createGroup({chartOptions});
  setupTwoAxisChart(chart, chartOptions);
  addTickFormatter(chart, chartOptions, mapper);
  const valueAccessor = generateValueAccessorByMetric(metric);

  chart
    .xAxisPadding(0.5)
    .centerBar(true)
    .group(group)
    .valueAccessor(d => valueAccessor(d.value))
    .render();

  return {
    dispose() {
      crossfilterDimension.dispose();
      group.dispose();
      dc.chartRegistry.deregister(chart);
    },
    setSize(width: number, height: number) {
      chart.width(width).height(height).render();
    }
  }
}

function addGroupByValuesToOptionsIfNecessary(options: TwoDimensionChartOptions & BaseChartOptions) {
  const {
    groupBy,
    crossfilter
  } = options;
  if (groupBy) {
    const data = crossfilter.all();
    options.groupByValues = _.chain(data).map(groupBy.selector).flatten().uniq().value() as any;
  }
}

export function createTwoAxisChart(
  chartType: Chart,
  options: LineChartOptions | BarChartOptions
): ComponentResizer {
  addGroupByValuesToOptionsIfNecessary(options);
  switch (chartType) {
    case Chart.Bar: {
      return _.isUndefined(options.groupBy)
        ? createBarChart(options) : createStackedBarChart(options);
    }
    case Chart.Line:
    default: {
      return _.isUndefined(options.groupBy)
        ? createLineChart(options) : createMultilineChart(options);
    }
  }
}

export function createPieChart(
  chartOptions: PieChartOptions,
  mapper: Mapper
): ComponentResizer {
  const {
    element,
    width,
    height,
    crossfilterDimension,
    groupBy,
    metric = Metric.Count
  } = chartOptions;

  const isSunburstChart = !!groupBy;
  const chart = isSunburstChart
    ? dc.sunburstChart(element)
    : dc.pieChart(element);

  chart.onClick = () => null;

  const group = createGroup({
    chartOptions,
    ignoreGroupBy: true
  });
  const valueAccessor = generateValueAccessorByMetric(metric);
  const legend = dc.legend()
    .legendText((d: any) => isSunburstChart ? d.name.toString() : mapper.integerToOrdinal(d.name));

  chart
    .width(width)
    .height(height)
    .dimension(crossfilterDimension)
    .group(group)
    .legend(legend)
    .valueAccessor(d => valueAccessor(d.value))
    .label( ({key}) => {
      return isSunburstChart ? key : mapper.integerToOrdinal(key);
    })
    .render();

  adjustPositionAndRadiusForLegend(chart);

  return {
    dispose() {
      crossfilterDimension.dispose();
      group.dispose();
      dc.chartRegistry.deregister(chart);
    },
    setSize(width: number, height: number) {
      const transitionDuration = chart.transitionDuration()
      chart.width(width).height(height).transitionDuration(0);
      adjustPositionAndRadiusForLegend(chart);
      chart.transitionDuration(transitionDuration);
    }
  }
}


export function createOrdinalChart(
  chartType: Chart,
  mapper: Mapper,
  options: BarChartOptions & PieChartOptions & SunburstChartOptions
): ComponentResizer {
  addGroupByValuesToOptionsIfNecessary(options);
  switch (chartType) {
    case Chart.Pie: {
      return createPieChart(options, mapper);
    }
    case Chart.Bar:
    default: {
      return _.isUndefined(options.groupBy)
        ? createBarChart(options, mapper)
        : createStackedBarChart(options, mapper);
    }
  }
}

function createNumberDisplayGroup(options: NumberDisplayOptions) {
  const {
    crossfilter,
    dimension,
    metric,
  } = options;
  const reducer = reductio().filter((d: DataPoint) => dimension?.selector(d) !== -1);
  addAggregations(reducer, metric, dimension?.selector);
  let group = crossfilter.dimension(d => d as any).groupAll();
  return reducer(group);
}

function getUnfilteredTotal(
  options: NumberDisplayOptions
) {
  const {
    metric,
    crossfilter,
    dimension
  } = options;
  const data = crossfilter.all().map(dimension.selector).filter(v => v >= 0);
  switch (metric) {
    case Metric.Average:
      return data.reduce((previousValue, currentValue) => previousValue + currentValue, 0) / data.length;
    case Metric.Max:
      return Math.max(...data);
    case Metric.Min:
      return Math.min(...data);
    case Metric.StandardDeviation:
      const mean = data.reduce((a, b) => a + b) / data.length
      const preSqrt = data.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / data.length;
      return Math.sqrt(preSqrt);
    case Metric.Sum:
    default:
      return data.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
  }
}

export function createNumberDisplay(
  options: NumberDisplayOptions
): ComponentResizer {
  const {
    crossfilterDimension,
    element,
    metric,
    percentageOfTotal,
    crossfilter,
  } = options;
  const data = crossfilter.all();
  let total: number;
  if (percentageOfTotal) {
    total = metric == Metric.Count
      ? data.length
      : getUnfilteredTotal(options);
  }
  const group = createNumberDisplayGroup(options);
  const valueAccessor = generateValueAccessorByMetric(metric);
  const numberDisplay = dc.numberDisplay(element as any)
    .group(group)
    .valueAccessor(d => {
      const value = valueAccessor(d);
      return percentageOfTotal
        ? Math.round((value / total) * 1000) / 10
        : value;
    });
  numberDisplay.render();

  return {
    dispose() {
      crossfilterDimension.dispose();
      group.dispose();
    },
    setSize(width: number, height: number) {
      numberDisplay.width(width).height(height).render()
    }
  }
}
