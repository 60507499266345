import { put, call, takeEvery, all, fork } from "redux-saga/effects";

import { authCheckAsync, logoutAsync } from "../services/auth";
import { authCheckRequest, authCheckSuccess, authCheckFailure, logoutSuccess, logoutRequest } from "../actions/auth";
import { AuthAction } from "../actions/auth";
import {hydrateUserInfo} from "../actions/user";

function* onAuthCheck() {
  try {
    yield put(authCheckRequest());
    const userInfo = yield call(authCheckAsync);
    yield put(authCheckSuccess(userInfo));
    yield put(hydrateUserInfo(userInfo));
  } catch (error) {
    yield put(authCheckFailure(error));
  }
}

function* onLogout() {
  try {
    yield put(logoutRequest());
    yield call(logoutAsync);
    yield put(logoutSuccess());
  } catch (error) {
    yield put(authCheckFailure(error));
  }
}

function* watchOnAuthCheck() {
  yield takeEvery(AuthAction.AuthCheck, onAuthCheck);
}

function* watchOnLogout() {
  yield takeEvery(AuthAction.Logout, onLogout);
}

export default function* authSaga() {
  yield all([fork(watchOnAuthCheck), fork(watchOnLogout)]);
}
