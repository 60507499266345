import {Metric} from "./enums";

export const METRIC_OPTIONS = [
  { value: Metric.Count, label: 'Antall ulykker' },
  { value: Metric.Sum, label: 'Sum' },
  { value: Metric.Average, label: 'Gjennomsnitt' },
  { value: Metric.Max, label: 'Maximum' },
  { value: Metric.Min, label: 'Minimum' },
  { value: Metric.StandardDeviation, label: 'Standardavvik' },
];
