import React, {PropsWithChildren, useEffect, useRef, useState} from "react";
import {Button} from "antd";

// @ts-ignore
import className from "../../assets/scss/statistics.scss"

export interface RendererWrapperProperties extends PropsWithChildren<{}> {
  changed: boolean;
  onRerender: () => void;
  valid?: boolean;
  editing?: boolean;
  isEditingExistingComponent: boolean;
}


export default function RendererWrapper(props: RendererWrapperProperties) {
  const {
    valid,
    changed,
    onRerender,
    children,
    editing,
    isEditingExistingComponent
  } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [renderedAtLeastOnce, setRenderedAtLeastOnce] = useState(isEditingExistingComponent);

  if (editing) {
    return (
      <div
        ref={ref}
        className={className.rendererContainer}>
        <div style={{ opacity: changed ? 0.5 : 1.0 }}>
          {
            children
          }
        </div>
        <div
          style={{ display: changed || !renderedAtLeastOnce ? 'flex' : 'none' }}
          className={className.renderButtonContainer}>
          <Button
            className={className.rerenderButton}
            disabled={!valid}
            onClick={() => {
              onRerender();
              setRenderedAtLeastOnce(true);
            }}>
            {
              renderedAtLeastOnce ? "Oppdater" : "Forhåndsvis"
            }
          </Button>
        </div>
      </div>
    );
  }
  return (
    <div
      className={className.rendererContainer}
      ref={ref}>
      {
        children
      }
    </div>
  );
}
