import * as React from "react";
import {PopoverWrapperProperties} from "../../interfaces/properties/PopoverWrapperProperties";
import {Button, Checkbox, Popover, Radio, Tooltip, Typography} from "antd";
import MapControlPopoverItem from "../../interfaces/MapControlPopoverItem";
import _ from "lodash";
import objectPath from "object-path";
import {ReactElement, useState} from "react";

// @ts-ignore
import className from "../../assets/scss/components/map.scss";

function renderItem(
  item: MapControlPopoverItem,
  setPopoverVisible: (value: boolean) => void
): ReactElement {
    const { label, icon, key, onClick } = item;
    switch (item.type) {
        case "radio": {
            return (
              <div key={key} className={className.item}>
                  <Radio value={key}>{label}</Radio>
              </div>
            );
        }
        case "check": {
            const { value } = item;
            return (
              <div key={key} className={className.item}>
                  <Checkbox
                    defaultChecked={value}
                    onChange={e => {
                        const { checked } = e.target;
                        if (_.isFunction(onClick)) {
                            onClick(checked);
                        }
                    }}>{label}</Checkbox>
              </div>
            );
        }
        case "default":
        default: {
            return (
              <Button
                type="text"
                key={key}
                className={className.item}
                icon={icon}
                onClick={() => {
                    setPopoverVisible(false);
                    if (item.onClick) {
                        item.onClick();
                    }
                }}>
                  {label}
              </Button>
            );
        }
    }
}

function groupPopoverItemsByCategory(popoverItems: MapControlPopoverItem[]): Record<string, MapControlPopoverItem[]> {
    const popoverItemsGroupedByCategory = {};
    popoverItems.forEach(popoverItem => {
        const {
            category = "default"
        } = popoverItem;
        objectPath.push(popoverItemsGroupedByCategory, [category], popoverItem);
    });
    return popoverItemsGroupedByCategory;
}

function addPopoverItemsGroupToElements(
  elements: ReactElement[],
  popoverItemsGroup: MapControlPopoverItem[],
  setPopoverVisible: (value: boolean) => void
) {
    const additionalElements = popoverItemsGroup.map(popoverItem => renderItem(popoverItem, setPopoverVisible));
    const isRadio = popoverItemsGroup.map(popoverItem => popoverItem.type).indexOf("radio") >= 0;
    if (isRadio) {
        elements.push(
          <Radio.Group key={popoverItemsGroup[0].key} defaultValue={popoverItemsGroup[0].key}>
              { additionalElements }
          </Radio.Group>
        );
    } else {
        additionalElements.forEach(additionalElement => elements.push(additionalElement));
    }
}

function createPopoverContent(
  popoverItems: MapControlPopoverItem[]|undefined,
  setPopoverVisible: (value: boolean) => void
) {
    if (popoverItems && popoverItems.length) {
        const popoverItemsGroupedByCategory = groupPopoverItemsByCategory(popoverItems);
        if (_.size(popoverItemsGroupedByCategory) > 1) {
            let elements: ReactElement[] = [];
            _.keys(popoverItemsGroupedByCategory).forEach((category: string) => {
                const header = (
                  <div className={className.header}>
                      <Typography.Text type="secondary">
                          {
                              _.capitalize(category)
                          }
                      </Typography.Text>
                  </div>
                );
                elements.push(header);
                const popoverItemsGroup = popoverItemsGroupedByCategory[category];
                addPopoverItemsGroupToElements(elements, popoverItemsGroup, setPopoverVisible);
            });
            elements = elements
              .map((element, index) => React.cloneElement(element, { key: index }));
            return (
              <div className={className.popoverContainer}>
                  { elements }
              </div>
            )
        }
        return (
          <div className={className.popoverContainer}>
              {
                  popoverItems
                    .map(popoverItem => renderItem(popoverItem, setPopoverVisible))
                    .map((element, index) =>
                      React.cloneElement(element, { key: index }))
              }
          </div>
        )
    }
}

export default function AntDPopoverWrapper(
  props: PopoverWrapperProperties
) {
  const {
    element,
    popoverItems,
    tooltipTitle,
    popoverElement,
    tooltipPlacement
  } = props;
  const [tooltipOpen, setTooltipVisible] = useState(false);
    const [popoverVisible, setPopoverVisible] = useState(false);
    const popoverContent = popoverElement || createPopoverContent(popoverItems, setPopoverVisible);
    return (
      <div className={className.mainLayout}>
          <Tooltip
            placement={tooltipPlacement || "top"}
            open={tooltipOpen && !!tooltipTitle}
            title={tooltipTitle}>
              <div
                onMouseLeave={() => setTooltipVisible(false)}
                onMouseOver={() => setTooltipVisible(true)}>
                  {
                    popoverContent ? (
                      <Popover
                        onOpenChange={setPopoverVisible}
                        open={popoverVisible}
                        placement="leftTop"
                        content={popoverContent}
                        trigger={["click"]}>
                        { element }
                      </Popover>
                    ) : element
                  }
              </div>
          </Tooltip>
      </div>
    );
}
