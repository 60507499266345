import {DimensionId} from "../constants/enums";
import _ from "lodash";
import {config as dcConfig} from "dc";
import * as d3 from "d3";
import DataSource from "../interfaces/DataSource";
import axios from "axios";
import {analytics, AnalyticsEvent} from "../components/analytics";
import {Key, useMemo} from "react";

export const alwaysTrue = () => true;

export function lookupFromArray<T = any, D=T>(
    array: T[],
    keySelector: (d: T) => string|number,
    valueSelector?: (d: T) => any
): Record<Key, D> {
    const lookup: Record<string|number, any> = {};
    array.forEach(element => {
        const key = keySelector(element);
        lookup[key] = _.isFunction(valueSelector) ? valueSelector(element) : element;
    });
    return lookup;
}

export function optionalClassNames(
    classNames: Record<string, boolean>
): string {
    return _.entries(classNames).filter(([, include]) => include).map(([className, ]) => className).join(" ");
}

export function joinClassNames(
  ...classNames: (string|undefined|null)[]
) {
    classNames = classNames.filter(className => _.isString(className) && className.length > 0);
    return classNames.join(" ");
}

export function updateState<T>(state: T, update: Partial<T>) {
    const nextState = { ...state };
    return Object.assign(nextState, update);
}

export function hasFlag(flags: number, compareFlag: number) {
    return (flags & compareFlag) === compareFlag;
}

export function hasFlags(flags: number, compareFlags: number[]) {
    return compareFlags.every(compareFlag => hasFlag(flags, compareFlag))
}

export function generateColorScaleForAccidents(
    dataSource: DataSource
) {
    const { _schemeCategory20c: scheme } = dcConfig as any;
    const scale = d3.scaleOrdinal(scheme);
    const domain = dataSource.dimension(DimensionId.AccidentType).group().all().map(d => d.key);
    scale.domain(domain as any);
    return scale;
}

function pushLogObjectToBackend(logObject: { type: string, message: string }) {
    const host = process.env.API_HOST;
    const port = process.env.API_PORT;
    const version = process.env.API_VERSION;
    const apiLogPath = process.env.API_LOG_PATH;
    if (apiLogPath) {
        const endPoint = `http://${host}:${port}/api/${version}/${apiLogPath}`;
        axios.post(endPoint, logObject)
            .then(response => {
            })
    }
}

export function logConsoleMessages() {
    const types = ['warn', 'error', 'trace'];
    types.forEach(type => {
        const func = (window.console as any)[type];
        Object.defineProperty(window.console, type, {
            writable: true, value: (...args: any[]) => {
                const logObject = {
                    type,
                    message: args.filter(arg => _.isString(arg)).join(" ")
                };
                analytics.track(AnalyticsEvent.Console, logObject);
                func(...args);
            }
        });
    });
}

export function hexToRGB(hex: string, alpha: number = 1.0) {
    const r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
}

export function rgbToRgba(
  rgb: string,
  alpha: number = 1.0
) {
    return rgb.substr(0, rgb.length - 1) + `, ${alpha})`;
}

export function endpoint(path: string) {
    const host = process.env.API_HOST;
    const port = process.env.API_PORT;
    const version = process.env.API_VERSION;
    return `http://${host}:${port}/api/${version}/${path}`;
}

export function personDamageKeyMapper(postFixSingular: string, postFixPlural: string) {
    return function(key: any) {
        if (key < 0) {
            return "Ukjent";
        } else if (key == 0) {
            return `Ingen ${postFixPlural}`;
        } else if (key == 1) {
            return `${key} ${postFixSingular}`;
        } else {
            return `${key} ${postFixPlural}`;
        }
    }
}
