import {DimensionDefinition, DimensionType} from "../interfaces/Config";
import _ from "lodash";
import {DimensionId} from "../constants/enums";
import dayjs from "dayjs";
import React from "react";
import {DataPoint} from "../interfaces/models/DataPoint";

export let dimensions: DimensionDefinition<DataPoint>[] = [
  {
    id: DimensionId.County,
    type: DimensionType.GeoCategorical,
    selector: d => d.county,
    label: 'Fylke',
    filterable: true,
  },
  {
    id: DimensionId.PoliceDistrict,
    type: DimensionType.GeoCategorical,
    selector: d => d.policeDistrict,
    label: 'Politidistrikt',
    filterable: true,
  },
  {
    id: DimensionId.Cause,
    type: DimensionType.Categorical,
    selector: d => d.accidentType.split(',').map(cause => _.capitalize(cause)),
    isArray: true,
    label: 'Årsak',
    filterable: true,
  },
  {
    id: DimensionId.DataSource,
    type: DimensionType.Categorical,
    selector: d => d.dataSource,
    label: 'Datakilde',
    filterable: true
  },
  {
    id: DimensionId.ShipType,
    type: DimensionType.Categorical,
    selector: d => d.vesselType.split(',').map(vesselType => _.capitalize(vesselType)),
    isArray: true,
    label: 'Skipstype',
    filterable: true,
  },
  {
    id: DimensionId.Dead,
    type: DimensionType.Numerical,
    selector: d => d.dead,
    label: 'Døde',
    filterable: true,
  },
  {
    id: DimensionId.Missing,
    type: DimensionType.Numerical,
    selector: d => d.missing,
    label: 'Savnede',
    filterable: true,
  },
  {
    id: DimensionId.Injured,
    type: DimensionType.Numerical,
    selector: d => d.hurt,
    label: 'Skadde',
    filterable: true,
  },
  {
    id: DimensionId.Lat,
    type: DimensionType.Numerical,
    selector: d => d.location.coordinates[1],
    label: 'Breddegrad',
    filterable: true
  },
  {
    id: DimensionId.Lon,
    type: DimensionType.Numerical,
    selector: d => d.location.coordinates[0],
    label: 'Lengdegrad',
    filterable: true
  },
  {
    id: DimensionId.Coordinates,
    type: DimensionType.Coordinates,
    selector: d => d.location.coordinates,
    label: 'Koordinater',
    filterable: false
  },
  {
    id: DimensionId.Description,
    type: DimensionType.Text,
    selector: d => d.description,
    label: 'Beskrivelse',
    filterable: true
  },
  {
    id: DimensionId.CauseDescription,
    type: DimensionType.Text,
    selector: d => d.accidentTypeDescription,
    label: 'Årsaksbeskrivelse',
    filterable: true
  },
  {
    id: DimensionId.VesselTypeDescription,
    type: DimensionType.Text,
    selector: d => d.vesselTypeDescription,
    label: 'Skipstypebeskrivelse',
    filterable: true
  },
  {
    id: DimensionId.Date,
    type: DimensionType.Date,
    selector: d => dayjs(d.date),
    label: 'Dato',
    filterable: true,
  },
  {
    id: DimensionId.Updated,
    type: DimensionType.Date,
    selector: d => dayjs(d.updatedAt),
    label: 'Oppdatert',
    filterable: true,
  },
  {
    id: DimensionId.CreatedAt,
    type: DimensionType.Date,
    selector: d => dayjs(d.createdAt),
    label: 'Opprettet',
    filterable: true,
  },
  {
    id: DimensionId.OperationalPhase,
    type: DimensionType.Categorical,
    selector: d => d.activity,
    label: 'Operasjonsfase',
    filterable: true
  },
  {
    id: DimensionId.WaveHeightCategory,
    type: DimensionType.Categorical,
    selector: d => d.waveHeightCategory,
    label: 'Bølgehøydekategori',
    filterable: true
  },
  {
    id: DimensionId.WaveHeight,
    type: DimensionType.Numerical,
    selector: d => d.waveHeight,
    label: 'Bølgehøyde',
    filterable: true,
    unit: 'm'
  },
  {
    id: DimensionId.WindStrengthCategory,
    type: DimensionType.Categorical,
    selector: d => d.windStrengthCategory,
    label: 'Vindstyrkekategori',
    filterable: true,
  },
  {
    id: DimensionId.WindStrength,
    type: DimensionType.Numerical,
    selector: d => d.windStrength,
    label: 'Vindstyrke',
    filterable: true,
    unit: 'm/s'
  },
  {
    id: DimensionId.CauseOfDeath,
    type: DimensionType.Categorical,
    selector: d => d.persons?.map(person => person.causeOfDeath) || [],
    label: 'Dødsårsak',
    isArray: true,
    filterable: true
  },
  {
    id: DimensionId.Age,
    type: DimensionType.Numerical,
    selector: d => d.persons?.map(person => person.age) || [],
    label: 'Alder',
    isArray: true,
    filterable: true
  },
  {
    id: DimensionId.Gender,
    type: DimensionType.Categorical,
    selector: d => d.persons?.map(person => person.gender) || [],
    label: 'Kjønn',
    isArray: true,
    filterable: true
  },
  {
    id: DimensionId.Nationality,
    type: DimensionType.Categorical,
    selector: d => d.persons?.map(person => person.nationality) || [],
    label: 'Nasjonalitet',
    isArray: true,
    filterable: true
  },
  {
    id: DimensionId.ActivityDescription,
    type: DimensionType.Text,
    selector: d => d.persons?.map(person => person.activityDescription) || [],
    label: 'Aktivitetbeskrivelse',
    isArray: true,
    filterable: true
  },
  {
    id: DimensionId.PersonCategory,
    type: DimensionType.Text,
    selector: d => d.persons?.map(person => person.category) || [],
    label: 'Personkategori',
    isArray: true,
    filterable: true
  },
  {
    id: DimensionId.FloatingDeviceUsed,
    type: DimensionType.Categorical,
    selector: d => d.persons?.map(person => person.useOfFloatingDevice) || [],
    label: 'Bruk av flyteutstyr',
    isArray: true,
    filterable: true
  },
  {
    id: DimensionId.Waters,
    type: DimensionType.Categorical,
    selector: d => d.waters,
    label: 'Farvann',
    filterable: true
  },
];
