import React, {ReactElement, useEffect, useRef} from "react";
import * as ol from "ol";
import {OSM, XYZ} from "ol/source";
import {Tile} from "ol/layer";
import {DEFAULT_MAX_ZOOM} from "../../../constants/defaults";
import BaseLayer from "ol/layer/Base";
import { v4 as uuid } from "uuid";

export interface MapConfig {
  maxZoom?: number;
}

export interface MapConfigInternal {
  pushTopLeftControl: (item: ReactElement) => void;
  pushTopRightControl: (item: ReactElement) => void;
  pushBottomLeftControls: (item: ReactElement) => void;
}

export class MapContainer {

  private readonly _instance: ol.Map;
  private readonly _config: MapConfig & MapConfigInternal;

  constructor(
    element: HTMLDivElement,
    config: MapConfig & MapConfigInternal
  ) {
    const {
      maxZoom = DEFAULT_MAX_ZOOM,
    } = this._config = config;
    this._instance = new ol.Map({
      controls: [],
      target: element,
      view: new ol.View({
        center: [ 1543064.7758262376, 12342707.078435263 ],
        zoom: 3.659,
        maxZoom: maxZoom + 1
      })
    });
  }

  public get instance(): ol.Map {
    return this._instance;
  }

}

export function useBaseLayers(
  nightMode: boolean
) {
  const baseLayerLight = useRef<Tile<OSM>>()
  const baseLayerDark = useRef<Tile<XYZ>>()

  useEffect(() => {
    baseLayerDark.current?.setVisible(nightMode);
    baseLayerLight.current?.setVisible(!nightMode);
  }, [nightMode]);

  if (!baseLayerLight.current) {
    baseLayerLight.current = new Tile({
      source: new OSM(),
      visible: !nightMode,
      zIndex: 0,
      properties: {
        includeInLayerSwitcher: false,
        key: uuid()
      }
    });
  }
  if (!baseLayerDark.current) {
    baseLayerDark.current = new Tile({
      source: new XYZ({
        url: 'https://{a-c}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png'
      }),
      visible: nightMode,
      zIndex: 0,
      properties: {
        includeInLayerSwitcher: false,
        key: uuid()
      }
    });
  }
  return [
    baseLayerLight.current,
    baseLayerDark.current
  ] as BaseLayer[];
}

