import React from "react";
import ComponentEditorProperties from "../../interfaces/properties/ComponentEditorProperties";
import {Statistic} from "../../constants/globalTypes";
import Injectable from "../../injection/injectable";
import {Checkbox, Form, Input, Select} from "antd";
import {injectReactComponent} from "../../utils/injectionUtilities";
import {PreviewAreaProperties} from "./PreivewArea";
import {DimensionDefinition, DimensionType} from "../../interfaces/Config";
import {useDataBuilder, useDimensionOptions} from "../../utils/hooks";
import {ComponentType, Metric} from "../../constants/enums";
import {METRIC_OPTIONS} from "../../constants/options";
import {DataPoint} from "../../interfaces/models/DataPoint";

import className from "../../assets/scss/statistics.scss";

const LAYOUT = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

type DimensionOption = {
  value: number,
  label: string,
  dimension: DimensionDefinition<DataPoint>
}

function statisticValid(statistic: Partial<Statistic>) {
  return (!!statistic.dimensionId || (statistic.metric == Metric.Count && !statistic.dimensionId))
    && !!statistic.title;
}

const INITIAL = {
  metric: METRIC_OPTIONS[0].value
}

export function StatisticEditor(props: ComponentEditorProperties<Statistic>) {
  const {
    data,
    onDataChange,
    renderer: Renderer,
    isEditingExistingComponent
  } = props;
  const [form] = Form.useForm();
  const [statistic, updateStatistic, valid] = useDataBuilder<Statistic>(
    ComponentType.Statistic,data || INITIAL, onDataChange, statisticValid);

  const dimensionOptions = useDimensionOptions({
    type: DimensionType.Numerical
  });
  const PreviewArea = injectReactComponent<PreviewAreaProperties>(Injectable.PreviewArea)

  return (
    <div className={className.editorContainer}>
      <div className={className.formContainer}>
        <Form
          className={className.form}
          form={form}
          name="statistic-editor"
          {...LAYOUT}>
          <Form.Item
            label="Tittel"
            rules={[{ required: true }]}>
            <Input
              value={statistic.title}
              onChange={e => {
                updateStatistic(draft => {
                  draft.title = e.target.value;
                });
              }} />
          </Form.Item>
          <Form.Item
            label="Aggregering">
            <Select
              defaultValue={statistic.metric}
              value={statistic.metric}
              options={METRIC_OPTIONS}
              onChange={(value) => {
                updateStatistic(draft => ({
                  ...draft,
                  metric: value as Metric,
                  dimensionId: value !== Metric.Count ? statistic.dimensionId : undefined
                }));
                /*form.setFieldsValue({
                  dimension: value !== Metric.Count ? statistic.dimension?.label : undefined,
                });*/
              }} />
          </Form.Item>
          {
            statistic.metric !== Metric.Count && (
              <Form.Item
                label="Dimensjon"
                rules={[{ required: true }]}>
                <Select
                  value={statistic.dimensionId}
                  options={dimensionOptions}
                  onChange={(value, option) => updateStatistic(draft => ({
                    ...draft,
                    dimension: (option as DimensionOption).dimension
                  }))} />
              </Form.Item>
            )
          }
          {
            [Metric.Count, Metric.Sum].includes(statistic.metric!) && (
              <Form.Item
                name="percentageOfTotal"
                wrapperCol={{ offset: LAYOUT.labelCol.span }}
              >
                <Checkbox
                  checked={statistic.percentageOfTotal}
                  onChange={e => updateStatistic(draft => ({
                    ...draft,
                    percentageOfTotal: e.target.checked
                  }))}>
                  Prosent av total
                </Checkbox>
              </Form.Item>
            )
          }
        </Form>
      </div>
      <PreviewArea width={300} height={200}>
        {
          ({ width, height }) => (
            <Renderer
              isEditingExistingComponent={isEditingExistingComponent}
              editing={true}
              valid={valid}
              data={statistic}
              width={width}
              height={height} />
          )
        }
      </PreviewArea>
    </div>
  )
}
