import {container} from "../../injection";
import Client from "../../interfaces/Client";
import Injectable from "../../injection/injectable";
import {UserData} from "../interfaces/states/UserState";

export function persistUserDataAsync(
  data: UserData
) {
  const client = container.get<Client>(Injectable.Client);
  return client.put({
    apiEndpoint: 'user/data',
    withCredentials: true,
    data
  })
    .then(() => {
      // TODO: Maybe add some user feedback here
      return;
    });
}

export function hydrateUserDataAsync() {
  const client = container.get<Client>(Injectable.Client);
  return client.get({
    apiEndpoint: 'user/data',
    withCredentials: true,
  })
    .then(({ data }) => data)
}
