
export const enum RouterPath {
    Home = '/',
    Login = '/login',
    Playground = '/playground',
    Prediction = '/prediction',
    Models = '/models',
    Statistics = '/statistics',
    Error = '/error',
    DataExtractor = '/data-extractor',
    RiskModel = '/risk',
}

export enum LayoutAlternative {
    One = 1,
    Two = 2,
    Three= 3
}

export const enum UISize {
    Small = 'small',
    Default = 'default',
    Large = 'large',
    Middle = 'middle',
}

export const enum Type {
    Boolean = 'boolean',
    Symbol = 'symbol',
    Function = 'function',
    Class = 'class',
    Array = 'array',
    String = 'string',
    Number = 'number',
    Object = 'object',
    None = 'none',
}

export const enum Message {
    DataFetching = 'Laster inn data',
    DataFetched = 'Data lastet inn',
    DataFetchFailed = 'Datainnlastning mislyktes',
    DataDownloading = 'Genererer datafil for nedlastning',
    DataDownloaded = 'Datafil for nedlastning generert',
    DataDownloadedFailed = 'Datanedlastning mislyktes',
    AddedDataPoint = 'Ulykke lagt til',

}

export const enum DataType {
    Json ,
    Text
}

export enum PermissionFlag {
    None = 0,
    Admin = 1 << 0
}

export enum SelectionType {
    Polygon = "polygon",
    Freehand = "freehand",
    Box = "box",
    Extent = "extent"
}

export const enum FilterId {
    Description = 'description',

}

// Changing this will probably break stuff
export const enum DimensionId {
    County = 0,
    Description = 1,
    AccidentType = 2,
    ShipType = 3,
    Date = 4,
    Dead = 5,
    Injured = 6,
    Missing = 7,
    Length = 8,
    Geo = 9,
    Cause = 10,
    DataSource = 11,
    Lat = 12,
    Lon = 13,
    Coordinates = 14,
    CauseDescription = 15,
    VesselTypeDescription = 16,
    Updated = 17,
    CreatedAt = 18,
    OperationalPhase = 19,
    WaveHeightCategory = 20,
    WindStrengthCategory = 21,
    CauseOfDeath = 22,
    BirthYear = 23,
    Age = 24,
    Gender = 25,
    Nationality = 26,
    PoliceDistrict = 27,
    Waters = 28,
    FloatingDeviceUsed = 29,
    ActivityDescription = 30,
    PersonCategory = 31,
    WaveHeight = 32,
    WindStrength = 33,
}

export const enum DimensionLabel {
    TextSearch = 'Tekst filter',
    AccidentType = 'Ulykketype',
    ShipType = 'Skipstype',
    Date = 'Dato',
    Dead = 'Døde',
    Injured = 'Skadde',
    Missing = 'Savnede',
    Length = 'Lendge',
    Geo = 'Kartfilter',
}

export const enum LayerKey {
    BaseLight = "0",
    SeaMap = "1",
    Accidents = "2",
    AccidentsFilteredOut = "3",
    Counties = "4",
    TssBorder = "5",
    TssArea = "6",
    HovedOgBiled = "7",
    BaseDark = "8",
}

export const enum Chart {
    Bar = 'bar',
    Line = 'line',
    Pie= 'pie',
    Heat= 'heat',
    Bubble= 'bubble',
    Scatter = 'scatter',
    Box = 'box',
}

export const enum Unit {
    Date = "date",
    Weeks = "weeks",
    Months = "months",
    Year = "year",
    Float = "float",
    Integer = "integer"
}

export enum Aggregation {
    None = 0,
    Count = 1 << 0,
    Sum = 2 << 0,
    Average = 3 << 0,
    Max = 4 << 0,
    Min = 5 << 0,
    StandardDeviation = 6 << 0,
}

export const enum DimensionTransform {
    Date = "days",
    Weeks = "weeks",
    Months = "months",
    Year = "year"
}

export enum ComponentType {
    Trend = 'trend',
    Statistic = 'statistic',
    Categorical = 'categorical'
}

export enum Metric {
    None = 0,
    Count = 1 << 0,
    Sum = 2 << 0,
    Average = 3 << 0,
    Max = 4 << 0,
    Min = 5 << 0,
    StandardDeviation = 6 << 0,
}


export const enum Operator {
    LesserThan = 'lt',
    LesserThanOrEqual = 'lte',
    Equal = 'e',
    NotEqual = 'ne',
    GreaterThan = 'gt',
    GreaterThanOrEqual = 'gte',
    In = 'in',
}

export const enum FilterType {
    Numerical,
    Categorical,
    Month,
    Day,
    Year,
    Date,
    Geo,
    Text,
}

export const enum GeoFilterType {
    Polygon,
    Extent,
    ID,
    IDs
}

export const enum LayerType {
    ClusteredPoints,
    Counties,
    PoliceDistricts,
    HeatMap
}
