import Config from "../interfaces/Config";
import {UISize} from "../constants/enums";
import React from "react";
import {geoJSONLayers} from "./layers";
import {dimensions} from "./dimensions";
import build from "./build";
import {readBooleanString} from "ol/format/xsd";


const index: Config = {
    build: build,
    tabSize: UISize.Large,
    messageDuration: 2000,
    debug: readBooleanString(process.env.DEBUG || "false"),
    redirectUrl: "http://www.dagbladet.no",
    dataUrl: "http://localhost:3000/static/data/data.json",
    grid: {
        breakpoints: {
            small: 500,
            medium: 1200
        },
        columns: {
            small: 1,
            medium: 2,
            large: 3
        }
    },
    maxLengthFilterValue: 10,
    filterBarMinSize: 300,
    filterBarDefaultSize: 300,
    geoJSONLayerConfigs: geoJSONLayers,
    dimensions: dimensions
};

export default index;
