import React, {useContext} from "react";
import GridLayout from 'react-grid-layout';
import { Scrollbars } from 'react-custom-scrollbars';
import {useResizeObserver} from "../../utils/hooks";
import {RefObject, useRef} from "react";
import FilterGridItemProperties from "../../interfaces/properties/FilterGridItemProperties";
import innerClassName from "../../assets/scss/components/filtergrids.scss";
import {useInjection} from "inversify-react";
import {
    FilterGridItemComponent,
} from "../../constants/globalTypes";
import Injectable from "../../injection/injectable";
import {useSelector} from "react-redux";
import GlobalState from "../../store/interfaces/states/GlobalState";
import tabPaneclassName from "../../assets/scss/components/tabpane.scss";
import { TabBarContextState, TabBarContext } from "./AntDTabBar";
import Tab from "../../constants/tabs";

import className from "../../assets/scss/components/filtergrids.scss";

const FilterGridItem = React.forwardRef((props: FilterGridItemProperties, ref) => {
    const { key, style, className, children, title, component: Component, ...restOfProps } = props;
    const innerRef = useRef<HTMLDivElement>(null);
    const { width, height } = useResizeObserver(innerRef);
    return (
        <div
            ref={ref as RefObject<HTMLDivElement>}
            key={key}
            style={style}
            className={`${className} ${innerClassName.gridItem}`}
            {...restOfProps}>
            <div className={innerClassName.draggableHandle}>{title}</div>
            <div ref={innerRef} className={innerClassName.gridItemContent}>
                <Component width={width} height={height} />
            </div>
            {children}
        </div>
    );
});


export default function FilterGrid() {
    const layout = [
        {i: 'a', x: 0, y: 0, w: 12, h: 3, static: true },
        {i: 'b', x: 0, y: 5, w: 4, h: 5},
        {i: 'c', x: 4, y: 5, w: 4, h: 5},
        {i: 'd', x: 8, y: 5, w: 4, h: 5},
        {i: 'e', x: 0, y: 10, w: 12, h: 11},
        {i: 'f', x: 0, y: 21, w: 12, h: 12},
    ];
    const ref = useRef(null);
    const width = useSelector((state: GlobalState) => state.user.data.dataPaneSize!);

    const TextFilter = useInjection<FilterGridItemComponent>(Injectable.TextFilter);
    const InjuredSelect = useInjection<FilterGridItemComponent>(Injectable.InjuredSelectFilter);
    const DeadSelectFilter = useInjection<FilterGridItemComponent>(Injectable.DeadSelectFilter);
    const MissingSelect = useInjection<FilterGridItemComponent>(Injectable.MissingSelectFilter);
    const ShipTypePieChart = useInjection<FilterGridItemComponent>(Injectable.ShipTypePieChart);
    const AccidentTypePieChart = useInjection<FilterGridItemComponent>(Injectable.AccidentTypePieChart);

    const tabBarContext = useContext<TabBarContextState>(TabBarContext);
    const tabVisible = tabBarContext.activeTab === Tab.Filter;

    return (
        <div
          ref={ref}
          style={{

          }}
          className={className.content}>
            <div className={className.scroll}>
                <div 
                    className={className.notAlternativeOne}>
                    <GridLayout
                        draggableHandle={`.${className.draggableHandle}`}
                        layout={layout}
                        cols={12}
                        rowHeight={30}
                        width={width}
                        containerPadding={[20, 20]}>
                        <FilterGridItem
                            key="b"
                            title="Skadde"
                            component={InjuredSelect} />
                        <FilterGridItem
                            key="c"
                            title="Døde"
                            component={DeadSelectFilter} />
                        <FilterGridItem
                            key="d"
                            title="Savnede"
                            component={MissingSelect} />
                        <FilterGridItem
                            key="e"
                            title="Ulykketype"
                            component={AccidentTypePieChart} />
                        <FilterGridItem
                            key="f"
                            title="Skipstype"
                            component={ShipTypePieChart} />
                        {
                            /*
                                <FilterGridItem
                                    key="c"
                                    title="Lengde"
                                    component={ShipLengthBarChart} />
                             */
                        }
                    </GridLayout>
                </div>
            </div>
            <div
              className={`${tabPaneclassName.topToolBar} ${!tabVisible && tabPaneclassName.notVisible}`}>
                <TextFilter />
            </div>
        </div>
    );
}
