import {ComparatorValue, Filter} from "../../constants/globalTypes";
import {FilterType, Operator} from "../../constants/enums";
import React, {useMemo} from "react";
import {DatePicker, InputNumber, Select, Tag, Typography} from "antd";
import {isMultipleOperator, operatorsForType} from "../../utils/filterUtilities";
import {useInjection} from "inversify-react";
import Injectable from "../../injection/injectable";
import Config, {DimensionDefinition} from "../../interfaces/Config";
import {bindActionCreators} from "redux";
import {filterDefinitionActions} from "../../store/actions/data";
import {useDispatch, useSelector} from "react-redux";
import {useDimension} from "../../utils/hooks";
import dayjs from "dayjs";
import GlobalState from "../../store/interfaces/states/GlobalState";
import {DataPoint} from "../../interfaces/models/DataPoint";
import {useDataSourceContext} from "../datasource/DataSourceProvider";

// @ts-ignore
import className from "../../assets/scss/filterView.scss";


export interface FilterRendererProperties {
  filters: Filter[];
  onChange: (filter: Filter) => void;
}

interface FilterInputProperties {
  filter: Filter;
  onComparatorValueChange: (filter: Filter, value: ComparatorValue|ComparatorValue[]) => void;
  dimension?: DimensionDefinition<DataPoint>;
}

function DateInput(props: FilterInputProperties) {

  const { filter, onComparatorValueChange } = props;
  const comparatorValue = filter.comparatorValue as number;

  const date = useMemo(() => {
    const date = dayjs.unix(comparatorValue);
    return date.isValid() ? date : null;
  }, [comparatorValue]);

  return (
    <DatePicker
      placeholder="Dato"
      style={{ display: 'flex', flex: 1 }}
      size="small"
      value={date}
      onChange={(nextDate: any) => {
        onComparatorValueChange(filter, nextDate?.unix());
      }}/>
  );
}

function CategoricalInput(props: FilterInputProperties) {
  const config = useInjection<Config>(Injectable.Config);
  const { filter, onComparatorValueChange } = props;
  const dimension = useDimension(filter.dimensionId);
  const { dataSource } = useDataSourceContext();
  const {
    redactionString,
    redactionInt
  } = useSelector((state: GlobalState) => state.data);
  const optionValues = dimension
      ? dataSource.getUniqueValuesForDimension(dimension).filter(({ label, value }) => value !== redactionString && value !== redactionInt)
      : [];
  return (
    <Select
      maxTagCount={2}
      maxTagTextLength={config.maxLengthFilterValue}
      mode={isMultipleOperator(filter.operator) ? "multiple" : undefined}
      value={filter.comparatorValue as any}
      optionFilterProp="children"
      options={optionValues}
      onChange={value => onComparatorValueChange(filter, value)}
      style={{ width: '100%' }}
      dropdownMatchSelectWidth={false}>
    </Select>
  );
}

function NumericalInput(props: FilterInputProperties) {
  const {
    filter,
    onComparatorValueChange,
    dimension
  } = props;
  return (
    <InputNumber
      className={className.numberInput}
      value={filter.comparatorValue as any}
      onChange={value => onComparatorValueChange(filter, value)}
      addonAfter={dimension?.unit} />
  );
}

function includeOperatorSelect(
  filter: Filter
) {
  return !!filter;
}

export function FilterRenderer(
  props: FilterRendererProperties
) {
  const {
    filters,
    onChange
  } = props;
  const { type, dimensionId } = filters[0] || {};
  const dimension = useDimension(dimensionId);
  const operators = useMemo(() => operatorsForType(type), [type]);
  const updateOperator = (filter: Filter, operator: Operator) => onChange({ ...filter, operator });
  const updateComparatorValue = (filter: Filter, comparatorValue: ComparatorValue|ComparatorValue[]) =>
    onChange({ ...filter, comparatorValue });
  const dispatch = useDispatch();
  const {
    deleteFilterDefinition
  } = useMemo(() => bindActionCreators(filterDefinitionActions, dispatch), []);

  const tagElements = filters.map((filter: Filter, index: number) => (
    <Tag
      key={index}
      onClose={() => deleteFilterDefinition(filter)}
      className={className.filterContainer}
      closable={true}>
      <div className={className.filter}>
        {
          includeOperatorSelect(filter) ? (
            <div>
              <Select
                value={filter.operator}
                optionFilterProp="children"
                className={className.operator}
                onChange={value => updateOperator(filter, value)}>
                {
                  operators.map(([value, label], index) =>
                    <Select.Option key={index} value={value}>{label}</Select.Option>)
                }
              </Select>
            </div>
          ) : <></>
        }
        {
          filter.type === FilterType.Date && <DateInput
              filter={filter}
              dimension={dimension}
              onComparatorValueChange={updateComparatorValue} />
        }
        {
          filter.type === FilterType.Categorical && <CategoricalInput
              filter={filter}
              dimension={dimension}
              onComparatorValueChange={updateComparatorValue} />
        }
        {
          filter.type === FilterType.Numerical && <NumericalInput
              filter={filter}
              dimension={dimension}
              onComparatorValueChange={updateComparatorValue} />
        }
      </div>
    </Tag>
  ));
  const containerClassName = filters.length > 1 ? className.moreThanOneFilter : undefined;
  return (
    <div
      className={containerClassName}>
      <Typography.Title
        level={5}
        className={className.filterLabel}>
        {dimension?.label}
      </Typography.Title>
      <div className={className.tags}>
        {
          tagElements
        }
      </div>
    </div>
  );
}
