import React, {CSSProperties, PropsWithChildren, useMemo} from "react";
import {useInjection} from "inversify-react";
import Injectable from "../../injection/injectable";
import { FunctionalComponent } from "../../constants/globalTypes";
import { theme, Tabs, Form } from "antd";
import _ from "lodash";

// @ts-ignore
import className from "../../assets/scss/data-extractor.scss";
import {useSelector} from "react-redux";
import {getNightMode} from "../../store/selectors/user";

interface TreeItemProperties {
  content: string;
}

function TreeItem(
  props: TreeItemProperties
) {
  const {
    token
  } = theme.useToken();
  const {
    content
  } = props;
  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        height: 100,
        borderColor: token.colorBorder,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: token.borderRadius
      }}>
      { content }
    </div>
  )
}


function useContentStyle(
  overrideStyles: Partial<CSSProperties> = {}
): Partial<CSSProperties> {
  const { token } = theme.useToken();
  const nightMode = useSelector(getNightMode);
  return {
    borderColor: nightMode ? token.colorBgElevated : token.colorBorder,
    background: token.colorBgContainer,
    borderWidth: '0 1px 1px 1px',
    borderStyle: 'solid',
    height: '100%',
    padding: 12,
    ...overrideStyles
  }
}

function DataPointList() {
  const style = useContentStyle();
  return (
    <div
      style={style}
      className={className.dataPointList}>

    </div>
  )
}

function DataPointSelector() {
  return (
    <Tabs
      className={className.dataPointSelectorTabs}
      defaultActiveKey="1"
      items={[
        {
          label: 'Ubekreftet',
          key: '1',
          children: <DataPointList />
        },
        {
          label: 'Bekreftet',
          key: '2',
          children: <DataPointList />
        }
      ]}
      type="card"
    />
  );
}

function Text() {
  const style = useContentStyle();
  return (
    <div
      style={style}>
      Tekst
    </div>
  );
}

function Web() {
  const style = useContentStyle();
  return (
    <div
      style={style}>
      Web
    </div>
  );
}


function createTabItemsForDataPointViewer(
  dataPoint: { id: string, text: string, url?: string }
) {
  const { url } = dataPoint;
  const items = [{
    label: 'Text',
    key: '1',
    children: <Text />
  }];
  if (_.isString(url)) {
    items.push({
      label: 'Se på nett',
      key: '2',
      children: <Web />
    });
  }
  return items;
}

interface DataPointViewerProperties {
  dataPoint: { id: string, text: string, url?: string };
}

function TextViewer(
  props: DataPointViewerProperties
) {
  const { dataPoint } = props;
  const tabItems = useMemo(() => createTabItemsForDataPointViewer(dataPoint), []);
  return (
    <Tabs
      className={className.textViewerTabs}
      defaultActiveKey="1"
      items={tabItems}
      type="card"
    />
  )
}

function DatapointViewer(
  props: PropsWithChildren<{}>
) {
  const {
    children
  } = props;
  const style = useContentStyle({
    borderWidth: 1,
    marginTop: 34,
    height: 'calc(100% - 34px)'
  });
  return (
    <div
      style={style}
    >
      { children }
    </div>
  );
}

function ExtractedDataViewer() {
  return (
    <Form
      className={className.extractedDataForm}>

    </Form>
  );
}

export default function DataExtractor() {
  const DataSourceProvider = useInjection<FunctionalComponent>(Injectable.DataSourceProvider);
  const CheckAuthWrapper = useInjection<FunctionalComponent>(Injectable.CheckAuthWrapper);
  const {
    token: {
      colorBgLayout: background
    }
  } = theme.useToken();


  return (
    <DataSourceProvider>
      <CheckAuthWrapper>
        <div
          style={{ background }}
          className={className.container}>
          <DataPointSelector />
          <TextViewer dataPoint={{
            id: 'miow',
            text: 'woff',
            url: 'google.com'
          }} />
          <DatapointViewer>
            Style
          </DatapointViewer>
        </div>
      </CheckAuthWrapper>
    </DataSourceProvider>
  );
}
